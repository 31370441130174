import React, { useState, useEffect } from 'react'
import AdminDashboardNav from '../AdminDashboardNav'
import fb from "../../Firebase/firebase";
import { useNavigate } from 'react-router-dom';
const DB = fb.firestore()
const CDB = DB.collection('Faq_Form');

const FaqDB = () => {

    const [careerform, SetCareerForm] = useState([]);
    let navigate = useNavigate();
    let id;

    useEffect(() => {

        fb.auth().onAuthStateChanged(function (user) {
            if (user) {
                id = user.uid;
                navigate('/faq-form-database');
                console.log("uid", id);
            } else {
                navigate('/admin-login');
                console.log("user is logged out")
            }
        });

        const unsubscribe = CDB.onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));
            // Update state
            SetCareerForm(data);
        });

        // Detach listener
        return unsubscribe;
    }, []);

    return (
        <div>
            <div className='dashboard'>
                <AdminDashboardNav />
                <div className='dashboard-app'>
                    <h3>Form Responses of Faq Form</h3>

                    <div className='contact-form'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            {careerform.map(career => (
                                <tbody key={career.id}>
                                    <tr>
                                        <td data-column="Name">{career.Name}</td>
                                        <td data-column="Email">{career.Email}</td>
                                        <td data-column="Phone">{career.Mobile}</td>
                                        <td data-column="Message">{career.Message}</td>
                                    </tr>
                                </tbody>
                            ))}
                        </table>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default FaqDB

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/storage'

firebase.initializeApp({
    apiKey: "AIzaSyCyywTgkWbDdg7Vxbz59RQbRmSngnjnDI8",
    authDomain: "ahs-assured-services.firebaseapp.com",
    databaseURL: "https://ahs-assured-services-default-rtdb.firebaseio.com",
    projectId: "ahs-assured-services",
    storageBucket: "ahs-assured-services.appspot.com",
    messagingSenderId: "325654870538",
    appId: "1:325654870538:web:f577753dc14b7f29803df3"
});

const fb = firebase;

export default fb;
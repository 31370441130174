import Header from '../../Utilities/Header'
import Footer from '../../Utilities/Footer'
import Bot from '../../Functionalities/Bot'
import UpArrow from '../../Utilities/UpArrow'
import "./Blog.scss"
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import fb from "../../Firebase/firebase";
const DB = fb.firestore()
const Blogslist = DB.collection('Blogs').orderBy("Date", "desc");

const Blog = () => {

    const [blogs, Setblogs] = useState([]);

    useEffect(() => {
        const unsubscribe = Blogslist.limit(100).onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));
            // Update state
            Setblogs(data);
        });

        // Detach listener
        return unsubscribe;
    }, []);

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }, []);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AHS Assured Services Pvt Ltd - Blogs</title>
                <link rel="canonical" href="https://www.ahsassuredservices.com/blog" />
                <meta name="description" content="Explore our blogs for valuable insights on digital marketing, staffing, and real estate services. Stay  informed & updated with industry trends and expert tips to boost your business" />
            </Helmet>
            <Header />

            <div className="blog">
                <div className="section-head col-sm-12">
                    <h1 style={{ color: '#000' }}><span>Blogs</span> </h1>

                </div>

                <div className="blog-content">
                    {blogs.map(blog => (
                        <div className="blog-content-body" key={blog.id}>
                            <img rel="preload" loading="lazy" src={blog.Image} alt="Blog" />

                            <div className="blog-content-body-text">
                                <p><b>Posted On:</b> {blog.Date}</p>

                                <h5><Link to={"/show/" + blog.id}>{blog.Title}</Link></h5>

                            </div>
                        </div>
                    ))}
                </div>

            </div>

            <Footer />
            <Bot />
            <UpArrow />
        </div>
    )
}

export default Blog

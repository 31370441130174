import Footer from "../../Utilities/Footer"
import Accordion from 'react-bootstrap/Accordion';
import Bot from '../../Functionalities/Bot';
import Header from '../../Utilities/Header';
import { useEffect } from 'react';
import React, { useRef } from 'react'
import { useState } from "react"
import emailjs from '@emailjs/browser';
import UpArrow from "../../Utilities/UpArrow";
import { Helmet } from "react-helmet";
import "./Faq.scss"

const Faq = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    const form = useRef();


    const sendEmail = (e) => {
        e.preventDefault();
        // service_id, templte_id and public key will get from Emailjs website when you create account and add template service and email service 
        emailjs.sendForm('service_ag5zzwp', 'template_yq2peav', e.target,
            'gynpFH6G_dIrHIEw9')
            .then((result) => {
                console.log(result)
                alert("Sent Successfully");
            }, (error) => {
                console.log(error)
                alert("oops! some error occured");
            });

    };


    const [userData, setUserData] = useState({
        name: "",
        email: "",
        message: ""

    });
    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }
    //connect with firebase

    const SubmitData = async (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'service_z3p8h0m',
            'template_svmf3nm',
            e.target,
            'user_7Pf1rN0FgZQwrrMpFSw55'
        ).then((result) => {
            console.log(
                result
            );
        }, (error) => {
            console.log(error);
        });
        const { name, email, message } = userData;
        if (name && email && message) {


            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(userData.email)) {
                alert('Please enter a valid email address');
                return
            }
            const res = fetch(
                'https://ahs-assured-services-default-rtdb.firebaseio.com/faq_form.json',
                {
                    method: 'POST',
                    headers: {
                        "content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        name,
                        email,
                        message
                    }),
                }
            );
            if (res) {

                alert('Message Sent Successfully');
                setUserData({
                    name: "",
                    email: "",
                    message: ""
                })


            } else {
                alert('Please fill the data');
            }
        }
        else {
            alert('Please fill the data');
        }
    };

    return (

        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AHS Assured Services Pvt Ltd - FAQ</title>
                <link rel="canonical" href="https://www.ahsassuredservices.com/faq" />
                <meta name="description" content="Find answers to your questions on our FAQ page. Clear and comprehensive solutions to all your queries. Enhance your website experience now!" />
            </Helmet>
            <Bot />
            <UpArrow />
            <Header />
            {/* <Sample /> */}
            <div className='faq'>
                <div className="section-head col-sm-12">
                    <h1><span>How Can We Help You?</span> </h1>
                                   </div>

                <div className='faq-body'>

                    <div className='faq-accordion'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0" tabIndex="1">
                                <Accordion.Header>What is digital marketing?</Accordion.Header>
                                <Accordion.Body>
                                    Digital marketing refers to the use of digital channels, such as websites, search engines, social media platforms, email, and mobile apps, to promote products or services. It encompasses various strategies and tactics aimed at reaching and engaging with a target audience online.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1" tabIndex="1">
                                <Accordion.Header>What are the benefits of digital marketing?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Digital marketing offers several benefits, including:</p>

                                    <p><b>Increased reach:</b><i>&nbsp;Digital marketing allows you to reach a global audience, irrespective of geographical boundaries.</i></p>
                                    <p><b>Targeted audience:</b><i>&nbsp;You can target specific demographics, interests, and behaviors to reach the right audience for your business.</i></p>
                                    <p><b>Cost-effective:</b><i>&nbsp;Compared to traditional marketing methods, digital marketing often offers a higher return on investment (ROI) and can be more cost-effective. </i></p>
                                    <p><b>Measurable results:</b><i>&nbsp;Digital marketing provides robust analytics and tracking tools to measure the performance of your campaigns and make data-driven decisions.</i></p>
                                    <p><b>Personalization:</b><i>&nbsp;It enables personalized communication and tailored messaging to connect with customers on a more individual level. </i></p>
                                    <p><b>Flexibility and scalability:</b><i>&nbsp;Digital marketing allows you to quickly adjust your strategies and scale campaigns based on performance and business needs. </i></p>



                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>What are the key components of digital marketing?</Accordion.Header>
                                <Accordion.Body>
                                    <p>The key components of digital marketing include:</p>

                                    <p><b>Search Engine Optimization (SEO):</b><i>&nbsp;Optimizing websites to rank higher in search engine results pages. </i></p>
                                    <p><b>Pay-Per-Click Advertising (PPC):</b><i>&nbsp;Running paid ads on search engines and other platforms, paying only when someone clicks on the ad.</i></p>
                                    <p><b>Social Media Marketing:</b><i>&nbsp;Promoting products or services on social media platforms to engage with the target audience. </i></p>
                                    <p><b>Content Marketing:</b><i>&nbsp;Creating and distributing valuable content to attract, educate, and engage with potential customers. </i></p>
                                    <p><b>Email Marketing:</b><i>&nbsp;Sending targeted emails to subscribers to nurture relationships and promote products or services. </i></p>
                                    <p><b>Influencer Marketing:</b><i>&nbsp;Collaborating with influential individuals or personalities to promote products or services.</i></p>
                                    <p><b>Affiliate Marketing:</b><i>&nbsp;Partnering with affiliates who promote your products or services in exchange for a commission on sales. </i></p>
                                    <p><b>Online Reputation Management:</b><i>&nbsp;Monitoring and managing the online reputation of a brand or business.</i></p>
                                    <p><b>Analytics and Reporting:</b><i>&nbsp;Measuring and analyzing the performance of digital marketing efforts using various tools and reporting methods. </i></p>


                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>How can I measure the success of my digital marketing campaigns?</Accordion.Header>
                                <Accordion.Body>

                                    <p>The success of digital marketing campaigns can be measured using various metrics, depending on the specific goals and objectives. Common metrics include:</p>
                                    <p><b>Website traffic:</b><i>&nbsp;The number of visitors to your website.</i></p>
                                    <p><b>Conversion rate:</b><i>&nbsp;The percentage of visitors who complete a desired action, such as making a purchase or filling out a form. </i></p>
                                    <p><b>Click-through rate (CTR):</b><i>&nbsp;The percentage of people who clicked on a specific link or ad. </i></p>
                                    <p><b>Return on Investment (ROI):</b><i>&nbsp;The ratio of the net profit generated from a campaign to the cost of running that campaign.</i></p>
                                    <p><b>Cost per acquisition (CPA):</b><i>&nbsp;The average cost to acquire a new customer or lead. </i></p>
                                    <p><b>Social media engagement:</b><i>&nbsp;The number of likes, comments, shares, and overall interaction with your social media posts.</i></p>
                                    <p><b>Email open rate and click-through rate:</b><i>&nbsp;The percentage of people who open your emails and click on links within them. </i></p>
                                    <p><b>Search engine rankings:</b><i>&nbsp;The position of your website in search engine results for specific keywords. </i></p>

                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header> What is AHS Staffing services?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Staffing services, also known as employment agencies or recruitment agencies, are companies that assist businesses in finding and hiring qualified candidates for temporary or permanent positions. These agencies act as intermediaries between employers and job seekers, helping to match the right candidates with the right job opportunities.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>What are the benefits of using AHS Staffing services?</Accordion.Header>
                                <Accordion.Body>
                                    <p> Utilizing AHS Staffing Services can offer several benefits to businesses:</p>
                                    <p><b>Time and Cost Savings:</b><i>&nbsp;AHS handles the time-consuming tasks of sourcing, screening, and interviewing candidates, saving businesses valuable time and resources.</i></p>
                                    <p><b>Access to a Larger Talent Pool:</b><i>&nbsp;AHS has extensive networks and databases of qualified candidates, allowing businesses to tap into a larger pool of talent that may not be easily accessible through traditional hiring methods.</i></p>
                                    <p><b>Expertise and Industry Knowledge:</b><i>&nbsp;We often specialize in specific industries and possess deep knowledge of market trends, salary ranges, and candidate qualifications, enabling them to find the right fit for each position.</i></p>
                                    <p><b>Flexibility:</b><i>&nbsp;We provide businesses with the flexibility to adjust their workforce according to fluctuating demands, such as seasonal peaks or project-specific requirements.</i></p>
                                    <p><b>Reduced Hiring Risks:</b><i>&nbsp;We conduct thorough background checks, skills assessments, and reference checks on candidates, minimizing the risk of hiring unqualified or unreliable employees.</i></p>
                                    <p><b>Streamlined Hiring Process:</b><i>&nbsp;We streamline the recruitment process, handling all administrative tasks related to hiring, onboarding, payroll, and compliance, ensuring a smooth and efficient process for both employers and candidates.</i></p>

                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>
                    </div>

                    <div className="faq-form">
                        <h2>Do You Have A Query?</h2>
                        <h4>Ask us</h4>
                        <form id="faq-form" ref={form} onSubmit={sendEmail}>
                            <div className="mb-3">
                                <input type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder='Enter Name'
                                    name="name"
                                    value={userData.name}
                                    onChange={postUserData}
                                    required />
                            </div>
                            <div className="mb-3">
                                <input type="email"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder='Enter Email'
                                    name="email"
                                    value={userData.email}
                                    onChange={postUserData}
                                    required />

                            </div>
                            <div className="form-floating">

                                <textarea
                                    className="form-control"
                                    placeholder="Ask Your Question"
                                    id="floatingTextarea2"
                                    style={{ height: "100px" }}
                                    name="message"
                                    value={userData.message}
                                    onChange={(e) => postUserData} required>

                                </textarea>
                            </div>

                            <div className="mb-3 faq-form-btn">
                                <button type="button" className="btn btn-success" value="Submit" onClick={SubmitData}>Send</button>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Faq

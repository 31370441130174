import React from 'react';

import './LearningOptions.css';

const DigitalLO = (props) => {
    const options = [
        {
            text: 'Social Media Optimization',
            handler: props.actionProvider.handleSmo
        },
        {
            text: 'Website Design & Development',
            handler: props.actionProvider.handleWeb
        },
        {
            text: 'Search Engine optimization',
            handler: props.actionProvider.handleSeo
        },
        {
            text: 'Pay per Click (PPC) advertising',
            handler: props.actionProvider.handlePpc
        },
        {
            text: 'Content Marketing',
            handler: props.actionProvider.handleContent
        },
        {
            text: 'Bulk Marketing',
            handler: props.actionProvider.handleBulk
        },
        {
            text: 'Video Marketing',
            handler: props.actionProvider.handleVideo
        },
        {
            text: 'Affiliate Marketing',
            handler: props.actionProvider.handleAffiliate
        },
        {
            text: 'Influencer marketing',
            handler: props.actionProvider.handleInfluencer
        }
    ];

    const optionsMarkup = options.map((option) => (
        <button
            className='option-button'
            key={option.id}
            onClick={option.handler}
        >
            {option.text}
        </button>
    ));

    return <div className='options-container'>{optionsMarkup}</div>;
};

export default DigitalLO;

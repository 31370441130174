import React from 'react'
import Header from '../../../Utilities/Header'
import Footer from '../../../Utilities/Footer'
import UpArrow from '../../../Utilities/UpArrow'
import Bot from '../../../Functionalities/Bot'
import Title from '../../../Utilities/Title'
import { Link } from 'react-router-dom'
import InquiryForm from '../../../Utilities/InquiryForm'


const VideoMarketing = () => {
  return (
    <div>
      <Bot />
      <UpArrow />
      <Header />
      <div className='banner-hero'>

        <div className="banner-hero-text">
          <h1>video marketing</h1>
          <div className="content">
            <h2>Unleashing Your Brand's Story in Motion!</h2>
          </div>
          {/* <a href='#staffing-services'>Learn More</a> */}
        </div>

        <div className="banner-hero-img">
          <img rel="preload" loading="lazy"  src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632931/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Video%20Marketing/hero_uy08nn.webp' alt="smo-background" className='img-fluid' />
        </div>

      </div>

      <div className="smo-what">
        <div className="smo-what-text">
          <Title title="What is video marketing?" />
        </div>

        <div className="smo-what-content">

          <div className="smo-what-content-text">
            <p>Video marketing is a digital marketing strategy that involves using videos to promote, advertise, and communicate with a target audience. It is a powerful and effective method of engaging potential customers, building brand awareness, and driving conversions. Video marketing leverages the visual and auditory appeal of videos to deliver messages, showcase products or services, and tell compelling stories.</p>

            <p>
            In video marketing, businesses create and distribute various types of videos across different platforms, including social media, websites, email campaigns, and video-sharing sites like YouTube and Vimeo. These videos can take the form of commercials, explainer videos, product demonstrations, customer testimonials, behind-the-scenes footage, interviews, webinars, and more.
            </p>

            <p>Content marketing can take various forms, including blog posts, articles, videos, infographics, podcasts, social media posts, ebooks, webinars, and more. It is an ongoing and strategic process that requires understanding the target audience, creating high-quality content, and effectively distributing it through various channels to reach the intended audience.</p>
          </div>

          <div className="smo-what-content-img">
            <img rel="preload" loading="lazy"  src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632932/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Video%20Marketing/video_what_pppakz.webp' alt="what is smo?" />
          </div>

        </div>
      </div>

      <div className='content-services web-why'>
        <div className="smo-what-text">
          <Title title="types of video marketing" />
        </div>
        <div className='container-fluid mx-auto mt-5 mb-5 col-12' style={{ textAlign: "center" }}>

          <div className="row web-why-card" style={{ justifyContent: "center" }}>
            <div className="card">
              <div className="card-content">
                <div className="card-body"> <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632931/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Video%20Marketing/brand_videos_n4ix37.webp' alt='customized' />
                  <div className="shadow"></div>
                  <div className="card-title">Brand Videos</div>
                  <div className="card-subtitle">
                    <p> These videos showcase the overall brand, its mission, values, and personality, helping to create an emotional connection with the audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <div className="card-body"> <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632931/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Video%20Marketing/product_videos_q4o8wc.webp' alt='user-Friendly Experience' />
                  <div className="card-title">Product/Service Videos</div>
                  <div className="card-subtitle">
                    <p>Demonstrating how a product or service works, its features, benefits, and use cases, to persuade potential customers to make a purchase.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <div className="card-body"> <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632931/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Video%20Marketing/explainer_videos_p7rg27.webp' alt='Mobile-Optimized' />
                  <div className="card-title">Explainer Videos</div>
                  <div className="card-subtitle">
                    <p>Short, informative videos that explain complex concepts or processes in a simple and engaging manner.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-content">
                <div className="card-body"> <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632931/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Video%20Marketing/testimonials_vstqud.webp' alt='Mobile-Optimized' />
                  <div className="card-title">Testimonials and Case Studies</div>
                  <div className="card-subtitle">
                    <p>Videos featuring satisfied customers or clients sharing their positive experiences with a brand, product, or service.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-content">
                <div className="card-body"> <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632931/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Video%20Marketing/live_videos_njxlk9.webp' alt='Mobile-Optimized' />
                  <div className="card-title">Live Videos</div>
                  <div className="card-subtitle">
                    <p>Real-time streaming of events, Q&A sessions, interviews, or behind-the-scenes glimpses, which foster immediate interaction with the audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-content">
                <div className="card-body"> <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632931/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Video%20Marketing/tutorial_videos_ytepla.webp' alt='Mobile-Optimized' />
                  <div className="card-title">Tutorial and How-to Videos</div>
                  <div className="card-subtitle">
                    <p> Providing step-by-step instructions or tutorials on using a product, service, or solving specific problems.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-content">
                <div className="card-body"> <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632931/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Video%20Marketing/event_recap_uz4pey.webp' alt='Mobile-Optimized' />
                  <div className="card-title">Event Recap Videos</div>
                  <div className="card-subtitle">
                    <p>Summarizing and highlighting key moments from conferences, trade shows, or corporate events.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div id='seo_key' className="smo-types seo">
        <Title title="advantages of video Marketing" />
        <div className="seo-key-content">

          <div className="seo-key-content-text">
            <h2>High Engagement</h2>
            <hr />
            <p>Videos are more captivating than text-based content, leading to better viewer retention and increased message comprehension.</p>
          </div>

          <div className="seo-key-content-text">
            <h2>Enhanced Brand Awareness</h2>
            <hr />
            <p>Engaging videos help businesses establish a strong brand presence and leave a lasting impression on the audience.</p>
          </div>

          <div className="seo-key-content-text">
            <h2>Improved SEO</h2>
            <hr />
            <p>Search engines often prioritize video content, leading to higher search rankings and increased website traffic.</p>
          </div>

          <div className="seo-key-content-text">
            <h2>Increased Conversions</h2>
            <hr />
            <p>Well-crafted videos can persuade viewers to take desired actions, such as making a purchase or signing up for a service.</p>
          </div>

          <div className="seo-key-content-text">
            <h2>Emotional Connection</h2>
            <hr />
            <p>Videos can evoke emotions, allowing brands to connect with their audience on a deeper level.</p>
          </div>

          <div className="seo-key-content-text">
            <h2>Versatility</h2>
            <hr />
            <p>Video marketing can be adapted to various marketing goals, industries, and target audiences.</p>
          </div>

        </div>

      </div>

      <div className='project'>
        <div className="project-img">
          <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632931/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Video%20Marketing/project_j3fu5x.webp' alt="project" />
        </div>

        <div className='project-text'>
          <h2>looking for Video Marketing?</h2>
          <Link data-bs-toggle="modal" data-bs-target="#getintouchform" to={'#'}>get started</Link>
        </div>
        <InquiryForm />

      </div>
      <Footer />
    </div>
  )
}

export default VideoMarketing

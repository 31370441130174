import React from 'react'

const UpArrow = () => {

    const up = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    return (
        <div className='uparrow'>
            <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1691739553/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/up-arrow_jsfslg.png' alt="uparrow" onClick={up} />
        </div>
    )
}

export default UpArrow

import React from 'react'
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
const Header = () => {
    const up = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    // const [dis, setDis] = useState("none");
    // const show = () => {
    //     setDis('flex');
    // }
    // const hide = () => {
    //     setDis('none');
    // }
    return (
        <>
            
            <div className='header'>

                {[false, 'lg'].map((expand) => (
                    <Navbar key={expand} expand={expand} className="mb-3">
                        <Container fluid>
                            <Navbar.Brand>
                                <Link onClick={up} to={"/"}>
                                    <img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696153860/AHS%20Assured%20Services/Logo/ahs_logo_cvgolt_wbgmgo.png' alt="logo" className='logo' />
                                </Link>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                placement="end"
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>

                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>

                                    <Nav className="justify-content-center flex-grow-1 pe-3">
                                        <Nav.Link> <Link onClick={up} to={"/"}>Home</Link></Nav.Link>
                                        <Nav.Link><Link onClick={up} to={"/about_us"}>About Us</Link></Nav.Link>
                                        <NavDropdown title="OUR SERVICES" id={`offcanvasNavbarDropdown-expand-${expand}`} className='basic-nav-dropdown'>
                                            <div className='basic-nav-dropdown-body'>
                                                {/* <NavDropdown.Item target="_blank"><Link onClick={up} to={"/construction_services"}>Construction Services</Link></NavDropdown.Item> */}
                                                <NavDropdown.Item className='navdropdown-heading digital-header' target="_blank"><Link onClick={up} className='navdropdown-heading-head' to={"/digital_marketing_services"}>Digital Marketing Services</Link>
                                                    <ul className='navdropdown-subheadings'>
                                                        <Link
                                                            onClick={up}
                                                            to={"/smo"}>
                                                            <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632918/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/Header/smo_p8dsjm.webp' alt='smo' />
                                                            Social Media Optimization (SMO)
                                                        </Link>
                                                        <Link
                                                            onClick={up}
                                                            to={"/web_design_and_development"}>
                                                            <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632918/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/Header/web_dk4c3b.webp' alt='web design' />
                                                            website design & development
                                                        </Link>
                                                        <Link
                                                            onClick={up}
                                                            to={"/seo"}>
                                                            <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632918/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/Header/seo_cfhqbm.webp' alt='seo' />
                                                            Search Engine Optimization (SEO)
                                                        </Link>
                                                        <Link
                                                            onClick={up}
                                                            to={"/ppc"}>
                                                            <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632917/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/Header/ppc_ks8gr1.webp' alt='ppc' />
                                                            pay per click (PPC) advertising
                                                        </Link>
                                                        <Link
                                                            onClick={up}
                                                            to={"/content_marketing"}>
                                                            <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632917/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/Header/content_adgzgn.webp' alt='content markting' />
                                                            Content Marketing
                                                        </Link>
                                                        <Link
                                                            onClick={up}
                                                            to={"/bulk_marketing"}>
                                                            <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632917/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/Header/email_xfsgqt.webp' alt='bulk marketing' />
                                                            Bulk Marketing
                                                        </Link>
                                                        <Link
                                                            onClick={up}
                                                            to={"/video_marketing"}>
                                                            <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632918/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/Header/video_dtaxm7.webp' alt='video marketing' />
                                                            Video Marketing
                                                        </Link>
                                                        {/* <Link
                                                            onClick={up}
                                                            to={"/affiliate_marketing"}>
                                                            <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632916/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/Header/affiliate_b3wjbv.webp' alt='affiliate marketing' />
                                                            Affiliate marketing
                                                        </Link>
                                                        <Link
                                                            onClick={up}
                                                            to={"/influencer_marketing"}>
                                                            <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632917/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/Header/influencer_tb1dlb.webp'
                                                                alt='influencer marketing' />
                                                            Influencer Marketing
                                                        </Link> */}
                                                        <Link
                                                            onClick={up}
                                                            to={"/web-solutions"}>
                                                            <img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1700747891/AHS%20Assured%20Services/Icons/Header/web-solution_h7yz3k.png'
                                                                alt='influencer marketing' />
                                                            E-Voting Web Solution
                                                        </Link>
                                                    </ul>

                                                </NavDropdown.Item>
                                                <div>
                                                    <NavDropdown.Item className='navdropdown-heading staffing-header' target="_blank"><Link onClick={up} className='navdropdown-heading-head' to={"/staffing_services"}>Staffing Services</Link>
                                                        <ul className='navdropdown-subheadings'>
                                                            <Link
                                                                onClick={up}
                                                                to={"/staffing_services"}>
                                                                <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632918/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/Header/temporary_xsx8fd.webp'
                                                                    alt='Temporary staffing services' />
                                                                Temporary staffing services
                                                            </Link>
                                                            <Link
                                                                onClick={up}
                                                                to={"/staffing_services"}>
                                                                <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632917/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/Header/permanent_acczc4.webp' alt='Permanent staffing services' />
                                                                Permanent staffing services
                                                            </Link>
                                                            <Link
                                                                onClick={up}
                                                                to={"/staffing_services"}>
                                                                <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632917/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/Header/contract_fxj1xy.webp'
                                                                    alt='Contract staffing services' />
                                                                Contract staffing services
                                                            </Link>
                                                            <Link
                                                                onClick={up}
                                                                to={"/staffing_services"}>
                                                                <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632917/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/Header/executive_m7pxtx.webp' alt='Executive Search Services' />
                                                                Executive Search Services
                                                            </Link>

                                                        </ul>
                                                    </NavDropdown.Item>
                                                    {/* <NavDropdown.Item target="_blank"><Link onClick={up} to={"/business_strategy_consulting_services"}>Business Strategy Consulting Services</Link></NavDropdown.Item> */}
                                                    {/* <NavDropdown.Item className='navdropdown-heading' target="_blank"><Link onClick={up} className='navdropdown-heading-head' to={"/realestate_services"}>Real Estate Services</Link>
                                                    <ul className='navdropdown-subheadings'>
                            <Link onClick={up} to={"/realestate_services"}><img rel="preload" src={sale} alt='Sale'/>Sale</Link>
                            <Link onClick={up} to={"/realestate_services"}><img rel="preload" src={purchase} alt='Purchase'/>Purchase</Link>
                            <Link onClick={up} to={"/realestate_services"}><img rel="preload" src={residential} alt='Residential/Commercial Property'/>Residential/Commercial Property</Link>
                          </ul>
                        </NavDropdown.Item> */}
                                                </div>
                                            </div>
                                        </NavDropdown>
                                        <Nav.Link><Link onClick={up} to={"/portfolio"}>Portfolio</Link></Nav.Link>
                                        <Nav.Link><Link onClick={up} to={"/blog"}>Blog</Link></Nav.Link>
                                        <Nav.Link><Link onClick={up} to={"/careers"}>Careers</Link></Nav.Link>
                                        <Nav.Link><Link onClick={up} to={"/faq"}>Faq</Link></Nav.Link>
                                        {/* <Nav.Link><Link onClick={up} to={"/contact"}>Contact</Link></Nav.Link> */}
                                        {/* <Nav.Link><Link onClick={up} to={"/contact"}>Contact Us</Link></Nav.Link> */}
                                        <div className='w'>
                                            <a href="https://wa.me/917290890032/?text=Hi,%0a%0aI have a query related to AHS Services, wish to connect for further discussion.%0a%0aRegards%0a%0aUser from AHS Website%0awww.ahsassuredservices.com " target="_blank" rel="noreferrer"> Chat Now  <img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1697038334/AHS%20Assured%20Services/Icons/footer/whatsapp_2504957_gjjxbb.png' alt="whatsapp" /></a>

                                        </div>

                                    </Nav>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                ))}

            </div>
        </>
    )
}

export default Header

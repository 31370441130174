import React, { useState, useEffect } from 'react'
import Header from '../../Utilities/Header'
import Footer from '../../Utilities/Footer'
import "./Portfolio2.scss"
import ReactPlayer from 'react-player'
import { transform } from 'framer-motion'

const Portfolio2 = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    let btn_id;
    const retrieveText = (e) => {

        // let text = e.target.text;
        // setText(text);
        btn_id = e.target.id;

        if (btn_id == "logo") {
            setBorder({
                logo: "2px 2px 10px #fff",
                websites: "",
                social: "",
                reels: ""
            });
            setBackground({
                logo: "#16243d",
                websites: "",
                social: "",
                reels: ""
            });
            setCOlor({
                logo: "#fff",
                websites: "",
                social: "",
                reels: ""
            });
            setCategory({
                websites: false,
                logo: true,
                social_media_posts: false,
                videos: false
            })
            setTransform({
                logo: "scale(1.1)",
                websites: "",
                social: "",
                reels: ""
            });
        } else if (btn_id == "social_media_posts") {
            setBorder({
                logo: "",
                websites: "",
                social: "2px 2px 10px #fff",
                reels: ""
            });
            setBackground({
                logo: "",
                websites: "",
                social: "#16243d",
                reels: ""
            });
            setCOlor({
                logo: "",
                websites: "",
                social: "#fff",
                reels: ""
            });
            setCategory({
                websites: false,
                logo: false,
                social_media_posts: true,
                videos: false
            })
            setTransform({
                logo: "",
                websites: "",
                social: "scale(1.1)",
                reels: ""
            });
        } else if (btn_id == "videos") {
            setCategory({
                websites: false,
                logo: false,
                social_media_posts: false,
                videos: true
            })
            setBorder({
                logo: "",
                websites: "",
                social: "",
                reels: "2px 2px 10px #fff"
            });
            setBackground({
                logo: "",
                websites: "",
                social: "",
                reels: "#16243d"
            });
            setCOlor({
                logo: "",
                websites: "",
                social: "",
                reels: "#fff"
            });
            setTransform({
                logo: "",
                websites: "",
                social: "",
                reels: "scale(1.1)"
            });
        }
        else if (btn_id == "websites") {
            setCategory({
                websites: true,
                logo: false,
                social_media_posts: false,
                videos: false
            })
            setBorder({
                logo: "",
                websites: "2px 2px 10px #fff",
                social: "",
                reels: ""
            });
            setBackground({
                logo: "",
                websites: "#16243d",
                social: "",
                reels: ""
            });
            setCOlor({
                logo: "",
                websites: "#fff",
                social: "",
                reels: ""
            });
            setTransform({
                logo: "",
                websites: "scale(1.1)",
                social: "",
                reels: ""
            });
        }
    }

    const websites = [
        {
            id: "Bliss",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706594194/AHS%20Assured%20Services/Portfolio/Banners/Bliss_School_Of_Music_wszn6s.webp",
            title: "Bliss School Of Music",
            url: "https://www.blissmusicschool.com/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510113/Bliss%20School%20Of%20Music/Logo/BSOM_transparent_ffnlvs.png",
            category: "education",
        },
        {
            id: "Dwarka",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706594194/AHS%20Assured%20Services/Portfolio/Banners/Dwarka_School_Of_Music_wd3oti.webp",
            title: "Dwarka School Of Music",
            url: "https://www.dwarkaschoolofmusic.com/",
            logo: "https://res.cloudinary.com/dcimsha8g/image/upload/v1691563397/Dwarka%20School%20Of%20Music/Logo/m_logo_jispoe.ico",
            category: "education",
        },
        {
            id: "Competency",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706594194/AHS%20Assured%20Services/Portfolio/Banners/Competency_Enhancers_fblaqi.webp",
            title: "Competency Enhancers",
            url: "https://www.competencyenhancers.com/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803151/CE/Logo/Title_logo_d2u8qm.webp",
            category: "education",
        },
        {
            id: "WiseFinserv",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1708081519/AHS%20Assured%20Services/Portfolio/Banners/Wise_Finserv_mv2t2z.webp",
            title: "Wise Finserv Private Wealth",
            url: "https://www.wisefinserv.com/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699732241/Wise%20FinServ%20Private%20Wealth/Logo/header-logo_mifelq.png",
            category: "corporate",
        },
        {
            id: "ThinkTank",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706594194/AHS%20Assured%20Services/Portfolio/Banners/Think_Tank_Research_Group_rhgr7q.webp",
            title: "Think Tank Research Group",
            url: "https://www.thinktankresearch.co/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1694512543/Think_Tank/Logo/TTR_Logo_oqhg4l_av9kaj.webp",
            category: "corporate",
        },
        {
            id: "Well",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706594194/AHS%20Assured%20Services/Portfolio/Banners/Well_Wishers_Home_Health_Care_lo40o4.webp",
            title: "Well Wishers Home Healthcare",
            url: "https://www.wellwishershomehealthcare.com/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696942440/AHS%20Assured%20Services/Our%20Projects/Well%20Wishers/logo_ru6fub.png",
            category: "healthcare",
        },
        {
            id: "Akcent",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706594195/AHS%20Assured%20Services/Portfolio/Banners/Akcent_Healthcare_g4ow7t.webp",
            title: "Akcent Healthcare Private Limited",
            url: "https://www.akcenthealthcare.com/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699082511/Akcent%20HealthCare/Logo/logo_transparent_i0ueir_bltcxv.png",
            category: "healthcare",
        },
        {
            id: "Axcelto",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706685044/AHS%20Assured%20Services/Portfolio/Banners/Axcelto_jge2co.png",
            title: "Axcelto",
            url: "https://www.axcelto.in/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1704886027/Axcelto/logo_mvyutp.webp",
            category: "healthcare",
        },
        {
            id: "Veinosil",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706685054/AHS%20Assured%20Services/Portfolio/Banners/Veinosil_ryhstx.png",
            title: "Veinosil",
            url: "https://www.veinosil.in/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1704713491/Veinosil-Forte/logo_vkja5q.webp",
            category: "healthcare",
        },
        {
            id: "Murine",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706685054/AHS%20Assured%20Services/Portfolio/Banners/Murine_mfa4fx.png",
            title: "Murine",
            url: "https://www.murine.in/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1703923010/Murine/logo_vfgcul.png",
            category: "healthcare",
        },
        {
            id: "Aksam",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706685044/AHS%20Assured%20Services/Portfolio/Banners/Aksam_fjkpxf.png",
            title: "Aksam",
            url: "https://www.aksam.in/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701952166/Aksam/logo_kj4gne.webp",
            category: "healthcare",
        },
        {
            id: "Beklair",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706685048/AHS%20Assured%20Services/Portfolio/Banners/Beklair_qywxnx.png",
            title: "Beklair",
            url: "https://www.beklair.in/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1702013694/Beklair/logo_kesrb4.webp",
            category: "healthcare",
        },
        {
            id: "Beveles",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706685047/AHS%20Assured%20Services/Portfolio/Banners/Beveles_lqvne7.png",
            title: "Beveles",
            url: "https://www.beveles.in/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1702558568/Beveles/logo_o91g4b.webp",
            category: "healthcare",
        },
        {
            id: "Beluksi",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706685046/AHS%20Assured%20Services/Portfolio/Banners/Beluksi_s60kdt.png",
            title: "Beluksi",
            url: "https://www.beluksi.in/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1702633710/Beluksi/logo_msrwba.webp",
            category: "healthcare",
        },
        {
            id: "Betkas",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706685049/AHS%20Assured%20Services/Portfolio/Banners/Betkas_siuibt.png",
            title: "Betkas",
            url: "https://www.betkas.in/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1702016483/Betkas-N3/logo_fpi5xp.webp",
            category: "healthcare",
        },
        {
            id: "Bmarco",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706685047/AHS%20Assured%20Services/Portfolio/Banners/Bmarco_g8iwox.png",
            title: "Bmarco",
            url: "https://www.bmarco.in/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701934634/B-Marco/logo_goimk2.png",
            category: "healthcare",
        },
        {
            id: "Collared",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706685049/AHS%20Assured%20Services/Portfolio/Banners/Collared_j0jqdo.png",
            title: "Collared",
            url: "https://www.collared.in/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1702125402/Collared/logo_zjql0r.webp",
            category: "healthcare",
        },
        {
            id: "Emarco",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706685048/AHS%20Assured%20Services/Portfolio/Banners/Emarco_xa46pu.png",
            title: "Emarco",
            url: "https://www.emarco.in/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1702898991/EMarco/logo_atkhot.png",
            category: "healthcare",
        },
        {
            id: "Enrowin",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706685055/AHS%20Assured%20Services/Portfolio/Banners/Enrowin_gbmgw2.png",
            title: "Enrowin",
            url: "https://www.enrowin.in/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1702895379/Enrowin/logo_pbhgbk.png",
            category: "healthcare",
        },
        {
            id: "Meluktor",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706685053/AHS%20Assured%20Services/Portfolio/Banners/Meluktor_x6nsd4.png",
            title: "Meluktor",
            url: "https://www.meluktor.in/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699086529/meluktor.com/meluktor_500_logo_noujzc.png",
            category: "healthcare",
        },
        {
            id: "Proviam",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706685052/AHS%20Assured%20Services/Portfolio/Banners/Proviam_eu8xaw.png",
            title: "Proviam",
            url: "https://www.proviam.in/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1702968538/Proviam/proviam_3_zkfeqo.png",
            category: "healthcare",
        },
        {
            id: "Trimyou",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706685056/AHS%20Assured%20Services/Portfolio/Banners/Trimyou_frlc76.png",
            title: "Trimyou",
            url: "https://www.trimyou.in/",
            logo: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1702345740/Trimyou/trimyou_logo_x2mmos_ycthmh.webp",
            category: "healthcare",
        },

    ]

    const creativeAdvertisement = [
        {
            id: "logo19",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1723008706/AHS%20Assured%20Services/Portfolio/Creative/Logo/GC_venture_1_u3updu.webp",
            category: "logo",
        },
        {
            id: "logo18",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1722252420/AHS%20Assured%20Services/Portfolio/Creative/Logo/Chai_btzluz.webp",
            category: "logo",
        },

        {
            id: "logo2",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706856186/AHS%20Assured%20Services/Portfolio/Creative/Logo/2_vs71cv.webp",
            category: "logo",
        },
        {

            id: "logo16",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713006664/AHS%20Assured%20Services/Portfolio/Creative/Logo/artplus_bockfq.webp",
            category: "logo",
        },
        {
            id: "logo17",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713006663/AHS%20Assured%20Services/Portfolio/Creative/Logo/path_of_music_vb8tkj.webp",
            category: "logo",
        },
        {
            id: "logo3",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706870406/AHS%20Assured%20Services/Portfolio/Creative/Logo/4_w7cepl.webp",
            category: "logo",
        },
        {
            id: "logo4",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706803858/AHS%20Assured%20Services/Portfolio/Creative/Logo/3_c37sbl.webp",
            category: "logo",
        },
        {
            id: "logo12",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1711956862/AHS%20Assured%20Services/Portfolio/Creative/Logo/8_x4sb8w.webp",
            category: "logo",
        }, {
            id: "logo1",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706803858/AHS%20Assured%20Services/Portfolio/Creative/Logo/1_epw2yp.webp",
            category: "logo",
        },
        {
            id: "logo13",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1711956859/AHS%20Assured%20Services/Portfolio/Creative/Logo/9_ohp757.webp",
            category: "logo",
        },
        {
            id: "logo14",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1711956858/AHS%20Assured%20Services/Portfolio/Creative/Logo/10_ymhmj3.webp",
            category: "logo",
        },
        {
            id: "logo5",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1711956929/AHS%20Assured%20Services/Portfolio/Creative/Logo/5_qkited.webp",
            category: "logo",
        },
        {
            id: "logo6",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1711957057/AHS%20Assured%20Services/Portfolio/Creative/Logo/1_qyrn06.webp",
            category: "logo",
        },
        {
            id: "logo7",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1711957055/AHS%20Assured%20Services/Portfolio/Creative/Logo/2_kipiua.webp",
            category: "logo",
        },
        {
            id: "logo8",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1711957025/AHS%20Assured%20Services/Portfolio/Creative/Logo/3_rojemd.webp",
            category: "logo",
        },
        {
            id: "logo9",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1711957016/AHS%20Assured%20Services/Portfolio/Creative/Logo/4_cefk34.webp",
            category: "logo",
        },
        {
            id: "logo10",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1711956922/AHS%20Assured%20Services/Portfolio/Creative/Logo/6_plyx28.webp",
            category: "logo",
        },

        {
            id: "logo11",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1711956907/AHS%20Assured%20Services/Portfolio/Creative/Logo/7_rsofdh.webp",
            category: "logo",
        },
        {
            id: "social_media_posts8",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002735/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/25_gmlblc.webp",
            category: "social_media_posts",
        },

        {
            id: "social_media_posts10",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002734/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/24_lebmhz.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts11",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002733/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/23_ejwtvb.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts12",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002732/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/15_l9sibg.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts13",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002732/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/22_kj8yed.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts14",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002730/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/21_zzcraw.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts15",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002728/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/19_qexxc2.webp",
            category: "social_media_posts",
        }, {
            id: "social_media_posts9",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002736/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/26_l9d0zz.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts16",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002728/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/20_rmwwwx.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts17",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002727/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/18_epot3y.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts18",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002726/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/17_gr2sjr.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts19",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002725/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/16_flresg.webp",
            category: "social_media_posts",
        }, {
            id: "social_media_posts20",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002722/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/14_zexelx.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts21",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002721/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/13_j7fph7.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts22",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002721/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/11_m4re1j.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts23",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002720/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/12_aqq9k2.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts24",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002719/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/10_ckimjz.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts25",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002718/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/3_cdbk80.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts26",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002717/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/9_uggkwc.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts27",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002716/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/8_mykvui.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts28",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002714/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/7_b61xrc.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts29",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002713/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/5_ktx9ir.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts30",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002712/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/6_wu7rqv.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts31",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002712/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/4_h7rzqq.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts32",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002711/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/2_dkwtsf.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts33",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713002711/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/1_iw3oj7.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts1",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706805279/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/2_r3x9xw.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts2",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706805276/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/3_skuslk.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts3",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706805273/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/4_bo4gcb.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts4",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706805283/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/1_wxovlq.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts5",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706857899/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/1_mcmicj.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts6",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706857899/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/3_ueilzj.webp",
            category: "social_media_posts",
        },
        {
            id: "social_media_posts7",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1706857899/AHS%20Assured%20Services/Portfolio/Creative/Social%20Media%20Posts/2_gapbtf.webp",
            category: "social_media_posts",
        },
        // {
        //     id: "videos",
        //     src: "https://www.youtube.com/watch?v=UeGL8zWSpwI",
        //     category: "videos"
        // },
        // {
        //     id: "videos",
        //     src: "https://www.youtube.com/watch?v=WDsspi7yJSs&t=47s",
        //     category: "videos"
        // },
        // {
        //     id: "videos",
        //     src: "https://www.youtube.com/watch?v=E7QiuDF003k",
        //     category: "videos"
        // },
        // {
        //     id: "videos",
        //     src: "https://www.youtube.com/watch?v=GCF7vXSeN7I",
        //     category: "videos"
        // },

        // pmc
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1722320422/AHS%20Assured%20Services/Portfolio/Creative/Reels/Annual_Music_Concert_-_Reel_ewv3fh.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1722321710/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/PMC-_promotional_video_qsw7yj.png",
        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1722320422/AHS%20Assured%20Services/Portfolio/Creative/Reels/Annual_Music_Concert_-_Reel_ewv3fh.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1722321192/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/Annual_Music_Concert_-_Thumbnail_dulhxh.png",
        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1722321544/AHS%20Assured%20Services/Portfolio/Creative/Reels/Students_dance_reel_xt24pj.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1722321426/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/Students_dance_reel_1_xc9nmn.png",
        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1718611595/AHS%20Assured%20Services/Portfolio/Creative/Reels/PMC_-_Piano_Reel_qslki0.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1718611144/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/PMC_-_Piano_Reel_lgmlpt.png",
        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1718610819/AHS%20Assured%20Services/Portfolio/Creative/Reels/Vocal_performance_fcley4.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1718610669/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/Vocal_performance_fdhqkw.png",
        }, {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1715759819/AHS%20Assured%20Services/Portfolio/Creative/Reels/behind_rdqwfp.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1715759526/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/behind_h7qep1.png",

        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1715758868/AHS%20Assured%20Services/Portfolio/Creative/Reels/STUDIO_TOUR_ozjpd8.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1715759424/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/STUDIO_TOUR_sl08f6.png",

        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1718611086/AHS%20Assured%20Services/Portfolio/Creative/Reels/Fine_Art_Reel_-_PMC_qypzfs.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1718611146/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/Fine_Art_Reel_-_PMC_qidfs8.png",
        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1718611139/AHS%20Assured%20Services/Portfolio/Creative/Reels/PMC_-_Synthesizer_Reel_yahfwx.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1718611147/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/PMC_-_Synthesizer_Reel_dbbqod.png",
        },

        // pmc ends

        // dsom
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1723009115/AHS%20Assured%20Services/Portfolio/Creative/Reels/Launch_-_DSOM_okmc7c.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1723009249/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/Launch_-_DSOM_heaieb.png",
        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1718610623/AHS%20Assured%20Services/Portfolio/Creative/Reels/drum_Performance_ggfxos.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1718610669/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/_drum_Performance_cgvawy.png",
        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1718611134/AHS%20Assured%20Services/Portfolio/Creative/Reels/Guitar_Performance_mhznue.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1718611151/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/Guitar_Performance_eebivv.png",
        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1713012221/AHS%20Assured%20Services/Portfolio/Creative/Reels/16_spq22p.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713159190/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/16_v57hhk.webp",

        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1718611137/AHS%20Assured%20Services/Portfolio/Creative/Reels/Dipasree_Chaterjee_gftbbq.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1718611145/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/Dipasree_Chaterjee_edhzfh.png",
        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1713012235/AHS%20Assured%20Services/Portfolio/Creative/Reels/13_rdz668.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713159361/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/13_khabnj.webp",

        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1713012324/AHS%20Assured%20Services/Portfolio/Creative/Reels/11_ths8tx.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713159186/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/11_gkf0ir.webp",

        },
        //    dsom ends

        // ahs 
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1723009185/AHS%20Assured%20Services/Portfolio/Creative/Reels/Website_Ad_video_pandeq.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1723009556/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/Website_Ad_video_n95dvw.png",
        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1718610557/AHS%20Assured%20Services/Portfolio/Creative/Reels/Paid_Advertising_vs_Organic_Reach_ntvd1k.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1718610668/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/Paid_Advertising_vs_Organic_Reach_suaju8.png",
        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1718611379/AHS%20Assured%20Services/Portfolio/Creative/Reels/Who_are_we_bz7bji.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1718611145/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/Who_are_we_l7yhzy.png",
        },


        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1715758843/AHS%20Assured%20Services/Portfolio/Creative/Reels/why_do_you_need_bulk_marketing_gqa7pf.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1715759720/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/why_do_you_need_bulk_marketing_x0va9z.png",

        },

        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1715756701/AHS%20Assured%20Services/Portfolio/Creative/Reels/Website_Reel_ilklvl.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1715756770/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/Website_Reel_mdod1u.png",

        },
        // ahs ends




        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1713012233/AHS%20Assured%20Services/Portfolio/Creative/Reels/17_higssy.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713159188/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/17_gahgju.webp",

        },

        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1713012327/AHS%20Assured%20Services/Portfolio/Creative/Reels/15_bexssg.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713159143/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/15_zlizmk.webp",

        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1713012329/AHS%20Assured%20Services/Portfolio/Creative/Reels/14_zxqjus.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713159142/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/14_xgut5o.webp",

        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1713012338/AHS%20Assured%20Services/Portfolio/Creative/Reels/9_gmtpcd.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713159140/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/9_r8nuez.webp",

        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1715758852/AHS%20Assured%20Services/Portfolio/Creative/Reels/ArtPlus_-_showcase_video_kjkjfs.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1715759421/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/ArtPlus_-_showcase_video_iuq1gg.png",

        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1715756763/AHS%20Assured%20Services/Portfolio/Creative/Reels/IKUS_Factory_Work_-_Studio9_b7dxlj.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1715756769/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/IKUS_Factory_Work_-_Studio9_laensx.png",

        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1713012342/AHS%20Assured%20Services/Portfolio/Creative/Reels/12_puohqq.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713159139/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/12_scqyzi.webp",

        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1718610549/AHS%20Assured%20Services/Portfolio/Creative/Reels/from_dream_to_reality_bkoune.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1715759423/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/Copy_of_Website_Reel_zjp8ic.png",

        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1713012344/AHS%20Assured%20Services/Portfolio/Creative/Reels/8_fdnrdj.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713159137/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/8_orccyt.webp",

        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1713012348/AHS%20Assured%20Services/Portfolio/Creative/Reels/7_mos2cg.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713159136/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/7_stqqel.webp",

        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1713012353/AHS%20Assured%20Services/Portfolio/Creative/Reels/10_fzfvud.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713159136/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/10_senzhb.webp",

        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1713012353/AHS%20Assured%20Services/Portfolio/Creative/Reels/2_onjxcx.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713159192/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/2_zslhse.webp",

        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1713006063/AHS%20Assured%20Services/Portfolio/Creative/Reels/Paint_trends_4_iqbjws.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713160356/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/paint_trends_qpgkqd.webp"
        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1713006063/AHS%20Assured%20Services/Portfolio/Creative/Reels/Time_for_a_kitchen_dream_makeover_1_s3iebq.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713160353/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/time_for_a_kitchen_dream_makeover_xrklgn.webp"
        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1713006133/AHS%20Assured%20Services/Portfolio/Creative/Reels/Commercial_Architecture_q8ph8p.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713160358/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/commercial_architecture_c3neps.webp"
        },
        {
            id: "videos",
            src: "https://res.cloudinary.com/ddwk3hbiu/video/upload/v1713006170/AHS%20Assured%20Services/Portfolio/Creative/Reels/interior_znbmfb.mp4",
            category: "videos",
            thumbnail: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713160352/AHS%20Assured%20Services/Portfolio/Creative/Reels/thumbnail/interior_cihecv.webp"
        },
        {
            id: "videos",
            src: "https://www.youtube.com/shorts/YYlFA0BtkrA",
            category: "videos"
        },

        {
            id: "videos",
            src: "https://www.youtube.com/shorts/aotJ1eIe1J4",
            category: "videos"
        },
        {
            id: "videos",
            src: "https://www.youtube.com/shorts/41M7U58VPBs",
            category: "videos"
        },
        {
            id: "videos",
            src: "https://www.youtube.com/shorts/AhEYq3gNSBI",
            category: "videos"
        },
        {
            id: "videos",
            src: "https://www.youtube.com/shorts/SURH8AWU2o0",
            category: "videos"
        },
        {
            id: "videos",
            src: "https://www.youtube.com/shorts/V751mudhRuM",
            category: "videos"
        },
        {
            id: "videos",
            src: "https://www.youtube.com/shorts/Dm7IYnyZyYA",
            category: "videos"
        },
        {
            id: "videos",
            src: "https://www.youtube.com/shorts/ylPmVj1WKkE",
            category: "videos"
        },
        {
            id: "videos",
            src: "https://www.youtube.com/shorts/YYlFA0BtkrA",
            category: "videos"
        },
        {
            id: "videos",
            src: "https://www.youtube.com/shorts/ZW9bDKao_hs",
            category: "videos"
        },


    ]

    const [category, setCategory] = useState({
        websites: true,
        logo: false,
        social_media_posts: false,
        videos: false,
    })
    const [border, setBorder] = useState({
        logo: "",
        websites: "2px 2px 10px #fff",
        social: "",
        reels: ""
    });
    const [transform, setTransform] = useState({
        logo: "",
        websites: "scale(1.1)",
        social: "",
        reels: ""
    });
    const [color, setCOlor] = useState({
        logo: "",
        websites: "#fff",
        social: "",
        reels: ""
    });

    const [background, setBackground] = useState({
        logo: "",
        websites: "#16243d",
        social: "",
        reels: ""
    });
    return (
        <div>
            <Header />
            <div className="portfolio2">
                <div className="section-head col-sm-12">
                    <h4><span>Portfolio</span> </h4>
                </div>
                {/* <h4>{text}</h4> */}
                <div className="head">
                    <a href="#" id='websites' onClick={retrieveText} style={{ textShadow: border.websites, background: background.websites, color: color.websites, transform: transform.websites, }}>Websites</a>
                    <a href="#" id='logo' onClick={retrieveText} style={{ textShadow: border.logo, background: background.logo, color: color.logo, transform: transform.logo }}>Logos</a>
                    <a href="#" id='social_media_posts' style={{ textShadow: border.social, color: color.social, transform: transform.social, background: background.social }} onClick={retrieveText}>Social Media Posts</a>
                    <a href="#" id='videos' onClick={retrieveText} style={{ textShadow: border.reels, color: color.reels, transform: transform.reels, background: background.reels }}>Reels</a>
                </div>
                {category.websites && (
                    <div className="websites-body" id='portfolio-websites'>
                        {websites.map((item) => (
                            <div className="websites-card" id={item.id}>
                                <div className="websites-img">
                                    <img src={item.img} alt={item.title} />
                                </div>
                                <div className="websites-text">
                                    <div className="websites-text-logo">
                                        <img src={item.logo} alt="logo" />
                                    </div>
                                    <div className="websites-text-caption">
                                        <h6>{item.title}</h6>
                                        <a target='_blank' href={item.url}>
                                            <svg id="Layer_1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m10.88 11.54 1 .46-1 .46a7.06 7.06 0 0 0 0-.92m-2.88-3.54a9.41 9.41 0 0 1 0 8l7.75-3.6a.45.45 0 0 0 0-.8zm13-4h-18a1 1 0 0 0 -1 1v14a1 1 0 0 0 1 1h9v-2h-8v-12h16v7.84h2v-8.84a1 1 0 0 0 -1-1zm-2 13c-1.67 0-3.33 1-5 3 1.67 2 3.33 3 5 3s3.33-1 5-3c-1.67-2-3.33-3-5-3zm-1 3a1 1 0 1 1 1 1 1 1 0 0 1 -1-1z" /></svg>
                                            Live Preview
                                        </a>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {category.logo && (
                    <div className="websites-body" id='portfolio-logos'>
                        {creativeAdvertisement.filter(item => item.category == "logo").map((item) => (
                            <div className="websites-card" id={item.id} style={{ marginBottom: "1%" }}>
                                <div className="websites-img">
                                    <img src={item.img} alt={item.title} />
                                </div>

                            </div>
                        ))}
                    </div>
                )}
                {category.social_media_posts && (
                    <div className="websites-body" id='portfolio-social-media-posts'>
                        {creativeAdvertisement.filter(item => item.category == "social_media_posts").map((item) => (
                            <div className="websites-card" id={item.id} style={{ marginBottom: "1%" }}>
                                <div className="websites-img">

                                    <img src={item.img} alt={item.title} />
                                </div>

                            </div>
                        ))}
                    </div>
                )}
                {category.videos && (
                    <div className="websites-body" id="portfolio-reels">

                        {creativeAdvertisement.filter(item => item.category == "videos").map((item) => (
                            <div className="websites-card websites-videos" id={item.id} style={{ marginBottom: "1%" }}>
                                <div className="websites-img ">

                                    <ReactPlayer
                                        className="videoFrame"
                                        url={item.src}
                                        light={item.thumbnail}
                                        controls={true}
                                        playing={false}
                                        playIcon={true}
                                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                    />
                                </div>

                            </div>
                        ))}
                    </div>
                )}



            </div>

            <Footer />
        </div>
    )
}

export default Portfolio2

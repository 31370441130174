import React from 'react'
import Header from '../../../Utilities/Header'
import Footer from '../../../Utilities/Footer'
import { useEffect } from 'react'
import UpArrow from '../../../Utilities/UpArrow'
import Bot from '../../../Functionalities/Bot'
import { Link } from 'react-router-dom'
import Title from '../../../Utilities/Title'
import InquiryForm from '../../../Utilities/InquiryForm'
import { Helmet } from "react-helmet";

const Seo = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Search Engine Optimization - Best SEO Services In India </title>
        <link rel="canonical" href="https://www.ahsassuredservices.com/seo" />
        <meta name="description" content="Boost Your Online Visibility with AHS's Affordable SEO Services. Our expert team offers comprehensive SEO solutions, including SEO audit, on-page seo, off-page seo, technical seo, link building, etc. Elevate your online presence and drive organic growth with our cost-effective SEO strategies." />

      </Helmet>
      <Bot />
      <UpArrow />
      <Header />
      <div id='seo'>
        <div className='banner-hero'>

          <div className="banner-hero-text">

            <h1>Search Engine Optimization</h1>
            <div className="content">
              <h2>Fueling Your Online Growth with Cutting-Edge SEO Strategies</h2>
            </div>
            {/* <a href='#seo_key'>Learn More</a> */}
          </div>
          <div className="banner-hero-img">
            <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632929/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/SEO/bg_vzbieo.webp' alt="seo-background" className='img-fluid' />
          </div>

        </div>


        <div className="smo-what">

          <div className="smo-what-text">
            <Title title="What is Search Engine Optimization?" />

          </div>

          <div className="smo-what-content">

            <div className="smo-what-content-text">
              <p>Search Engine Optimization (SEO) is the practice of optimizing websites to improve their visibility in search engine results. It involves techniques like keyword research, on-page optimization (improving elements within a website), off-page optimization (activities conducted outside the website), and technical optimization (enhancing website performance). The goal of SEO is to attract organic (non-paid) traffic by making the website more appealing to search engines and users. This is achieved through strategies like creating high-quality content, acquiring backlinks, and ensuring a positive user experience.
              </p>

              <p>In summary, SEO is about optimizing websites to rank higher in search results, attract organic traffic, and improve online visibility. It involves various techniques to make websites more appealing to search engines and users, such as keyword research, on-page and off-page optimization, and technical improvements. The ultimate objective is to increase website visibility, drive relevant traffic, and provide a positive user experience.

              </p>
            </div>

            <div className="smo-what-content-img">
              <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632930/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/SEO/seo_what_nstmq7.webp' alt="what is seo?" />
            </div>

          </div>

          {/* <p className='last'>Social Media Optimisation is an essential component as it helps increase brand awareness, drive traffic to a website, and improve search engine rankings.</p> */}
        </div>

        <div className='project'>
          <div className="project-img">
            <img rel="preload" loading="lazy" className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632930/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/SEO/seo_hire_tbjrnl.webp' alt="project" />
          </div>
          <div className='project-text'>
            <h2>Looking for SEO? <br />we got you covered</h2>
            <Link data-bs-toggle="modal" data-bs-target="#getintouchform" to={'#'}>get started</Link>
          </div>
          <InquiryForm />

        </div>

        <div id='seo_key' className="smo-types seo">
          <Title title="key aspects of SEO" />
          <div className="seo-key-content">

            <div className="seo-key-content-text">
              <h2>Keywords</h2>
              <hr />
              <p> SEO starts with keyword research. Identifying relevant keywords and phrases that users are searching for helps you optimize your content and target the right audience. Keyword research tools like Google Keyword Planner can assist in finding popular and relevant keywords.</p>
            </div>

            <div className="seo-key-content-text">
              <h2>On-Page Optimization</h2>
              <hr />
              <p>This involves optimizing various elements on your web pages to make them more search engine friendly. Key on-page elements include the title tag, meta description, headers, URL structure, image alt tags, and keyword usage within the content. On-page optimization ensures that search engines can understand the content and relevance of your web pages.</p>
            </div>

            <div className="seo-key-content-text">
              <h2>Off-Page Optimization</h2>
              <hr />
              <p> This refers to activities conducted outside of your website to improve its visibility and reputation. The primary focus is on link building, which involves acquiring high-quality backlinks from other reputable websites. Off-page optimization also includes social media marketing, influencer outreach, and online reputation management.</p>
            </div>

            <div className="seo-key-content-text">
              <h2>Technical SEO</h2>
              <hr />
              <p>This aspect involves optimizing the technical aspects of a website to enhance its crawlability and indexability by search engines. It includes tasks like improving site speed, ensuring mobile-friendliness, optimizing site architecture, implementing structured data markup, creating XML sitemaps, and resolving any technical issues that may affect search engine visibility.</p>
            </div>

            <div className="seo-key-content-text">
              <h2>Content Creation</h2>
              <hr />
              <p>High-quality and relevant content is crucial for SEO success. Search engines prioritize websites that provide valuable information to users. Create unique, engaging, and informative content that incorporates target keywords naturally. Regularly updating your website with fresh content can also improve your search rankings.</p>
            </div>

            <div className="seo-key-content-text">
              <h2>User Experience (UX)</h2>
              <hr />
              <p> Search engines value websites that offer a positive user experience. Factors such as page load speed, mobile responsiveness, intuitive navigation, and clear call-to-actions contribute to a better user experience. Optimizing for UX can improve your search rankings and increase user engagement.</p>
            </div>

            <div className="seo-key-content-text">
              <h2>Analytics and Monitoring</h2>
              <hr />
              <p>It's important to monitor your website's performance using tools like Google Analytics. This helps you track organic traffic, user behavior, conversions, and other relevant metrics. By analyzing this data, you can make informed decisions and identify areas for improvement.</p>
            </div>


            <div className="seo-key-content-text">
              <h2>Local SEO</h2>
              <hr />
              <p> If you have a local business, optimizing for local search is crucial. This involves creating and optimizing a Google My Business listing, ensuring consistent NAP (Name, Address, Phone number) information across directories, and acquiring local citations and reviews.</p>
            </div>


            <div className="seo-key-content-text">
              <h2>Algorithm Updates</h2>
              <hr />
              <p>Search engines regularly update their algorithms, which can impact search rankings. Staying updated with industry news and algorithm changes can help you adapt your SEO strategies accordingly and maintain your search visibility.</p>
            </div>

          </div>

        </div>

      </div>
      <Footer />
    </div>
  )
}

export default Seo

import React from 'react'
import Footer from '../../Utilities/Footer'
import Card from 'react-bootstrap/Card';
import Bot from '../../Functionalities/Bot'
import UpArrow from '../../Utilities/UpArrow';
import Header from '../../Utilities/Header';
import { useEffect } from 'react';
import Title from '../../Utilities/Title';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import InquiryForm from '../../Utilities/InquiryForm';

const StaffingServices = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);


  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AHS Assured Services Pvt Ltd - staffing Services</title>
        <link rel="canonical" href="https://www.ahsassuredservices.com/staffing_services" />
        <meta name="description" content="Find the perfect fit for your workforce with our staffing services. Trust our expert manpower consultancy for top-tier talent solutions in India and experience unparalleled recruitment consultancy solutions. Boost your workforce today!" />
      </Helmet>
      <UpArrow />
      <Header />
      <Bot />
      <div className="staffing">
        <div className='banner-hero'>
          <div className="banner-hero-text">
            <h1>Staffing Services</h1>
            <div className="content">
              <h2>Where Talent Meets Opportunity</h2>
            </div>
            <a href='#staffing-services'>Learn More</a>
          </div>

          <div className="banner-hero-img">
            <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632936/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/hero-img_p7eoic.webp' alt="staffing-background" className='img-fluid' />
          </div>

        </div>

        <div className="staffing-hiring overview">
          <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632937/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/overview_jdw7ke.webp' alt="hiring" className='img-fluid' />
          <div className="staffing-hiring-text overview-text">
            <Title title="Overview" />
            <p>Finding Talent is not an overnight process. It takes time and involves tedious legal processes. Even if company is
              small, you should review applications, interview candidates, clarify their duties, define salaries, and more. All
              those tasks can take away precious time you can dedicate to your regular business obligations. However, you
              don’t have to handle the recruiting process all the time. As a staffing partner, we can look for talent that fit your
              company’s open roles. If the right person is not in our talent pool, we create the job description and vet
              candidates to find the most qualified individual for your open position.</p>

          </div>

        </div>

        <div className="staffing-hiring">
          <div className="staffing-hiring-text">
            <div className="staffing-hiring-title">
              <h1>&nbsp; <span> </span></h1>
            </div>
            <p> We have your back! At <b style={{ color: '#015fc9' }}>AHS Assured Services</b>, we have been
              helping our clients find the best candidates to fill their open positions for years, and we can do the same for you
              and your company!</p>
          </div>
          <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632936/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/hiring_opcjcb.webp' alt="hiring" className='img-fluid' />
        </div>

        <div className='staffing-why'>
          <div className="staffing-why-content">
            <h2 style={{ color: '#fff' }}>Why Us</h2>
          </div>

          <div className='staffing-why-stick'>
          </div>
          <div className="staffing-why-text">

            <p><b>Highly trained professionals:</b><br /><i>We supply quality talent and help you find
              the ideal candidate for the position you need to fill.</i> </p>
            <p><b> Strong networking skills:</b><br /><i> We have established a working
              relationship with professional individuals, bodies, institutions and universities to find top talent for our
              clients.</i> </p>
            <p><b>Industry knowledge:</b><br /><i> We know your industry inside and out. Therefore, we can search for candidates with
              specific skills for your company’s niche.</i></p>
            <p><b>Vast recruiting experience:</b><br /><i> We can connect companies with job seekers
              within agreed timeline. Hence, we can execute an effective methodological process to find the best candidate
              among prospective employees. </i> </p>
            <p><b>Paperwork handling:</b><br /><i>We take care of the tedious post-recruiting tasks, including
              all the paperwork associated with new hires, contracts, and taxes.</i></p>
          </div>

          <div className="staffing-why-img">
            <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632935/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/highly_vzpnwq.webp' alt="highly trained professionals" className='img-fluid' />
            <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632937/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/network_rkljsx.webp' alt="strong networking skills" className='img-fluid' />
            <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632936/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/industry_uwbhid.webp' alt="industry knowledge" className='img-fluid' />
            <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632938/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/vast_mieai9.webp' alt="vast recruiting experience" className='img-fluid' />
            <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632937/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/paperwork_def9dg.webp' alt="paperwork handling" className='img-fluid' />
          </div>

        </div>

        <div id='staffing-services' className="staffing-body-services">
          <Title title="we provide " />
          <div className="staffing-body-services-text">
            <Card>
              <Card.Body>
                <Card.Title>Temporary staffing services</Card.Title>
                <Card.Text>
                  We provide temporary employees for short-term assignments or seasonal work. Temporary employees may work for multiple clients over a period of time basis client agreement only.
                </Card.Text>

              </Card.Body>
            </Card>

            <Card>
              <Card.Body>
                <Card.Title>Permanent staffing services</Card.Title>
                <Card.Text>
                  We help companies find permanent employees for full-time positions. We will work with companies to identify the skills and qualifications necessary for the role and will recruit candidates to fill the position.
                </Card.Text>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body>

                <Card.Title>Contract staffing services</Card.Title>
                <Card.Text>
                  We provide staffing for long-term projects or assignments. We will typically provide a team of contractors with specific skills or expertise to work on a project for a set period of time.
                </Card.Text>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body>

                <Card.Title>Executive Search Services</Card.Title>
                <Card.Text>
                  These services specialize in recruiting senior-level executives or other high-level professionals for permanent positions.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className="staffing-hiring">

          <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632937/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/what_z9zoqh.webp' alt="hiring" className='img-fluid' />

          <div className="staffing-hiring-text">
            <Title title="Expected Outcome" />
            <p>If you decide to partner with us, this is what we can do for you: </p>
            <ul>
              <li>Filter candidates and prospective employees according to your requirements</li>
              <li>Select only the top talent for your organization to help you build a talented and skilled staff </li>
              <li>Interview candidates to verify if they really meet the job profile</li>
              <li>Check candidate's previous employment experience and legal ability to work</li>
              <li>Test prospective employee's skills and assess their personalities</li>
              <li>Handle all the paperwork and payment processes</li>
            </ul>
          </div>

        </div>

        <div className='staffing-table'>
          <p>All recruitment processes occur in milestones, hence we have created a task list with expected timelines to help you understand
            each phase and the time it takes.</p>


          <div className='staffing-table-content'>
            <div className='staffing-table-content-text'>
              <h6>Activities</h6>
              <h6>Expected Timelines</h6>
            </div>
            <div className='staffing-table-content-text'>
              <h6>Discussing Job Details</h6>
             {/* <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632936/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/icons8-arrow-48_xxwluf.webp' alt="arrow" /> */}
              <h6>0-1 day</h6>
            </div>
            <div className='staffing-table-content-text'>
              <h6>Company requirements analysis</h6>
             {/* <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632936/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/icons8-arrow-48_xxwluf.webp' alt="arrow" /> */}
              <h6>0-1 day</h6>
            </div>
            <div className='staffing-table-content-text'>
              <h6>Job description creation</h6>
             {/* <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632936/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/icons8-arrow-48_xxwluf.webp' alt="arrow" /> */}
              <h6>0-1 day</h6>
            </div>
            <div className='staffing-table-content-text'>
              <h6>First round of candidate selection</h6>
             {/* <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632936/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/icons8-arrow-48_xxwluf.webp' alt="arrow" /> */}
              <h6>1-2 days</h6>
            </div>
            <div className='staffing-table-content-text'>
              <h6>Candidate's profiles and resumes analysis</h6>
             {/* <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632936/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/icons8-arrow-48_xxwluf.webp' alt="arrow" /> */}
              <h6>1-2 days</h6>
            </div>
            <div className='staffing-table-content-text'>
              <h6>First round of candidate selection</h6>
             {/* <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632936/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/icons8-arrow-48_xxwluf.webp' alt="arrow" /> */}
              <h6>1-2 days</h6>
            </div>
            <div className='staffing-table-content-text'>
              <h6>Second round of candidate selection</h6>
             {/* <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632936/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/icons8-arrow-48_xxwluf.webp' alt="arrow" /> */}
              <h6>2-3 days</h6>
            </div>
            <div className='staffing-table-content-text'>
              <h6>Candidate interviews</h6>
             {/* <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632936/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/icons8-arrow-48_xxwluf.webp' alt="arrow" /> */}
              <h6>3-5 days</h6>
            </div>
            <div className='staffing-table-content-text'>
              <h6>Skill and knowledge tests, if necessary</h6>
             {/* <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632936/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/icons8-arrow-48_xxwluf.webp' alt="arrow" /> */}
              <h6>3-5 days</h6>
            </div>
            <div className='staffing-table-content-text'>
              <h6>Final choice</h6>
             {/* <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632936/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/icons8-arrow-48_xxwluf.webp' alt="arrow" /> */}
              <h6>1 week</h6>
            </div>
            <div className='staffing-table-content-text'>
              <h6>Recruitment and paperwork</h6>
             {/* <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632936/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/icons8-arrow-48_xxwluf.webp' alt="arrow" /> */}
              <h6>1-2 weeks</h6>
            </div>
          </div>

          <p> Remember that you can contact us at any time to obtain information about a
            service not listed here</p>
        </div>

        <div className='project'>
          <div className="project-img">
            <img rel="preload" loading="lazy" className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632937/AHS%20Assured%20Services%20Pvt%20Ltd/Services/staffing/project_zz3anh.webp' alt="project" />
          </div>

          <div className='project-text'>
            <h2>looking for Staffing Services?</h2>
            <Link data-bs-toggle="modal" data-bs-target="#getintouchform" to={'#'}>get started</Link>
          </div>
          <InquiryForm />
        </div>


      </div>

      <Footer />
    </div>
  )
}

export default StaffingServices

import React from 'react'
import Chatbot from 'react-chatbot-kit'
import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser';
import config from './config';
import 'react-chatbot-kit/build/main.css';
import './Bot.css'
import { GrFormClose } from "react-icons/gr"
// import { useState } from 'react';

const Bot = () => {

  const showbot = () => {
    document.getElementById("bot-msg").style.display = "block";
    document.getElementById("bot-btn").style.display = "none";
  }

  const hidebot = () => {
    document.getElementById("bot-msg").style.display = "none";
    document.getElementById("bot-btn").style.display = "block";
  }


  return (
    <>
      {/* <SiGooglechat className='bot-btn' size={120}/> */}
      <div className='bot-btn' id='bot-btn' onClick={() => showbot()} >
        {/* <img className='bot-shape' src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1704520306/AHS%20Assured%20Services/Republic%20Day/bot-shape_tos5jh.png" alt="" /> */}
        <img className="chatbot" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1697545751/AHS%20Assured%20Services/Icons/digi_guru_bot_icon_jeekqq.png' alt="chatbot" />
      </div>

      <div className='bot' id='bot-msg'>
        <GrFormClose size={25} className='bot-close-btn' onClick={() => hidebot()} />
        <Chatbot config={config} actionProvider={ActionProvider} messageParser={MessageParser} headerText='Digi Guru'

        />
      </div>
    </>
  )
}

export default Bot

class MessageParser {
    constructor(actionProvider) {
        this.actionProvider = actionProvider;
    }

    parse(message) {
        const lowerCaseMessage = message.toLowerCase();

        if (lowerCaseMessage.includes('hello')
            || lowerCaseMessage.includes('hi')
            || lowerCaseMessage.includes('good')
            || lowerCaseMessage.includes('morning')
            || lowerCaseMessage.includes('night')
            || lowerCaseMessage.includes('noon')
            || lowerCaseMessage.includes('help')
            || lowerCaseMessage.includes('fine')
            || lowerCaseMessage.includes('thanks')
            || lowerCaseMessage.includes('thank')
            || lowerCaseMessage.includes('thankyou')) {
            this.actionProvider.greet();
        } else if (lowerCaseMessage.includes('bulk')
            || lowerCaseMessage.includes('mass')
            || lowerCaseMessage.includes('campaigns')
            || lowerCaseMessage.includes('wide-reaching')) {
            this.actionProvider.handleBulk();
        } else if (lowerCaseMessage.includes('smo')
            || lowerCaseMessage.includes('social')
            || lowerCaseMessage.includes('media')
            || lowerCaseMessage.includes('networks')
            || lowerCaseMessage.includes('management')
            || lowerCaseMessage.includes('engagement')
            || lowerCaseMessage.includes('strategy')
            || lowerCaseMessage.includes('branding')
            || lowerCaseMessage.includes('advertising')
            || lowerCaseMessage.includes('followers')
            || lowerCaseMessage.includes('trends')
            || lowerCaseMessage.includes('shares')
            || lowerCaseMessage.includes('posts')) {
            this.actionProvider.handleSmo();
        } else if (lowerCaseMessage.includes('seo')
            || lowerCaseMessage.includes('search')
            || lowerCaseMessage.includes('engine')
            || lowerCaseMessage.includes('google ranking')
            || lowerCaseMessage.includes('on page')
            || lowerCaseMessage.includes('off page')
            || lowerCaseMessage.includes('audit')
            || lowerCaseMessage.includes('strategy')
            || lowerCaseMessage.includes('backlinks')
            || lowerCaseMessage.includes('organic traffic')
            || lowerCaseMessage.includes('meta tags')) {
            this.actionProvider.handleSeo();
        } else if (lowerCaseMessage.includes('web')
            || lowerCaseMessage.includes('responsive')
            || lowerCaseMessage.includes('front-end')
            || lowerCaseMessage.includes('back-end')
            || lowerCaseMessage.includes('UX')
            || lowerCaseMessage.includes('website')
            || lowerCaseMessage.includes('Html')
            || lowerCaseMessage.includes('css')
            || lowerCaseMessage.includes('react')
            || lowerCaseMessage.includes('javascript')
            || lowerCaseMessage.includes('security')
            || lowerCaseMessage.includes('react')
            || lowerCaseMessage.includes('php')
            || lowerCaseMessage.includes('node')
            || lowerCaseMessage.includes('database')
            || lowerCaseMessage.includes('firebase')
            || lowerCaseMessage.includes('cross-browser')
            || lowerCaseMessage.includes('browser')
            || lowerCaseMessage.includes('redesign')
            || lowerCaseMessage.includes('e-commerce')
            || lowerCaseMessage.includes('cms')) {
            this.actionProvider.handleWeb();
        } else if (lowerCaseMessage.includes('content')) {
            this.actionProvider.handleContent();
        } else if (lowerCaseMessage.includes('ppc')) {
            this.actionProvider.handlePpc();
        } else if (lowerCaseMessage.includes('video')) {
            this.actionProvider.handleVideo();
        } else if (lowerCaseMessage.includes('affiliate')) {
            this.actionProvider.handleAffiliate();
        } else if (lowerCaseMessage.includes('influencer')) {
            this.actionProvider.handleInfluencer();
        } else if (lowerCaseMessage.includes('contact')
            || lowerCaseMessage.includes('whatsapp')
            || lowerCaseMessage.includes('phone')
            || lowerCaseMessage.includes('number')
            || lowerCaseMessage.includes('email')
            || lowerCaseMessage.includes('more')
            || lowerCaseMessage.includes('information')
            || lowerCaseMessage.includes('timeline')
            || lowerCaseMessage.includes('payment')) {
            this.actionProvider.handleContact();
        }else if (lowerCaseMessage.includes('address')) {
            this.actionProvider.handleAddress();
        }
        else {
            this.actionProvider.sorry();
        }
    }
}

export default MessageParser;

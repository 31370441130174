import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import "./Cookies.scss"


const Cookie = () => {

    const [display, setDisplay] = useState("")
    const click = () => {
        setDisplay("none");
    }
    return (
        <div className='cookies' style={{ display: display }}>
            <p>This site uses cookies. By continuing to use this website, you agree to their use. See <Link to={""}> privacy policy</Link> for more info.</p>
            <button onClick={click}>I Agree</button>
        </div>
    )
}

export default Cookie

import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom"
import '../Sass/landingpage.scss'
import { AiFillInstagram, AiFillLinkedin, AiFillGoogleCircle, AiFillFacebook } from 'react-icons/ai';
import { Helmet } from "react-helmet";
import fb from "../Firebase/firebase";
const DB = fb.firestore()
const LPGITF = DB.collection('landing_page_get_in_touch_form');

const LandingPage = () => {
    const navigate = useNavigate();

    const [userData, setUserData] = useState({
        Name: "",
        Email: "",
        Mobile: "",
        Subject: "",
        Message: "",
        Service: ""

    });
    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }


    //connect with firebase
    const SubmitData = async (e) => {
        e.preventDefault();
        const { Name, Email, Mobile, Subject, Message, Service } = userData;
        if (Name && Email && Mobile && Subject && Message && Service) {
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(userData.Email)) {
                alert('Please enter a valid email address');
                return
            }

            if (typeof Mobile !== "undefined") {

                var pattern = new RegExp(/^[0-9\b]+$/);

                if (!pattern.test(userData.Mobile)) {
                    alert("Please enter only number.");
                    return

                } else if (userData.Mobile.length !== 10) {
                    alert("Please enter 10 digit phone number.");
                    return
                }
            }

            try {
                LPGITF.add({
                    Name: Name,
                    Email: Email,
                    Mobile: Mobile,
                    Message: Message,
                    Service: Service,
                    Subject: Subject
                }).then((docRef) => {
                    navigate('/thanks')
                }).catch((error) => {
                    console.error("error:", error);
                });

                setUserData({
                    Name: "",
                    Email: "",
                    Mobile: "",
                    Message: "",
                    Service: "",
                    Subject: ""
                })

                // console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
                alert("Error adding document")
            }
        }
        else {
            alert('Please fill the data');
        }
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Get a Quote | AHS Assured Services</title>
                <link rel="canonical" href="https://www.ahsassuredservices.com/get-a-quote" />
                <meta name="description" content="Discover tailored solutions for your specific requirements on SEO, SMO, Website development and lead generation. Get a personalized quote today and embark on a journey of innovation with our expert team. Your goals, our expertise – let's bring your vision to life" />
            </Helmet>

            <div className="landing">
                {/* navbar */}
                <div className="landing-navbar">
                    <Link to={"/get-a-quote"} className="landing-navbar-img">
                        <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1690632920/AHS%20Assured%20Services%20Pvt%20Ltd/Logo/ahs_logo_cvgolt.png" alt="logo" />
                    </Link>
                    <div className="landing-links">
                        <Link to={'/get-a-quote'}>Home</Link>
                        <a href='#about'>About</a>
                        <a href='#service'>Services</a>
                        <a href="#offer">Offers</a>
                        <a href='#testimonial'>Testimonial</a>
                        <a href='https://www.ahsassuredservices.com'>Visit Our Website</a>
                    </div>

                </div>
                {/* navbar ends */}

                {/* banner ends */}
                <div id='form' className="landing-bg">
                    <div className="landing-bg-content">
                        <h1>Digital Marketing Services</h1>
                        <p>Transforming Digital Media
                        </p>
                    </div>

                    <div className='form-right'>

                        <form id="get_in_touch" method="POST" >
                            <h4>Get in Touch</h4>
                            <input
                                type="text"
                                name="Name"
                                id="name"
                                placeholder='Your Name'
                                value={userData.Name}
                                onChange={postUserData}
                                required /><br />

                            <input
                                type="text"
                                name="Mobile"
                                id="phone"
                                placeholder='Phone Number'
                                value={userData.Mobile}
                                onChange={postUserData}
                                required /><br />

                            <input
                                type="text"
                                name="Subject"
                                id="subject"
                                placeholder='Subject'
                                value={userData.Subject}
                                onChange={postUserData}
                                required /><br />
                            <input
                                type="email"
                                name="Email"
                                id="email"
                                placeholder='Email'
                                value={userData.Email}
                                onChange={postUserData}
                                required /><br />


                            <select aria-label="Default select example"
                                value={userData.Service}
                                onChange={postUserData}
                                className="form-select"
                                name='Service'>
                                <option value="default" defaultValue='What Service are you looking for?' >What Service are you looking for?</option>
                                <optgroup label="Digital Marketing Services">
                                    <option value="Social Media Optimization (SMO)">Social Media Optimization (SMO)</option>
                                    <option value="website design & development">website design & development</option>
                                    <option value="Search Engine Optimization (SEO)">Search Engine Optimization (SEO)</option>
                                    <option value="pay per click (PPC) advertising">pay per click (PPC) advertising</option>
                                    <option value="Content Marketing">Content Marketing</option>
                                    <option value="Bulk Marketing">Bulk Marketing</option>
                                    <option value="Video Marketing">Video Marketing</option>
                                    <option value="Affiliate marketing">Affiliate marketing</option>
                                    <option value="Influencer Marketing">Influencer Marketing</option>
                                </optgroup>
                                <optgroup label="Staffing Services">
                                    <option value="Temporary staffing services">Temporary staffing services</option>
                                    <option value="Permanent staffing services">Permanent staffing services</option>
                                    <option value="Contract staffing services">Contract staffing services</option>
                                    <option value="Executive Search Services">Executive Search Services</option>
                                </optgroup>
                            </select>



                            <textarea
                                id="message"
                                cols="30"
                                rows="4"
                                placeholder='Your Message'
                                name="Message"
                                value={userData.Message}
                                onChange={postUserData}
                                required></textarea>

                            <button
                                type="submit"
                                value="Send"
                                onClick={SubmitData}
                            >
                                Get A Quote
                            </button>

                        </form>


                    </div>
                </div>
                {/* banner ends */}
                {/* About  */}
                <div id='about' className="landingabout section-services">
                    <div className="landingabout-content">
                        <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1690976894/AHS%20Assured%20Services%20Pvt%20Ltd/About/three-businesspeople-looking-business-report-office_loxmvp.jpg" alt="" />
                        <div>
                            <div class="row justify-content-center text-center">
                                <div class="col-md-10 col-lg-8">
                                    <div class="header-section">
                                        <h2 class="lp-title">About Us</h2>
                                    </div>
                                </div>
                            </div>
                            <p>With 15+ years of business experience at leadership level, with a moral approach, guaranteeing high service levels and adherence to quality benchmarks. We'll assist you think about your business in an entire new way, bringing a new viewpoint to long-standing challenges and making a difference you imagine – and accomplish – a future with boundless conceivable outcomes.
                            </p>
                        </div>
                    </div>
                    <div className="landingabout-content">
                        <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1690976894/AHS%20Assured%20Services%20Pvt%20Ltd/About/group-people-are-sitting-around-table-with-large-window-that-says-nyc-it_sttyij.jpg" alt="" />
                        <div>
                            <div class="row justify-content-center text-center">
                                <div class="col-md-10 col-lg-8">
                                    <div class="header-section">
                                        <h2 class="lp-title">Our Vision</h2>
                                    </div>
                                </div>
                            </div>
                            <p>Our vision is to create a world where every aspect of business is seamlessly coordinated, connected, and committed to delivering value to all stakeholders. We strive to build a culture that empowers our employees to collaborate effectively, leverage the latest technology, and embrace a spirit of innovation to drive sustainable growth and create positive change in our communities.
                            </p>
                        </div>
                    </div>
                    <div className="landingabout-content">
                        <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1690976894/AHS%20Assured%20Services%20Pvt%20Ltd/About/close-up-ambitious-business-team-standing-circle-concept-teamwork_eudail.jpg" alt="" />
                        <div>
                            <div class="row justify-content-center text-center">
                                <div class="col-md-10 col-lg-8">
                                    <div class="header-section">
                                        <h2 class="lp-title">Our Mission</h2>
                                    </div>
                                </div>
                            </div>
                            <p>Our mission is to build a coordinated, connected, and committed organization that delivers exceptional products and services to our customers while creating sustainable value for all stakeholders. We strive to leverage the latest technology, foster a culture of collaboration and innovation, and maintain a strong sense of commitment to excellence in everything we do.
                            </p>
                        </div>
                    </div>
                </div>
                {/* About Ends */}

                {/* Services */}
                <div id='service' className="lp-services">

                    <section class="section-services">
                        <div class="container">
                            <div class="row justify-content-center text-center">
                                <div class="col-md-10 col-lg-8">
                                    <div class="header-section">
                                        <h2 class="lp-title">Our Services</h2>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-lg-6">
                                    <div class="single-service">
                                        <div class="content">
                                            <span class="icon">
                                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1690961173/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/attracting-social-media-icon-with-magnet-smartphone_b7tbwg.png" alt="" />
                                            </span>
                                            <h3 class="service-title">Social Media Optimization</h3>
                                            <p class="description">SMO is a process of optimizing a brand's presence and engagement on social media platforms. The primary objective of SMO is to increase brand visibility, attract more followers, and drive traffic to the website through effective social media strategies.</p>

                                        </div>
                                        <span class="circle-before"></span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="single-service">
                                        <div class="content">
                                            <span class="icon">
                                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1690962822/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/seo_tngt8u.png" alt="" />
                                            </span>
                                            <h3 class="service-title">Search Engine optimization</h3>
                                            <p class="description">SEO is the practice of optimizing a website to improve its visibility and ranking in search engine results pages (SERPs). The goal of SEO is to attract more organic (non-paid) traffic to a website by making it more relevant and authoritative to search engines.</p>

                                        </div>
                                        <span class="circle-before"></span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="single-service">
                                        <div class="content">
                                            <span class="icon">
                                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1690964257/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/web__1_-removebg-preview_wvrhkg.png" alt="" />
                                            </span>
                                            <h3 class="service-title">Website Design & Development</h3>
                                            <p class="description">Web design and development often go hand in hand, requiring close collaboration between designers and developers. The web design provides the visual concept and user interface, which developers bring to life with code and functionality. The result is a fully functional and aesthetically pleasing website that delivers a seamless user experience.</p>

                                        </div>
                                        <span class="circle-before"></span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="single-service">
                                        <div class="content">
                                            <span class="icon">
                                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1690963418/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/ppc_baeocy.png" alt="" />
                                            </span>
                                            <h3 class="service-title">Pay Per Click Advertising</h3>
                                            <p class="description">PPC, or Pay-Per-Click, is a digital advertising model where advertisers pay a fee each time their ad is clicked by a user. It is an effective way to drive targeted traffic to a website, as ads are displayed on search engines, social media platforms, websites, and other digital channels. The most popular PPC platforms include Google Ads and Facebook Ads.</p>

                                        </div>
                                        <span class="circle-before"></span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
                {/* Services ends */}

                {/* offer */}
                <section id='offer' class="pricing-section section-services">
                    <div class="container">
                        <div class="row justify-content-center text-center">
                            <div class="col-md-10 col-lg-8">
                                <div class="header-section">
                                    <h2 class="lp-title">WE OFFER</h2>
                                </div>
                            </div>
                        </div>

                        <div class="outer-box">
                            <div class="row">
                                <div class="pricing-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
                                    <div class="inner-box">
                                        <div class="icon-box">
                                            <div class="icon-outer"><img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691047686/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/app-website-background_1_dm3x7x.jpg" alt="" /></div>
                                        </div>
                                        <div class="price-box">
                                            <div class="title">Website Design & Development</div>
                                            <h4 class="price">Free</h4>
                                        </div>
                                        <ul class="features">
                                            <li>
                                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691051136/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/check-mark_5290058_1_ch7yzt.png" alt="checkmark" />
                                                &emsp;
                                                Analysis & Audit
                                            </li>
                                            <li>
                                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691050785/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/cross_10263796_vky4go.png" alt="cross" />
                                                &emsp;
                                                Conversion Rate Optimization (CRO)
                                            </li>
                                            <li>
                                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691050785/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/cross_10263796_vky4go.png" alt="cross" />
                                                &emsp;
                                                Maintenance
                                            </li>
                                            <li>
                                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691050785/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/cross_10263796_vky4go.png" alt="cross" />
                                                &emsp;
                                                Resposnive Design
                                            </li>
                                        </ul>
                                        <div class="btn-box">
                                            <a href="#form" class="theme-btn">BUY plan</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="pricing-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
                                    <div class="inner-box">
                                        <div class="icon-box">
                                            <div class="icon-outer"><img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691049595/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/empty-background-surrounded-with-social-media-icon-product-design-3d-render-illustration_znzjns.jpg" alt="" /></div>
                                        </div>
                                        <div class="price-box">
                                            <div class="title">Social Media Optimization (SMO)</div>
                                            <h4 class="price">Free</h4>
                                        </div>
                                        <ul class="features">
                                            <li>
                                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691051136/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/check-mark_5290058_1_ch7yzt.png" alt="checkmark" />
                                                &emsp;
                                                5 Social media posts
                                            </li>
                                            <li>
                                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691050785/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/cross_10263796_vky4go.png" alt="cross" />
                                                &emsp;
                                                Account handling
                                            </li>
                                            <li>
                                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691050785/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/cross_10263796_vky4go.png" alt="cross" />
                                                &emsp;
                                                Regular insights & Engagement
                                            </li>
                                            <li>
                                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691050785/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/cross_10263796_vky4go.png" alt="cross" />
                                                &emsp;
                                                Post Scheduling
                                            </li>
                                        </ul>
                                        <div class="btn-box">
                                            <a href="#form" class="theme-btn">BUY plan</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="pricing-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="400ms">
                                    <div class="inner-box">
                                        <div class="icon-box">
                                            <div class="icon-outer"><img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691049925/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/modern-business-finance-illustration-isolated-white-background-created-with-generative-ai_uihsmo.jpg" alt="" /></div>
                                        </div>
                                        <div class="price-box">
                                            <div class="title">Search Engine Optimization (SEO)</div>
                                            <h4 class="price">Free</h4>
                                        </div>
                                        <ul class="features">
                                            <li>
                                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691051136/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/check-mark_5290058_1_ch7yzt.png" alt="checkmark" />
                                                &emsp;
                                                Content Writing
                                            </li>
                                            <li>  <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691051136/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/check-mark_5290058_1_ch7yzt.png" alt="checkmark" />
                                                &emsp;
                                                3 Blog
                                            </li>
                                            <li>
                                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691050785/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/cross_10263796_vky4go.png" alt="cross" />
                                                &emsp;
                                                On Page Activities & Off Page Activites
                                            </li>
                                            <li> <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691050785/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/cross_10263796_vky4go.png" alt="cross" />
                                                &emsp;
                                                End to end analysis
                                            </li>
                                        </ul>
                                        <div class="btn-box">
                                            <a href="#form" class="theme-btn">BUY plan</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="pricing-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="400ms">
                                    <div class="inner-box">
                                        <div class="icon-box">
                                            <div class="icon-outer"><img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691071016/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/3d-render-cash-back-concept-wallet-icon-floating-with-coin_pdymsr.jpg" alt="" /></div>
                                        </div>
                                        <div class="price-box">
                                            <div class="title">Pay Per Click Advertising (PPC)</div>
                                            <h4 class="price">Free</h4>
                                        </div>
                                        <ul class="features">
                                            <li>
                                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691051136/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/check-mark_5290058_1_ch7yzt.png" alt="checkmark" />
                                                &emsp;
                                                Consultation
                                            </li>
                                            <li>  <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691050785/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/cross_10263796_vky4go.png" alt="checkmark" />
                                                &emsp;
                                                Laser-Targeted Audience
                                            </li>
                                            <li>
                                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691050785/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/cross_10263796_vky4go.png" alt="cross" />
                                                &emsp;
                                                Real-Time Analytics
                                            </li>
                                            <li> <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691050785/AHS%20Assured%20Services%20Pvt%20Ltd/Landing%20Page/cross_10263796_vky4go.png" alt="cross" />
                                                &emsp;
                                                Engaging Ad Creatives
                                            </li>
                                        </ul>
                                        <div class="btn-box">
                                            <a href="#form" class="theme-btn">BUY plan</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* offer ends */}

                {/* testimonial */}
                <div id='testimonial' className="testimonial section-services">
                    <div class="container">
                        <div class="row justify-content-center text-center">
                            <div class="col-md-10 col-lg-8">
                                <div class="header-section">
                                    <h2 class="lp-title">Testimonials</h2>

                                </div>
                            </div>
                        </div>
                    </div>
                    <figure class="snip1192">
                        <blockquote>I wasn't sure about trying AHS Assured Services Pvt. Ltd., but it ended up being a big help for my business! The team really understood what I needed and did an amazing job. Our sales have gone up a lot, and we're reaching more people now.
                            <div class="author">

                                <h5>David Beckham
                                    {/* <span> LittleSnippets</span> */}
                                </h5>
                            </div> </blockquote>

                    </figure>
                    <figure class="snip1192 hover">
                        <blockquote>Wow! This service made a huge difference in a short time. The team was very professional and knew what they were doing. Our website is getting a lot more visitors, and we're getting more people interested in our business. Thank you for all your help!

                            <div class="author">
                                <h5>Manoj Kumar
                                    {/* <span> LittleSnippets</span> */}
                                </h5>
                            </div>
                        </blockquote>

                    </figure>
                    <figure class="snip1192">
                        <blockquote>I've been struggling to find the right service, and I'm so happy I found this company. They gave me excellent support and customized solutions beyond my expectations. They really care about making their customers happy, and I'm grateful for their expertise!
                            <div class="author">

                                <h5>Ravi Mishra
                                    {/* <span> LittleSnippets</span> */}
                                </h5>
                            </div>
                        </blockquote>

                    </figure>
                </div>
                {/* testimonial ends */}

            </div>

            {/* footer */}
            <div class="footer-l">
                <section class="ft-social">
                    <ul class="ft-social-list">
                        <li><a href="https://www.facebook.com/ahsassuredservices?mibextid=ZbWKwL"><AiFillFacebook /></a></li>
                        <li><a href="https://www.instagram.com/ahsgroupindia/?igshid=MzNlNGNkZWQ4Mg%3D%3D"><AiFillInstagram /></a></li>
                        <li><a href="https://www.google.com/search?sxsrf=AJOqlzVS2RUI5zROVflYgjgDk13ZdGZcKw:1679504304299&q=AHS+Assured+Services+Private+Limited&ludocid=6743101624921404522&gsas=1&client=ms-unknown&lsig=AB86z5XSgX3GiU25EqGDMskAGphg&kgs=dc9825159cb9ea2c&shndl=-1&source=sh/x/kp/local/2"><AiFillGoogleCircle /></a></li>
                        <li><a href="https://www.linkedin.com/company/ahs-assured-services-private-limited/"><AiFillLinkedin /></a></li>
                    </ul>
                </section>

                <section class="ft-legal">
                    <ul class="ft-legal-list">
                        <li><Link to={"/privacy_policy"}>Privacy Policy</Link></li>
                        <li>&copy; 2023 Copyright <a href="https://ahsassuredservices.com/"> AHS</a></li>
                    </ul>
                </section>
            </div>
            {/* footer ends */}
        </div >

    )
}

export default LandingPage

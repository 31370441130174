import React from 'react'
import Header from '../../../Utilities/Header'
import Footer from '../../../Utilities/Footer'
import { useEffect } from 'react'
import UpArrow from '../../../Utilities/UpArrow'
import Bot from '../../../Functionalities/Bot'
import { Link } from 'react-router-dom'
import InquiryForm from '../../../Utilities/InquiryForm'
import { Helmet } from "react-helmet";

const WebDevelopment = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Web Designing & Development Company | AHS Assured Services</title>
        <link rel="canonical" href="https://www.ahsassuredservices.com/web_design_and_development" />
        <meta name="description" content="Get cutting-edge web design & development services from a top web development company in delhi NCR. Responsive, user-friendly, and SEO-optimized websites for your business. Contact us now!" />
      </Helmet>
      <Bot />
      <UpArrow />
      <Header />

      <div id='web'>

        <div className='web-banner'>
          <img rel="preload" loading="lazy" className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632924/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/bg_indd9z.webp' alt="backgroundpic" />
          <div className="typewriter">
            <h1>website design and development</h1>
            <p className="subtitle fancy"><span>Transforming Ideas into  Seamless<br /> Online Experiences</span></p>
          </div>
          <div className='bg2'>
            <img rel="preload" loading="lazy" className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632932/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Web_Development/bg2_qurg3t.webp' alt="bg2" />
          </div>
        </div>

        {/* <div className='web-body-title' style={{ marginTop: "6em" }}>
          <h2 style={{ marginBottom: '5%' }}>Our Projects</h2>
        </div> */}

        <div id='web'>
          <div className='web-body-title'>
            <h2>Categories</h2>
            <p>There are numerous types of websites, each designed to serve specific purposes and cater to different needs. Here are some common types of websites:</p>
          </div>

          <div className="types-website">
            <div className='types-website-content'>

              <div className="container">
                <img rel="preload" loading="lazy" className="img-fluid image" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632932/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Web_Development/types/e-commerce_lvbg4e.webp' alt="e-commerce websites" />
                <div className="overlay">
                  <div className='text'>e-commerce websites</div>
                </div>
              </div>

              <div className="container">
                <img rel="preload" loading="lazy" className="img-fluid image" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632932/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Web_Development/types/blog_sm5gd1.webp' alt="Blogging Websites" />
                <div className="overlay">
                  <div className='text'>Blogging Websites</div>
                </div>
              </div>

              <div className="container">
                <img rel="preload" loading="lazy" className="img-fluid image" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632933/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Web_Development/types/portfolio_qz7fgm.webp' alt="Portfolio Websites" />
                <div className="overlay">
                  <div className='text'>Portfolio Websites</div>
                </div>
              </div>

              <div className="container">
                <img rel="preload" loading="lazy" className="img-fluid image" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632932/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Web_Development/types/corporate_souccy.webp' alt="Corporate Websites" />
                <div className="overlay">
                  <div className='text'>Corporate Websites</div>
                </div>
              </div>

              <div className="container">
                <img rel="preload" loading="lazy" className="img-fluid image" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632932/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Web_Development/types/educational_hgcls2.webp' alt="Educational Websites" />
                <div className="overlay">
                  <div className='text'>Educational Websites</div>
                </div>
              </div>


              <div className="container">
                <img rel="preload" loading="lazy" className="img-fluid image" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632933/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Web_Development/types/personal_lb9p34.webp' alt="landing Pages" />
                <div className="overlay">
                  <div className='text'>landing Pages</div>
                </div>
              </div>


            </div>
          </div>
        </div>

        <div className="why-website">
          <div className='web-body-title'>
            <h2>Why Every Business Needs A Website</h2>
            <p>Websites serve various purposes and provide numerous benefits for individuals, businesses, organizations, and society as a whole. Here are some key reasons why we need websites:</p>
          </div>
          <div className="why-website-content">

            <div>
              <h4>Online Presence</h4>
              <p>Websites provide an online presence and act as a digital storefront for businesses, professionals, and individuals. They allow people to find and learn more about you or your organization, even if they are not physically present.</p>
            </div>

            <div>
              <h4>Information and Communication</h4>
              <p>Websites are an effective way to share information and communicate with a wide audience. Whether it's news, articles, product details, contact information, or updates, websites provide a centralized platform for disseminating information.</p>
            </div>

            <div>
              <h4>Accessibility and Convenience</h4>
              <p>Websites are accessible 24/7 from anywhere with an internet connection. They offer convenience to users, allowing them to access information, products, or services at their own pace and from the comfort of their homes or offices.</p>
            </div>

            <div>
              <h4>Community Building</h4>
              <p>Websites can foster communities and bring people together around shared interests, causes, or hobbies. Forums, blogs, and social features on websites encourage interaction, discussion, and collaboration among users.</p>
            </div>

            <div>
              <h4>Adaptability and Scalability</h4>
              <p>Websites offer flexibility and scalability, allowing businesses and organizations to adapt to changing needs, expand their offerings, and reach a larger audience without significant physical infrastructure investments.</p>
            </div>

            <div>
              <h4>Online Marketing and E-commerce</h4>
              <p>Websites are powerful marketing tools. They enable businesses to showcase their products or services, reach a global audience, and engage with potential customers. Websites can also facilitate e-commerce, allowing businesses to sell products or services online, expanding their customer base and revenue opportunities.</p>
            </div>

            <div>
              <h4>Credibility and Trust</h4>
              <p>A professionally designed and well-maintained website can enhance credibility and build trust with customers, clients, or stakeholders. It provides a platform to showcase expertise, achievements, testimonials, and other elements that establish credibility in a particular field.</p>
            </div>

            <div>
              <h4>Branding and Identity</h4>
              <p>Websites help establish and reinforce a brand's identity. Through design elements, logos, color schemes, and consistent messaging, websites contribute to brand recognition and differentiation in a crowded marketplace.</p>
            </div>



          </div>
        </div>

        <div className='web-body-title' style={{ marginTop: "6em" }}>
          <h2>Introducing Our Web Development Process:</h2>
          <p>In this digital age, your online presence has never been more crucial. To thrive in a fast-paced and competitive landscape, you need a website that captivates, engages, and converts. At our cutting-edge web development agency, we are passionate about crafting exceptional online experiences that leave a lasting impact.</p>
        </div>

        <div className="web-process">

          <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632934/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Web_Development/infographics_ycyuax.webp' alt="web-process" className='img-fluid' />

          <div className="web-process-img">

            <div className="container">
              <div className="pullUp planning">
                <p>The first step in a website is to determine the purpose of the website, its target audience, and the content that will be included.</p>
                <hr />
                <h4>planning</h4>

              </div>
            </div>

            <div className="container">
              <div className="pullUp design">
                <p>This involves creating a visual design for the design of the website including the layout color scheme typography and graphics.</p>


                <hr />
                <h4>design</h4>

              </div>

            </div>

            <div className="container">
              <div className="pullUp development">
                <p>This involves coding an d programming the website using HTML, CSS, Javascript and other web development technologies.</p>


                <hr />
                <h4>development</h4>


              </div>

            </div>

            <div className="container">
              <div className="pullUp testing">
                <p>Once the website has been developed it needs to be tested to ensure that it is functioning properly and that all features are working correctly.</p>

                <hr />
                <h4>testing</h4>


              </div>

            </div>

            <div className="container">
              <div className="pullUp launch">
                <p>Once the website has been designed, developed and tested, it is ready to be launched and made available to the public.</p>


                <hr />
                <h4>launch</h4>
              </div>

            </div>

            <div className="container">
              <div className="pullUp maintenance">
                <p>Finally after the website has been launched it needs to be regularly maintained to ensure that it's content remains up to date.</p>


                <hr />
                <h4>maintenance</h4>


              </div>

            </div>

          </div>

        </div>

        <div className='web-why' style={{marginTop: '-2%'}}>
          <div className='web-body-title'>
            <h2>Why choose AHS DiGi-GuRu for your website design needs?</h2>
            <p>In this digital age, your online presence has never been more crucial. To thrive in a fast-paced and competitive landscape, you need a website that captivates, engages, and converts. At our cutting-edge web development agency, we are passionate about crafting exceptional online experiences that leave a lasting impact.</p>
          </div>

          <div className='container-fluid mx-auto mt-5 mb-5 col-12' style={{ textAlign: "center" }}>

            <div className="row web-why-card" style={{ justifyContent: "center" }}>
              <div className="card">
                <div className="card-content">
                  <div className="card-body"> <img rel="preload" loading="lazy" className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632914/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/customized_designs_mbhsaw.webp' alt='customized' />
                    <div className="shadow"></div>
                    <div className="card-title">Customized Designs</div>
                    <div className="card-subtitle">
                      <p>We understand that each business is unique, and we tailor our designs to align perfectly with your brand identity and goals. Stand out from the crowd with a website that is exclusively yours!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <div className="card-body"> <img rel="preload" loading="lazy" className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632920/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/userfriendly_bgogpp.webp' alt='user-Friendly Experience' />
                    <div className="card-title">User-Friendly Experience</div>
                    <div className="card-subtitle">
                      <p>We prioritize creating intuitive and user-friendly interfaces, ensuring seamless navigation and maximum engagement for your website visitors. Leave a lasting impression with a memorable online experience!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <div className="card-body"> <img rel="preload" loading="lazy" className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632919/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/mobile_pwlb0u.webp' alt='Mobile-Optimized' />
                    <div className="card-title">Mobile-Optimized</div>
                    <div className="card-subtitle">
                      <p>With most internet users browsing on their smartphones, we optimize your website to be responsive across all devices. Reach your audience wherever they are, and drive conversions on every screen size!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <div className="card-body"> <img rel="preload" loading="lazy" className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632920/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/seo_crpwo4.webp' alt='SEO-Friendly' />
                    <div className="card-title">SEO-Friendly</div>
                    <div className="card-subtitle">
                      <p> Our team of experts employs the latest SEO techniques to ensure your website ranks higher in search engine results. Gain increased visibility and attract organic traffic that leads to meaningful business growth.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <div className="card-body"> <img rel="preload" loading="lazy" className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632920/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/timelydelivery_uv0iue.webp' alt='Timely Delivery' />
                    <div className="card-title">Timely Delivery</div>
                    <div className="card-subtitle">
                      <p>We understand the importance of time in the business world. Count on us to deliver your website on schedule, without compromising on quality. Your success is our priority!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className='project'>
          <div className="project-img">
            <img rel="preload" loading="lazy" className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632932/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Web_Development/project_pguqzr.webp' alt="project" />
          </div>
          <div className='project-text'>
            <h2>do you have a <br />project in your mind?</h2>
            <Link data-bs-toggle="modal" data-bs-target="#getintouchform" to={'#'}>get started</Link>
          </div>
          <InquiryForm />
        </div>

      </div >

      <Footer />
    </div >
  )
}

export default WebDevelopment


import React from 'react'
import Header from '../../../Utilities/Header'
import UpArrow from '../../../Utilities/UpArrow'
import Bot from '../../../Functionalities/Bot'
import Footer from '../../../Utilities/Footer'
import Title from '../../../Utilities/Title'
import { Link } from 'react-router-dom'
import InquiryForm from '../../../Utilities/InquiryForm'

const ContentMarketing = () => {
  return (
    <div>
      <Bot />
      <UpArrow />
      <Header />
      <div className="content_marketing">


        <div className='banner-hero'>

          <div className="banner-hero-text">
            <h1>content marketing</h1>
            <div className="content">
              <h2>Empower Your Audience with <br />Engaging Content</h2>
            </div>
            {/* <a href='#staffing-services'>Learn More</a> */}
          </div>

          <div className="banner-hero-img">
            <img rel="preload" loading="lazy"  src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632926/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Content%20Marketing/hero_qox4wi.webp' alt="smo-background" className='img-fluid' />
          </div>

        </div>

        <div className="smo-what">
          <div className="smo-what-text">
            <Title title="What is content marketing?" />
          </div>

          <div className="smo-what-content">

            <div className="smo-what-content-text">
              <p>Content marketing is a strategic marketing approach that involves creating and distributing valuable, relevant, and consistent content to attract and engage a specific target audience. The primary focus of content marketing is to provide valuable information, entertainment, or education to the audience rather than directly promoting a product or service.</p>

              <p>
                The goal of content marketing is to build brand awareness, establish authority and credibility, nurture customer relationships, and ultimately drive profitable customer action. By delivering content that meets the needs and interests of the audience, businesses aim to build trust and loyalty, positioning themselves as a valuable resource and solution provider within their industry or niche.
              </p>

              <p>Content marketing can take various forms, including blog posts, articles, videos, infographics, podcasts, social media posts, ebooks, webinars, and more. It is an ongoing and strategic process that requires understanding the target audience, creating high-quality content, and effectively distributing it through various channels to reach the intended audience.</p>
            </div>

            <div className="smo-what-content-img">
              <img rel="preload" loading="lazy"  src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632925/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Content%20Marketing/content_what_q6w5e0.webp' alt="what is smo?" />
            </div>

          </div>
        </div>

        <div className='content-services web-why'>
          <div className="smo-what-text">
            <Title title="Services" />
          </div>
          <div className='container-fluid mx-auto mt-5 mb-5 col-12' style={{ textAlign: "center" }}>

            <div className="row web-why-card" style={{ justifyContent: "center" }}>
              <div className="card">
                <div className="card-content">
                  <div className="card-body"> <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632925/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Content%20Marketing/blogs_wridgk.webp' alt='customized' />
                    <div className="shadow"></div>
                    <div className="card-title">Blogs and Articles</div>
                    <div className="card-subtitle">
                      <p>Written content published on a website, covering a wide range of topics related to the industry or niche.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <div className="card-body"> <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632926/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Content%20Marketing/video_ibyb4l.webp' alt='user-Friendly Experience' />
                    <div className="card-title">Videos</div>
                    <div className="card-subtitle">
                      <p>Engaging and informative videos that can be shared on platforms like YouTube, social media, or embedded on a website.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <div className="card-body"> <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632926/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Content%20Marketing/infographics_g0o1fz.webp' alt='Mobile-Optimized' />
                    <div className="card-title">Infographics</div>
                    <div className="card-subtitle">
                      <p>Visual representations of data or information that are easy to understand and share.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-content">
                  <div className="card-body"> <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632926/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Content%20Marketing/podcast_jmxekf.webp' alt='Mobile-Optimized' />
                    <div className="card-title">Podcasts</div>
                    <div className="card-subtitle">
                      <p>Audio content that allows for in-depth discussions or interviews on specific topics.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-content">
                  <div className="card-body"> <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632926/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Content%20Marketing/social_media_post_tyxdfr.webp' alt='Mobile-Optimized' />
                    <div className="card-title">Social Media Posts</div>
                    <div className="card-subtitle">
                      <p>Sharing valuable content, updates, and engaging with the audience on social media platforms.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-content">
                  <div className="card-body"> <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632925/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Content%20Marketing/ebooks_fonh19.webp' alt='Mobile-Optimized' />
                    <div className="card-title">Ebooks and Whitepapers</div>
                    <div className="card-subtitle">
                      <p>Longer-form content that offers in-depth knowledge or insights on a specific subject.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-content">
                  <div className="card-body"> <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632926/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Content%20Marketing/email_newsletters_esfuz4.webp' alt='Mobile-Optimized' />
                    <div className="card-title">Email Newsletters</div>
                    <div className="card-subtitle">
                      <p>Regularly sending curated or original content directly to subscribers' email addresses.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-content">
                  <div className="card-body"> <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632927/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Content%20Marketing/webinars_i4xf7v.webp' alt='Mobile-Optimized' />
                    <div className="card-title">Webinars</div>
                    <div className="card-subtitle">
                      <p>Online seminars or workshops that provide valuable information and allow interaction with the audience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div id='seo_key' className="smo-types seo">
          <Title title="Benefits of Content Marketing" />
          <div className="seo-key-content">

            <div className="seo-key-content-text">
              <h2>Building Brand Awareness</h2>
              <hr />
              <p>By consistently providing valuable content, businesses can increase their visibility and reach a wider audience.</p>
            </div>

            <div className="seo-key-content-text">
              <h2>Establishing Authority</h2>
              <hr />
              <p>Creating high-quality content can position a brand or individual as an industry expert or thought leader.</p>
            </div>

            <div className="seo-key-content-text">
              <h2>Lead Generation</h2>
              <hr />
              <p>Valuable content can attract potential customers and nurture them through the buying process.</p>
            </div>

            <div className="seo-key-content-text">
              <h2>Customer Engagement</h2>
              <hr />
              <p>Content that resonates with the audience can lead to increased engagement and interaction.</p>
            </div>

            <div className="seo-key-content-text">
              <h2>SEO and Organic Traffic</h2>
              <hr />
              <p>Content optimized for search engines can improve a website's ranking and drive organic traffic.</p>
            </div>

            <div className="seo-key-content-text">
              <h2>Cost-Effectiveness</h2>
              <hr />
              <p>Content marketing can be more cost-effective than traditional advertising methods and offers long-term value.</p>
            </div>

          </div>

        </div>

        <div className='project'>
          <div className="project-img">
            <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632927/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Content%20Marketing/project_rx7ogs.webp' alt="project" />
          </div>

          <div className='project-text'>
            <h2>looking for Content Marketing?</h2>
            <Link data-bs-toggle="modal" data-bs-target="#getintouchform" to={'#'}>get started</Link>
        </div>
        <InquiryForm />

        </div>

      </div>
      <Footer />
    </div>
  )
}

export default ContentMarketing

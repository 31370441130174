import React, { useEffect } from 'react'
import AdminDashboardNav from './AdminDashboardNav'
import fb from "../Firebase/firebase";
import { useNavigate } from 'react-router-dom';

const Admin = () => {
    let navigate = useNavigate();
    let id;

    useEffect(() => {

        fb.auth().onAuthStateChanged(function (user) {
            if (user) {
                id = user.uid;
                navigate('/admin');
                console.log("uid", id);
            } else {
                navigate('/admin-login');
                console.log("user is logged out")
            }
        });
    }, [])

    return (
        <div>
            <div className='dashboard'>
                <AdminDashboardNav />
                <div className='dashboard-app' style={{ padding: '0' }}>
                    <h5 style={{ fontSize: '8vw', position: 'relative', top: '65%', left: '0', textAlign: 'center', fontWeight: 'bold', color: '#16243d' }}>Welcome to the<br /> ADMIN Panel</h5>
                </div>
            </div>
        </div >
    )
}

export default Admin

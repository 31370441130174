import React from 'react'

const Title = (props) => {
    return (
        <div>
            <h2 className='heading'>{props.title}</h2>
        </div>
    )
}

export default Title

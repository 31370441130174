class ActionProvider {
    constructor(createChatBotMessage, setStateFunc) {
        this.createChatBotMessage = createChatBotMessage;
        this.setState = setStateFunc;
    }
    greet = () => {
        const message = this.createChatBotMessage(
            "How can I help you today?",
            {
                widget: 'learningOptions'
            }
        );

        this.updateChatbotState(message);
    }

    sorry = () => {
        const message = this.createChatBotMessage(
            "Sorry, unable to understand you. I am still learning. Kindly reach out through our email:",
            {
                widget: 'Others'
            }
        );

        this.updateChatbotState(message);
    }

    handleContact = () => {
        const message = this.createChatBotMessage(
            "For your Queries, reach out to us through",
            {
                widget: 'Contact'
            }
        );

        this.updateChatbotState(message);
    };

    handleStaffingServices = () => {
        const message = this.createChatBotMessage(
            "These are our Staffing Services",
            {
                widget: 'StaffingServices'
            }
        );

        this.updateChatbotState(message);
    };

    handleDigitalMarketingServices = () => {
        const message = this.createChatBotMessage(
            "These are our Digital Marketing Services",
            {
                widget: 'DigitalMarketingServices'
            }
        );

        this.updateChatbotState(message);
    };

    handleServices = () => {
        const message = this.createChatBotMessage(
            "Kindly Choose your Service",
            {
                widget: 'Services'
            }
        );

        this.updateChatbotState(message);
    };

    handleJob = () => {
        const message = this.createChatBotMessage(
            "Please Select",
            {
                widget: 'Job'
            }
        );

        this.updateChatbotState(message);
    };

    handleJobYes = () => {
        const message = this.createChatBotMessage(
            "Fantastic, We have these openings currently:",
            {
                widget: 'JobYes'
            }
        );

        this.updateChatbotState(message);
    };

    handleSmo = () => {
        const message = this.createChatBotMessage(
            "Social Media Optimization (SMO) is the process of optimizing and leveraging social media platforms to increase brand visibility, reach a wider audience, and engage with users.",
            {
                widget: 'SMO'
            }
        );

        this.updateChatbotState(message);
    };

    handleWeb = () => {
        const message = this.createChatBotMessage(
            "Website design is about creating the visual look and user experience, while website development involves building the functional aspects using code and technologies.",
            {
                widget: 'WEB'
            }
        );

        this.updateChatbotState(message);
    };

    handleSeo = () => {
        const message = this.createChatBotMessage(
            "SEO marketing, also known as Search Engine Optimization marketing, is a strategy aimed at improving a website's visibility and ranking on search engines, leading to increased organic (non-paid) traffic.",
            {
                widget: 'SEO'
            }
        );

        this.updateChatbotState(message);
    };

    handlePpc = () => {
        const message = this.createChatBotMessage(
            "PPC stands for Pay-Per-Click, a digital advertising model where advertisers pay a fee each time their ad is clicked by users.",
            {
                widget: 'PPC'
            }
        );

        this.updateChatbotState(message);
    };

    handleContent = () => {
        const message = this.createChatBotMessage(
            "Content marketing is a marketing strategy focused on creating and distributing valuable, relevant, and consistent content to attract and engage a target audience.",
            {
                widget: 'CONTENT'
            }
        );

        this.updateChatbotState(message);
    };

    handleBulk = () => {
        const message = this.createChatBotMessage(
            "Bulk marketing, also known as mass marketing, is a marketing strategy that involves promoting products or services to a large and broad audience without specific targeting.",
            {
                widget: 'BULK'
            }
        );

        this.updateChatbotState(message);
    };

    handleVideo = () => {
        const message = this.createChatBotMessage(
            "Video marketing is a marketing strategy that involves using videos to promote products, services, or brand messages to a target audience.",
            {
                widget: 'VIDEO'
            }
        );

        this.updateChatbotState(message);
    };

    handleAffiliate = () => {
        const message = this.createChatBotMessage(
            "Affiliate marketing is a performance-based marketing strategy where individuals or businesses (affiliates) earn commissions by promoting products or services of other companies.",
            {
                widget: 'AFFILIATE'
            }
        );

        this.updateChatbotState(message);
    };

    handleInfluencer= () => {
        const message = this.createChatBotMessage(
            "Influencer marketing is a marketing strategy where brands collaborate with influencers (individuals with a significant online following) to promote their products or services to the influencer's audience.",
            {
                widget: 'INFLUENCER'
            }
        );

        this.updateChatbotState(message);
    };

    handleOthers = () => {
        const message = this.createChatBotMessage(
            "Looking for Something Else, Kindly Write us on:",
            {
                widget: 'Others'
            }
        );

        this.updateChatbotState(message);
    };

    handleAddress = () => {
        const message = this.createChatBotMessage(
            "Click below for the Map Navigation",
            {
                widget: 'Address'
            }
        );

        this.updateChatbotState(message);
    };

    updateChatbotState(message) {
        // NOTICE: This function is set in the constructor, and is passed in from the top level Chatbot component. The setState function here actually manipulates the top level state of the Chatbot, so it's important that we make sure that we preserve the previous state.

        this.setState((prevState) => ({
            ...prevState,
            messages: [...prevState.messages, message]
        }));
    }
}

export default ActionProvider;

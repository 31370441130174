import React from 'react'
import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import fb from "../Firebase/firebase";
const DB = fb.firestore()
const CF = DB.collection('careers_form');

const Career_form = () => {

    const form = useRef();
    const [userData, setUserData] = useState({
        careers_name: "",
        careers_email: "",
        careers_mobile: "",
        careers_file: "",
        careers_letter: "",
        careers_post: ""

    });
    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }
    //connect with firebase

    const SubmitData = async (e) => {
        e.preventDefault();
        const { careers_name, careers_email, careers_mobile, careers_file, careers_letter, careers_post } = userData;
        if (careers_name && careers_email && careers_mobile && careers_file && careers_letter && careers_post) {


            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(userData.careers_email)) {
                alert('Please enter a valid email address');
                return
            }

            if (typeof careers_mobile !== "undefined") {

                var pattern = new RegExp(/^[0-9\b]+$/);

                if (!pattern.test(userData.careers_mobile)) {
                    alert("Please enter only number.");
                    return

                } else if (userData.careers_mobile.length !== 10) {
                    alert("Please enter 10 digit phone number.");
                    return
                }
            }

            try {

                CF.add({
                    careers_name: careers_name,
                    careers_email: careers_email,
                    careers_mobile: careers_mobile,
                    careers_file: careers_file,
                    careers_letter: careers_letter,
                    careers_post: careers_post
                }).then((docRef) => {
                    alert("Application Submitted Successfully");
                }).catch((error) => {
                    console.error("error:", error);
                });

                emailjs.sendForm('service_wblyans', 'template_n9ua8wv', form.current, 'ke7NgkL-D_6Ssn-xZ')
                    .then((result) => {
                        console.log(result.text);

                    }, (error) => {
                        console.log(error.text);
                    });

                alert('Message Sent Successfully');

                setUserData({
                    careers_name: "",
                    careers_email: "",
                    careers_mobile: "",
                    careers_file: "",
                    careers_letter: "",
                    careers_post: ""
                })

                // console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
                alert("Error adding document")
            }
        }
        else {
            alert('Please fill the data');
        }
    };

    return (
        <div>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title fs-5" id="exampleModalLabel">Apply Now</h3>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form ref={form} id='career-form' method='POST' >
                                <div className="mb-3">
                                    <input type="text"
                                        name="careers_name"
                                        placeholder="Enter Name *"
                                        autoFocus
                                        value={userData.careers_name}
                                        onChange={postUserData}
                                        required />
                                </div>
                                <div className="mb-3">
                                    <input type="text"
                                        name="careers_mobile"
                                        placeholder="Enter Mobile *"
                                        autoFocus
                                        value={userData.careers_mobile}
                                        onChange={postUserData}
                                        required />
                                </div>

                                <div className="mb-3">
                                    <input style={{ textTransform: 'lowercase' }} type="email"
                                        name="careers_email"
                                        placeholder="Enter Email *"
                                        autoFocus
                                        value={userData.careers_email}
                                        onChange={postUserData}
                                        required />
                                </div>

                                <select aria-label="Default select example"
                                    value={userData.careers_post}
                                    onChange={postUserData}
                                    className="form-select"
                                    name='careers_post'>
                                    <option value="Select Job" defaultValue='What Service are you looking for?'>Select Job *</option>
                                    <option value="Sr. Digital Marketing Manager">Sr. Digital Marketing Manager</option>
                                    <option value="SEO Executive">SEO Executive</option>
                                    <option value="Social Media Manager">Social Media Manager</option>
                                    <option value="UI/UX Intern">UI/UX Intern</option>
                                    <option value="Backend Developer">Backend Developer</option>
                                    <option value="Content Writer">Content Writer</option>

                                </select>

                                <div className="mb-3">
                                    <label className="my-3 mx-2"
                                        style={{ color: 'rgba(255,255,255,0.5' }}
                                        htmlFor="careers_file">
                                        File size should be less than or equal to 10kb *
                                    </label>
                                    <input type="file"
                                        name="careers_file"
                                        placeholder="Choose file"
                                        autoFocus
                                        value={userData.careers_file}
                                        onChange={postUserData}
                                        required />
                                </div>

                                <textarea className="form-control" placeholder='Cover Letter *' name="careers_letter"
                                    value={userData.careers_letter}
                                    onChange={postUserData}></textarea>

                                <button type="submit" className="btn btn-success" value="Submit" onClick={SubmitData}>Submit</button>
                            </form>
                        </div>
                        <div className="modal-footer">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Career_form

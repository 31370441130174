import React from 'react'
import Footer from '../../Utilities/Footer'
import Bot from '../../Functionalities/Bot'
import Header from '../../Utilities/Header'
import UpArrow from '../../Utilities/UpArrow'
import Title from '../../Utilities/Title'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";


const DigitalMarketingServices = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);


  const services = [
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039612/AHS%20Assured%20Services/Services/smo_evgqi5.webp",
      title: "Social media optimization",
      url: "/smo"
    },
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039597/AHS%20Assured%20Services/Services/web_tn4ri1.webp",
      title: "website design and development",
      url: "/web_design_and_development"
    },
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039614/AHS%20Assured%20Services/Services/seo_yrgneh.webp",
      title: "search engine optimization",
      url: "/seo"
    },
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039610/AHS%20Assured%20Services/Services/lead_generation_igi9un.webp",
      title: "pay per click advertising",
      url: "/ppc"
    },
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039605/AHS%20Assured%20Services/Services/content_marketing_ngbahu.webp",
      title: "content marketing",
      url: "/content_marketing"
    },
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039603/AHS%20Assured%20Services/Services/bulk_marketing_gshns6.webp",
      title: "bulk marketing",
      url: "/bulk_marketing"
    },
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039616/AHS%20Assured%20Services/Services/video_marketing_wmnuua.webp",
      title: "video marketing",
      url: "/video_marketing"
    },
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039601/AHS%20Assured%20Services/Services/affiliate_marketing_wtbeix.webp",
      title: "affiliate marketing",
      url: "/affiliate_marketing"
    },
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039607/AHS%20Assured%20Services/Services/influencer_vum0uh.webp",
      title: "influencer marketing",
      url: "/influencer_marketing"
    },
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039598/AHS%20Assured%20Services/Services/web_solutions_n8k23f.webp",
      title: "web solutions",
      url: "/web-solutions"
    },

  ]

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AHS Assured Services Pvt Ltd - Digital Marketing Services</title>
        <link rel="canonical" href="https://www.ahsassuredservices.com/digital_marketing_services" />
        <meta name="description" content="Boost your online presence with top-notch digital marketing services to maximize growth and visibility. Contact us today for digital marketing expert solutions" />
      </Helmet>
      <UpArrow />
      <Header />
      <Bot />

      <div id="digital">

        <div className='banner-hero'>
          <div className="banner-hero-text">
            <h1>Digital Marketing</h1>
            <div className="content">
              <h2>Transforming Digital Media</h2>
            </div>
            <a href='#digital_marketing_services'>Learn More</a>
          </div>
          <div className="banner-hero-img">
            <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632927/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/hero-img_i4xuyn.webp' alt="staffing-background" className='img-fluid' />
          </div>
        </div>

        <div className="digital-what">
          <Title title="What is digital marketing?" />
          <div className="digital-what-content">
            <div className='digital-what-img'>
              <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632928/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/image1_rb4hgn.webp' alt="image1" />
            </div>

            <div className="digital-what-text">
              <p>Digital Marketing Services refer to the use of digital channels, such as search engines, social media, email,mobile apps, and websites, to promote a product or a service.</p>
              <p>The main objective of digital marketing is to reach and engage with a target audience, build brand awareness, and drive sales and revenue. There are several types of digital marketing services.</p>
              <p>Digital marketing refers to the practice of promoting products, services, or brands using digital channels and technologies. It encompasses various online marketing strategies and tactics aimed at reaching and engaging a target audience through digital platforms such as search engines, social media, email, websites, mobile apps, and more.</p>
              <p>Digital marketing leverages the power of the internet and digital devices to deliver marketing messages, create brand awareness, drive website traffic, generate leads, and ultimately, increase sales and business growth. It encompasses a wide range of activities</p>
            </div>
          </div>
        </div>

        <div className="digital-advantages">
          <Title title="Advantages of Digital Marketing" />
          <div className="digital-advantages-content">
            <div className="digital-advantages-content-img">
              <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632927/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/global_reach_bbohxv.webp' alt="global reach" />
              <h2>Global Reach</h2>
            </div>

            <div className="digital-advantages-content-img">
              <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632929/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/lower_cost_seqodt.webp' alt="lower cost" />
              <h2>lower cost</h2>
            </div>

            <div className="digital-advantages-content-img">
              <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632927/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/effective_targeting_ccyryc.webp' alt="effective targeting" />
              <h2>effective targeting</h2>
            </div>

            <div className="digital-advantages-content-img">
              <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632928/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/increased_effective_enagagement_lk7yt2.webp' alt="increases" />
              <h2>increased effective engagement</h2>
            </div>

            <div className="digital-advantages-content-img">
              <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632924/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/analytics_and_optimization_isdv5m.webp' alt="analytics" />
              <h2>analytics and optimisation</h2>
            </div>

          </div>
        </div>

        <div id='digital_marketing_services' className='services-section'>

          <Title title="Services" />

          <div className="services-content">
            {services.map((item) => (
              <div className="services-content-text">
                <img src={item.img} alt={item.title} />
                <h3>{item.title}</h3>
                <Link to={item.url} target="_blank"></Link>
              </div>
            ))}
          
          </div>
        </div>

      </div>
      <Footer />
    </div>
  )
}

export default DigitalMarketingServices

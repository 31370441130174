import React from 'react'
import Header from '../../../Utilities/Header'
import Footer from '../../../Utilities/Footer'
import { useEffect } from 'react'
import UpArrow from '../../../Utilities/UpArrow'
import Bot from '../../../Functionalities/Bot'
import Title from '../../../Utilities/Title'
import { Link } from 'react-router-dom'
import InquiryForm from '../../../Utilities/InquiryForm'

const Ppc = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Bot />
      <UpArrow />
      <Header />

      <div className="ppc">

        <div className='banner-hero'>

          <div className="banner-hero-text">
            <h1>Pay Per Click Advsertising</h1>
            <div className="content">
              <h2>Empower Your Clicks, Maximize Your Reach!</h2>
            </div>
            {/* <a href='#staffing-services'>Learn More</a> */}
          </div>

          <div className="banner-hero-img">
            <img rel="preload" loading="lazy"  src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632929/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/PPC/hero_c55p5n.webp' alt="smo-background" className='img-fluid' />
          </div>

        </div>

        <div className="smo-what">
          <div className="smo-what-text">
            <Title title="What is Pay per click advertising?" />
          </div>

          <div className="smo-what-content">

            <div className="smo-what-content-text">
              <p>Pay Per Click (PPC) advertising is an online advertising model in which advertisers pay a fee each time one of their ads is clicked. It's a way of buying visits to a website rather than attempting to earn those visits organically. PPC is commonly associated with search engines, social media platforms, and other websites where advertisers bid on relevant keywords or placements to display their ads.</p>

              <p>
              The most popular PPC platform is Google Ads, where advertisers bid on keywords related to their products or services. When a user searches for a specific keyword, the search engine displays relevant ads based on the bidding and ad quality. If the user clicks on one of these ads, the advertiser pays a certain amount, and the user is redirected to the advertiser's website.
              </p>
            </div>

            <div className="smo-what-content-img">
              <img rel="preload" loading="lazy"  src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632929/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/PPC/ppc_what_rwsche.webp' alt="what is smo?" />
            </div>

          </div>
        </div>

        <div className='content-services web-why'>
          <div className="smo-what-text">
            <Title title="how pay per click advertising works" />
          </div>
          <div className='container-fluid mx-auto mt-5 mb-5 col-12' style={{ textAlign: "center" }}>

            <div className="row web-why-card" style={{ justifyContent: "center" }}>
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    {/* <img rel="preload" loading="lazy"  className="img-fluid" src={hero} alt='customized' /> */}
                    <div className="shadow"></div>
                    <div className="card-title">Ad Auction</div>
                    <div className="card-subtitle">
                      <p>In PPC, advertisers bid on keywords or ad placements relevant to their target audience. When a user enters a search query or visits a webpage with relevant content, an ad auction takes place to determine which ads will be shown.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    {/* <img rel="preload" loading="lazy"  className="img-fluid" src={products} alt='user-Friendly Experience' /> */}
                    <div className="card-title">Ad Rank</div>
                    <div className="card-subtitle">
                      <p>The ad's position in the search results or website is determined by the ad rank, which considers the bid amount, ad quality, and other factors.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    {/* <img rel="preload" loading="lazy"  className="img-fluid" src={explainer} alt='Mobile-Optimized' /> */}
                    <div className="card-title">Ad Display</div>
                    <div className="card-subtitle">
                      <p> If the user's search query or webpage content matches the keywords and targeting set by the advertiser, the ad is displayed to the user.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    {/* <img rel="preload" loading="lazy"  className="img-fluid" src={testimonails} alt='Mobile-Optimized' /> */}
                    <div className="card-title">Cost per Click (CPC)</div>
                    <div className="card-subtitle">
                      <p>Advertisers only pay when a user clicks on their ad. The cost per click depends on the bidding competition and the ad's quality score.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>

        <div id='seo_key' className="smo-types seo">
          <Title title="advanatages of pay per click advertising" />
          <div className="seo-key-content">
            <div className="seo-key-content-text">
              <h2>Targeted Advertising</h2>
              <hr />
              <p>PPC allows precise audience targeting based on keywords, demographics, interests, and behaviors.</p>
            </div>

            <div className="seo-key-content-text">
              <h2>Measurable Results</h2>
              <hr />
              <p>Advertisers can track key performance metrics like clicks, conversions, and return on investment (ROI).</p>
            </div>

            <div className="seo-key-content-text">
              <h2>Cost Control</h2>
              <hr />
              <p>Advertisers set daily or monthly budgets, ensuring they don't exceed their advertising expenses.</p>
            </div>

            <div className="seo-key-content-text">
              <h2>Quick Results</h2>
              <hr />
              <p>PPC campaigns can generate immediate traffic and visibility for businesses.</p>
            </div>

            <div className="seo-key-content-text">
              <h2>Brand Awareness</h2>
              <hr />
              <p>Even if users don't click on the ads, they can still be exposed to the brand's message, increasing brand awareness.</p>
            </div>

          </div>

        </div>

        <div className='project'>
          <div className="project-img">
            <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632929/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/PPC/project_ond0j1.webp' alt="project" />
          </div>

          <div className='project-text'>
            <h2>looking for Pay Per Click Advertising?</h2>
            <Link data-bs-toggle="modal" data-bs-target="#getintouchform" to={'#'}>get started</Link>
        </div>
        <InquiryForm />
        </div>

      </div>



      <Footer />
    </div>
  )
}

export default Ppc

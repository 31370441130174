import React from 'react';

import './LearningOptions.css';

const ServicesLO = (props) => {
    const options = [
        {
            text: 'Digital Marketing Services',
            handler: props.actionProvider.handleDigitalMarketingServices,
            id: 1
        },
        {
            text: 'Staffing Services',
            handler: props.actionProvider.handleStaffingServices,
            id: 2
        }
    ];

    const optionsMarkup = options.map((option) => (
        <button
            className='option-button'
            key={option.id}
            onClick={option.handler}
        >
            {option.text}
        </button>
    ));

    return <div className='options-container'>{optionsMarkup}</div>;
};

export default ServicesLO;

import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import emailjs from '@emailjs/browser';
import fb from "../Firebase/firebase";
const DB = fb.firestore()
const GITF = DB.collection('get_in_touch_form');

const Slider = () => {

    const navigate = useNavigate();
    //database setup 

    const form = useRef();
    const [userData, setUserData] = useState({
        Name: "",
        Email: "",
        Mobile: "",
        Service: "",
        Message: "",
        Subject: ""

    });
    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }
    //connect with firebase

    const SubmitData = async (e) => {
        e.preventDefault();
        const { Name, Email, Mobile, Service, Message, Subject } = userData;
        if (Name && Email && Mobile && Service && Message && Subject) {


            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(userData.Email)) {
                alert('Please enter a valid email address');
                return
            }

            if (typeof Mobile !== "undefined") {

                var pattern = new RegExp(/^[0-9\b]+$/);

                if (!pattern.test(userData.Mobile)) {
                    alert("Please enter only number.");
                    return

                } else if (userData.Mobile.length !== 10) {
                    alert("Please enter 10 digit phone number.");
                    return
                }
            }

            try {

                GITF.add({
                    Name: Name,
                    Email: Email,
                    Mobile: Mobile,
                    Subject: Subject,
                    Service: Service,
                    Message: Message
                }).then((docRef) => {
                    alert("Message Sent Successfully");
                }).catch((error) => {
                    console.error("error:", error);
                });

                emailjs.sendForm('service_wblyans', 'template_mx183ea', form.current, 'ke7NgkL-D_6Ssn-xZ')
                    .then((result) => {
                        console.log(result.text);

                    }, (error) => {
                        console.log(error.text);
                    });

                navigate("/thankyou");

                setUserData({
                    Name: "",
                    Email: "",
                    Mobile: "",
                    Service: "",
                    Message: "",
                    Subject: ""
                })
                // console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
                alert("Error adding document")
            }
        }
        else {
            alert('Please fill the data');
        }
    };

    return (
        <div className='slider'>
            <div className="carousel">
                <div class="carousel-wrapper">
                    <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} showStatus={false}>
                        <div className='carousel-body'>
                            <img rel="preload" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696153846/AHS%20Assured%20Services/Banner/new/4_nfvkar_gd8m3u.webp" alt='slider1' />

                        </div>
                        <div className='carousel-body'>
                            <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1690632915/AHS%20Assured%20Services%20Pvt%20Ltd/Banner/new/7_l9pfhq.webp" alt='slider2' />

                        </div>
                        <div className='carousel-body'>
                            <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1690632912/AHS%20Assured%20Services%20Pvt%20Ltd/Banner/new/4_nfvkar.webp" alt='slider3' />
                        </div>
                    </Carousel>
                </div>

                <div className="carousel-caption">
                    <h1>Enhance <br /> your <span>business</span>  <br /> with <span> our services</span></h1>
                    <p>"We believe that our client's success is the finest <br /> degree of our own performance"</p>
                </div>
                <div className="slider-form">
                    <h4>Get In Touch</h4>

                    <form ref={form} className="child2" id="get_in_touch" method="POST" >
                        <div className="child2-flex">

                            <input
                                type="text"
                                name="Name"
                                id="name"
                                placeholder='Your Name'
                                value={userData.Name}
                                onChange={postUserData}
                                required />

                            <input type="text" name="Mobile"
                                id="phone"
                                placeholder='Phone Number'
                                value={userData.Mobile}
                                onChange={postUserData}
                                required />

                            <div className="optgroup">
                                <input
                                    type="text"
                                    name="Subject"
                                    id="subject"
                                    placeholder='Subject'
                                    value={userData.Subject}
                                    onChange={postUserData}
                                    required />
                                <input
                                    type="email"
                                    name="Email"
                                    id="email"
                                    placeholder='Email'
                                    value={userData.Email}
                                    onChange={postUserData}
                                    required />
                            </div>

                            <select aria-label="Default select example"
                                value={userData.Service}
                                onChange={postUserData}
                                className="form-select"
                                name='Service'>
                                <option value="default" defaultValue='What Service are you looking for?'>What Type of Service are you looking for?</option>

                                <optgroup label="Digital Marketing Services">
                                    <option value="Social Media Optimization (SMO)">Social Media Optimization (SMO)</option>
                                    <option value="website design & development">website design & development</option>
                                    <option value="Search Engine Optimization (SEO)">Search Engine Optimization (SEO)</option>
                                    <option value="pay per click (PPC) advertising">pay per click (PPC) advertising</option>
                                    <option value="Content Marketing">Content Marketing</option>
                                    <option value="Bulk Marketing">Bulk Marketing</option>
                                    <option value="Video Marketing">Video Marketing</option>
                                    <option value="Affiliate marketing">Affiliate marketing</option>
                                    <option value="Influencer Marketing">Influencer Marketing</option>
                                </optgroup>
                                <optgroup label="Staffing Services">
                                    <option value="Temporary staffing services">Temporary staffing services</option>
                                    <option value="Permanent staffing services">Permanent staffing services</option>
                                    <option value="Contract staffing services">Contract staffing services</option>
                                    <option value="Executive Search Services">Executive Search Services</option>
                                </optgroup>
                                {/* <optgroup label="Real Estate Services">
                                    <option value="Sale">Sale</option>
                                    <option value="Purchase">Purchase</option>
                                    <option value="Residential/Commercial Property">Residential/Commercial Property</option>
                                </optgroup> */}
                            </select>
                            <textarea
                                id="message"
                                cols="30"
                                rows="5"
                                placeholder='Your Message'
                                name="Message"
                                value={userData.Message}
                                onChange={postUserData}
                                required>

                            </textarea>
                            <div className="chec">
                                <input type="checkbox" name="check" id="check" />
                                <label htmlFor="check">I hereby allow to receive promotional messages.</label>
                            </div>


                        </div>

                        <button
                            type="submit"
                            className="btn btn-light"
                            value="Send"
                            onClick={SubmitData}
                        >
                            Send
                        </button>

                    </form>
                </div>
            </div>

        </div>
    )
}

export default Slider

import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import Header from '../../Utilities/Header'
import Footer from '../../Utilities/Footer'
import { useParams } from 'react-router-dom';
import fb from "../../Firebase/firebase";
// import ReactPlayer from 'react-player/youtube'
const DB = fb.firestore()
const Blogslist = DB.collection('Blogs');

const BlogDetails = () => {

  const { id } = useParams();
  const [blogs, Setblogs] = useState([]);
  Blogslist.doc(id).get().then((snapshot) => {
    const data = snapshot.data()
    Setblogs(data);
  });

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{blogs.Title + "| AHS Assured Services"}</title>
        <link rel="canonical" href={"https://ahsassuredservices.com/show/" + blogs.id} />
        <meta name="description" content={blogs.Description} />
        <meta property="og:image"
          content={blogs.Image} />
      </Helmet>

      <Header />
      <div className="blog blog-details">
        <div className="blog-content">
          <div className="blog-content-body">
            <img rel="preload" src={blogs.Image} alt="blogImage" className='blg-img' />
            {/* <ReactPlayer url={blogs.Video}
              controls={true}
              width="100%"
              height="60%"
              autoplay={false}
              light={true}
              playing={false}
            /> */}
            <p><strong>Posted on:</strong> {blogs.Date}</p>
            <div className="blog-details-content-body-text">
              <h1>{blogs.Title}</h1>
              <div dangerouslySetInnerHTML={{ __html: blogs.Body }}></div>
            </div>
          </div>
        </div>

      </div >
      <Footer />
    </div >
  )
}

export default BlogDetails

import Footer from '../../Utilities/Footer'
import React from 'react'
import { useEffect } from "react"
import Bot from '../../Functionalities/Bot'
import Header from '../../Utilities/Header';
import UpArrow from '../../Utilities/UpArrow';
import CareerForm from '../../Utilities/Career_form'
import { Helmet } from "react-helmet";
import Title from '../../Utilities/Title'
import "./Careers.scss"

const Careers = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AHS Assured Services Pvt Ltd - Careers</title>
        <link rel="canonical" href="https://www.ahsassuredservices.com/careers" />
        <meta name="description" content="Explore exciting opportunities and grow your career with us. Join a dynamic team and make your mark. Unlock your potential today!" />
      </Helmet>
      <Bot />
      <UpArrow />
      <Header />
      <CareerForm />
      <section className="careers">

        <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632913/AHS%20Assured%20Services%20Pvt%20Ltd/Banner/careers_y18ups.webp' alt="careers" />
        <div className="careers-section2">
          <h1>Join us as we set off on a fruitful and successful path where your passion will be honoured at every turn.
          </h1>
          <p>Apply now and let your passion shine!</p>
          <p>#LetsGrowTogether</p>

          <div className="careers-card">
            <div className="ag-format-container">
              <div className="ag-courses_box">

                <div className="ag-courses_item">
                  <a href="javascript:void(0);" className="ag-courses-item_link">
                    <div className="ag-courses-item_bg"></div>

                    <div className="ag-courses-item_title">
                      <h4>Social Media Manager</h4>
                    </div>

                    <div className="ag-courses-item_date-box">
                      <span className="ag-courses-item_date">
                        <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#exampleModal"> Apply Now <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                          <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                        </svg></a>


                      </span>
                    </div>
                  </a>
                </div>

                <div className="ag-courses_item">
                  <a href="javascript:void(0);" className="ag-courses-item_link">
                    <div className="ag-courses-item_bg"></div>

                    <div className="ag-courses-item_title">
                      <h4>Sr. Digital Marketing Manager</h4>
                    </div>

                    <div className="ag-courses-item_date-box">
                      <span className="ag-courses-item_date">
                        <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#exampleModal"> Apply Now <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                          <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                        </svg></a>
                      </span>
                    </div>
                  </a>
                </div>


                <div className="ag-courses_item">
                  <a href="javascript:void(0);" className="ag-courses-item_link">
                    <div className="ag-courses-item_bg"></div>

                    <div className="ag-courses-item_title">
                      <h4>Content Writer</h4>
                    </div>

                    <div className="ag-courses-item_date-box">
                      <span className="ag-courses-item_date">
                        <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#exampleModal"> Apply Now <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                          <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                        </svg></a>
                      </span>
                    </div>
                  </a>
                </div>

                <div className="ag-courses_item">
                  <a href="javascript:void(0);" className="ag-courses-item_link">
                    <div className="ag-courses-item_bg"></div>

                    <div className="ag-courses-item_title">
                      <h4>SEO Executive</h4>
                    </div>

                    <div className="ag-courses-item_date-box">
                      <span className="ag-courses-item_date">
                        <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#exampleModal"> Apply Now <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                          <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                        </svg></a>
                      </span>
                    </div>
                  </a>
                </div>

                <div className="ag-courses_item">
                  <a href="javascript:void(0);" className="ag-courses-item_link">
                    <div className="ag-courses-item_bg"></div>

                    <div className="ag-courses-item_title">
                      <h4>UI/UX Intern</h4>
                    </div>

                    <div className="ag-courses-item_date-box">
                      <span className="ag-courses-item_date">
                        <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#exampleModal"> Apply Now <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                          <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                        </svg></a>
                      </span>
                    </div>
                  </a>
                </div>

                <div className="ag-courses_item">
                  <a href="javascript:void(0);" className="ag-courses-item_link">
                    <div className="ag-courses-item_bg"></div>

                    <div className="ag-courses-item_title">
                      <h4>BackEnd Developer</h4>
                    </div>

                    <div className="ag-courses-item_date-box">
                      <span className="ag-courses-item_date">
                        <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#exampleModal"> Apply Now <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                          <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                        </svg></a>
                      </span>
                    </div>
                  </a>
                </div>

              </div>
            </div>
          </div>

        </div>

        <div className="staffing-hiring overview">
          <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632914/AHS%20Assured%20Services%20Pvt%20Ltd/Career/passion_klafll.jpg' alt="hiring" className='img-fluid' />
          <div className="staffing-hiring-text overview-text">
            <Title title="Are You Passionate About Making A Difference?" />
            {/* <h2>Are You Passionate About Making A Difference?</h2> */}
            <p>Do you want to work in a dynamic and innovative environment where your ideas are valued? Join our team of passionate individuals and be part of an organization that is dedicated to changing lives!</p>
            <p>We are seeking passionate employees who are driven by their love for what they do. Whether you're a seasoned professional or just starting your career, we believe that passion is the key to success. We want individuals who are excited to come to work every day, ready to tackle challenges, and make a positive impact.</p>
          </div>

        </div>

        <div className="careers-who">
          <Title title="Why Work With Us?" />
          <div className='careers-sticky-notes'>

            <div className="quote-container">
              <i className="pin"></i>
              <div className="note ">
                <h4>Meaningful work</h4>
                <hr />
                Join a company that is making a real difference in people's lives.
              </div>
            </div>

            <div className="quote-container">
              <i className="pin"></i>
              <div className="note ">
                <h4>Growth opportunities</h4>
                <hr />
                As a passionate employee, you'll have access to continuous learning opportunities, mentorship programs, and career advancement prospects.
              </div>
            </div>

            <div className="quote-container">
              <i className="pin"></i>
              <div className="note ">
                <h4>  Collaborative environment</h4>
                <hr />
                You'll have the opportunity to work with a diverse group of passionate individuals who share a common goal.
              </div>
            </div>

            <div className="quote-container">
              <i className="pin"></i>
              <div className="note ">
                <h4>  Innovation-driven</h4>
                <hr />
                We encourage out-of-the-box thinking and value new ideas. As a passionate employee, you'll have the freedom to be creative and bring your innovative solutions to the table.
              </div>
            </div>

            <div className="quote-container">
              <i className="pin"></i>
              <div className="note ">
                <h4>Work-life balance</h4>
                <hr />
                We understand the importance of maintaining a healthy work-life balance. We offer flexible working hours and other employee benefits to ensure your well-being.
              </div>
            </div>
          </div>
        </div>

      </section>

      <Footer />
    </div>
  )
}

export default Careers

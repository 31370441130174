import React, { useState } from 'react'
import Header from '../../Utilities/Header'
import Slider from '../../Utilities/Slider'
import { Link } from 'react-router-dom'
import Footer from '../../Utilities/Footer'
import Bot from '../../Functionalities/Bot'
import UpArrow from '../../Utilities/UpArrow'
import HomeBlog from "../../Utilities/HomeBlog";
import { Helmet } from 'react-helmet'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./Home.scss"
import Cookies from '../../Molecules/Cookies/Cookies'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ReadMoreReact from 'read-more-react';


const Home = () => {

  // Testimonials--------------------------------
  // const [show1, setShow1] = useState(false);
  // const [text, setText] = useState('Read More');

  // const show = () => {

  //   if (text === 'Read More') {
  //     setShow1(true);
  //     setText('Read Less');
  //   } else {
  //     setText('Read More');
  //     setShow1(false);
  //   }
  // }

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };

    return (
      <p className="text">
        {isReadMore ? text.slice(0, 100) : text}
        <span
          onClick={toggleReadMore}
          className="read-or-hide"
          style={{ color: "#2058D4" }}
        >
          {isReadMore ? "...Read More" : " Show Less"}
        </span>
      </p>
    );
  };
  // Testimonials--------------------------------

  const partners = [
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1723020161/AHS%20Assured%20Services/partners/5_a8q1a6.png",
      title: "AliveCor"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1723020161/AHS%20Assured%20Services/partners/4_ybw4ra.png",
      title: "Hotel Highway King"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1723020165/AHS%20Assured%20Services/partners/7_szthxm.png",
      title: "Global Pratibha International School"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1723020161/AHS%20Assured%20Services/partners/3_jtpsfz.png",
      title: "Evaluable InfoServe"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1723020162/AHS%20Assured%20Services/partners/6_evxam5.png",
      title: "Uddy's Skill Club"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1723008114/AHS%20Assured%20Services/partners/Chai_leaf_c4imiz.png",
      title: "Chai Leaf"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1723008177/AHS%20Assured%20Services/partners/GC_Venture_dnw4zj.png",
      title: "Gc Venture"
    },

    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709195579/AHS%20Assured%20Services/partners/moa_qimsrb.webp",
      title: "Maharashtra Orthopedic Association"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709298646/AHS%20Assured%20Services/partners/Untitled_design_1_volp4h.webp",
      title: "Knite Ryder"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1711784863/AHS%20Assured%20Services/partners/path_of_music_lvuiou.webp",
      title: "Path Of Music College"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1711955736/AHS%20Assured%20Services/partners/Untitled_design__3_-removebg-preview_hbhige.png",
      title: "Art Plus"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709032590/AHS%20Assured%20Services/partners/logos-removebg-preview_zxecmy.webp",
      title: "Well Wishers Home Healthcare"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709020667/AHS%20Assured%20Services/partners/1-removebg-preview_e4yccg.webp",
      title: "Grocery Villa"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709033033/AHS%20Assured%20Services/partners/logos-removebg-preview_cvgnfy.webp",
      title: "Dwarka School Of Music"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709020667/AHS%20Assured%20Services/partners/2-removebg-preview_yo84fz.webp",
      title: "Think Tank Research Group"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709033034/AHS%20Assured%20Services/partners/logos__1_-removebg-preview_i9nky2.webp",
      title: "Bliss School Of Music"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709020667/AHS%20Assured%20Services/partners/3-removebg-preview_ritfil.webp",
      title: "Competency Enhancers"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709020668/AHS%20Assured%20Services/partners/4-removebg-preview_tbxs3d.webp",
      title: "Wise Finserv Private Wealth"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709020667/AHS%20Assured%20Services/partners/5-removebg-preview_qg65hv.webp",
      title: "Akcent Healthcare"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709020668/AHS%20Assured%20Services/partners/6-removebg-preview_o3pi0o.webp",
      title: "GreyBeez"
    },
    {
      url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709029603/AHS%20Assured%20Services/partners/22-removebg-preview_uchiha.webp",
      title: "Studio9"
    },
    // {
    //   url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709029598/AHS%20Assured%20Services/partners/7-removebg-preview_n1vdbi.webp",
    //   title: "Veinosil"
    // },
    // {
    //   url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709029599/AHS%20Assured%20Services/partners/8-removebg-preview_b2nygh.webp",
    //   title: "Axcelto"
    // },
    // {
    //   url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709031195/AHS%20Assured%20Services/partners/9-removebg-preview_1_livfqt.webp",
    //   title: "Murine"
    // },
    // {
    //   url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709029597/AHS%20Assured%20Services/partners/10-removebg-preview_fdbvq7.webp",
    //   title: "Aksam-400"
    // },
    // {
    //   url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709031195/AHS%20Assured%20Services/partners/11-removebg-preview_1_y2kxzp.webp",
    //   title: "Beklair"
    // },
    // {
    //   url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709029597/AHS%20Assured%20Services/partners/12-removebg-preview_crtpds.webp",
    //   title: "Beveles"
    // },
    // {
    //   url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709029597/AHS%20Assured%20Services/partners/13-removebg-preview_pnth3p.webp",
    //   title: "Beluksi"
    // },
    // {
    //   url: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709029597/AHS%20Assured%20Services/partners/14-removebg-preview_fzafbu.webp",
    //   title: "Betkas-N3"
    // }
  ]

  const services = [
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039612/AHS%20Assured%20Services/Services/smo_evgqi5.webp",
      title: "Social media optimization",
      url: "/smo"
    },
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039597/AHS%20Assured%20Services/Services/web_tn4ri1.webp",
      title: "website design and development",
      url: "/web_design_and_development"
    },
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039614/AHS%20Assured%20Services/Services/seo_yrgneh.webp",
      title: "search engine optimization",
      url: "/seo"
    },
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039610/AHS%20Assured%20Services/Services/lead_generation_igi9un.webp",
      title: "pay per click advertising",
      url: "/ppc"
    },
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039605/AHS%20Assured%20Services/Services/content_marketing_ngbahu.webp",
      title: "content marketing",
      url: "/content_marketing"
    },
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039603/AHS%20Assured%20Services/Services/bulk_marketing_gshns6.webp",
      title: "bulk marketing",
      url: "/bulk_marketing"
    },
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039616/AHS%20Assured%20Services/Services/video_marketing_wmnuua.webp",
      title: "video marketing",
      url: "/video_marketing"
    },
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039601/AHS%20Assured%20Services/Services/affiliate_marketing_wtbeix.webp",
      title: "affiliate marketing",
      url: "/affiliate_marketing"
    },
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039607/AHS%20Assured%20Services/Services/influencer_vum0uh.webp",
      title: "influencer marketing",
      url: "/influencer_marketing"
    },
    {
      img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039598/AHS%20Assured%20Services/Services/web_solutions_n8k23f.webp",
      title: "web solutions",
      url: "/web-solutions"
    },

  ]

  const responsive = {
    0: { items: 1 },
    480: { items: 2 },
    1024: { items: 3 },
  };

  const items = [
    <div className="services-content-text" data-value="1">
      <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039612/AHS%20Assured%20Services/Services/smo_evgqi5.webp" alt="Social Media Optimization" />
      <h3>Social Media Optimization</h3>
      <Link to={"/smo"} target="_blank"></Link>
    </div>,
    <div className="services-content-text" data-value="2">
      <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039597/AHS%20Assured%20Services/Services/web_tn4ri1.webp" alt="website design and development" />
      <h3>website design and development</h3>
      <Link to={"/web_design_and_development"} target="_blank"></Link>
    </div>,
    <div className="services-content-text" data-value="3">
      <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039614/AHS%20Assured%20Services/Services/seo_yrgneh.webp" alt="search engine optimization" />
      <h3>search engine optimization</h3>
      <Link to={"/seo"} target="_blank"></Link>
    </div>,
    <div className="services-content-text" data-value="4">
      <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039610/AHS%20Assured%20Services/Services/lead_generation_igi9un.webp" alt="Social Media Optimization" />
      <h3>pay per click advertising</h3>
      <Link to={"/ppc"} target="_blank"></Link>
    </div>,
    <div className="services-content-text" data-value="5">
      <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039605/AHS%20Assured%20Services/Services/content_marketing_ngbahu.webp" alt="Social Media Optimization" />
      <h3>content marketing</h3>
      <Link to={"/content_marketing"} target="_blank"></Link>
    </div>,
    <div className="services-content-text" data-value="6">
      <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039603/AHS%20Assured%20Services/Services/bulk_marketing_gshns6.webp" alt="bulk marketing" />
      <h3>bulk marketing</h3>
      <Link to={"/bulk_marketing"} target="_blank"></Link>
    </div>,
    <div className="services-content-text" data-value="7">
      <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039616/AHS%20Assured%20Services/Services/video_marketing_wmnuua.webp" alt="Social Media Optimization" />
      <h3>video marketing</h3>
      <Link to={"/video_marketing"} target="_blank"></Link>
    </div>,
    <div className="services-content-text" data-value="8">
      <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039601/AHS%20Assured%20Services/Services/affiliate_marketing_wtbeix.webp" alt="Social Media Optimization" />
      <h3>affiliate marketing</h3>
      <Link to={"/affiliate_marketing"} target="_blank"></Link>
    </div>,
    <div className="services-content-text" data-value="9">
      <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039607/AHS%20Assured%20Services/Services/influencer_vum0uh.webp" alt="Social Media Optimization" />
      <h3>influencer marketing</h3>
      <Link to={"/influencer_marketing"} target="_blank"></Link>
    </div>,
    <div className="services-content-text" data-value="10">
      <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712039598/AHS%20Assured%20Services/Services/web_solutions_n8k23f.webp" alt="Social Media Optimization" />
      <h3>web solutions</h3>
      <Link to={"/web-solutions"} target="_blank"></Link>
    </div>,
  ];

  const onSlideChange = (e) => {
    // console.log(`onSlideChanged => Item's position after changes: ${e.item}. Event:`, e);
    console.log(`onSlideChanged => Item's position after changes: ${e.item}`);

  };

  const [readMore, setReadMore] = useState({
    display: "none",
    height: "60vh"
  });

  const [button, setbutton] = useState({
    more: "block",
    less: "none"
  })

  const showReadMore = () => {
    setReadMore({
      display: "block",
      height: "90vh"
    })
    setbutton({
      more: "none",
      less: "block"
    })
  }

  const showReadLess = () => {
    setReadMore({
      display: "none",
      height: "60vh"
    })
    setbutton({
      more: "block",
      less: "none"
    })
  }

  return (
    <div>

      <Cookies />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Digital Marketing Agency | Digital Marketing Company in India | AHS Assured Services</title>
        <link rel="canonical" href="https://www.ahsassuredservices.com/" />
        <meta name="description" content="AHS Assured Services is a 360&deg; digital marketing agency in India, offering digital marketing services like SMO, SEO, SMM, Web design & Development, PPC, etc. Partner with us to fuel your online success." />
        <meta property="og:image"
          content="https://res.cloudinary.com/dcimsha8g/image/upload/v1690632934/AHS%20Assured%20Services%20Pvt%20Ltd/Logo/ahs_logo_bg_bgssoa.jpg" />

      </Helmet>
      {/* <img className='c-shape' src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1703164714/AHS%20Assured%20Services/Christmas/Untitled_design_1_ob6ksq.png" alt="christmas shape" /> */}
      {/* <img className='c-snowflake' src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1703221736/AHS%20Assured%20Services/Christmas/Untitled_design_3_xq46ea.png" alt="" /> */}

      <Header />
      <Slider />
      <Bot />
      <UpArrow />
      {/* About section */}
      <div className="home-about">
        <div className="section-head">
          <h4><span>About Us</span> </h4>
        </div>
        <div className='home-about-us'>
          <div className="abt-pic">
            <div className="img-1"></div>
            <div className="img-2"></div>
            <img rel="preload" loading="lazy" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632912/AHS%20Assured%20Services%20Pvt%20Ltd/About/about-one-img-1_v8qwko.webp' className="img-fluid" alt="about" />
          </div>
          <div className='abt-text'>
            {/* <h2>360° Business Solutions</h2>
            <p>With 15+ years of business experience at leadership level, with a moral approach, guaranteeing high service levels and adherence to quality benchmarks. We&apos;ll assist you think about your business in an entire new way, bringing a new viewpoint to long-standing challenges and making a difference you imagine – and accomplish – a future with boundless conceivable outcomes.</p> */}
            <h2>AHS Assured Services: Your Digital Success Partner</h2>
            <p>We are AHS Assured Services, a dynamic digital marketing agency that has been at the forefront of driving online growth for businesses across diverse sectors. With a strong foundation built over the past 2 years, we have established ourselves as a trusted partner to clients in Education, Fintech, Market Research, IT, Music Institutes, Retail, and Healthcare.</p>
            <p>Our mission is to empower businesses to achieve their full potential in the digital landscape. We leverage cutting-edge strategies, data-driven insights, and a deep understanding of consumer behavior to deliver exceptional results.</p>
          </div>
        </div>
      </div>
      {/* About Ends */}

      {/* Partner is Success */}
      <div className="partners">
        <div className="section-head">
          <h4><span>Partners in</span> Success</h4>
        </div>

        <div className="partners-body">
          {partners.map((item) => (
            <img src={item.url} alt={item.title} />
          ))}
        </div>
      </div>
      {/* Partner is Success Ends*/}

      {/* WhyChooseUs  */}
      <div className='whychooseus'>

        <div className="section-head">
          <h4><span>Why</span> Us?</h4>
        </div>
        <div className="whychooseus-content">

          <div className="whychooseus-content-text">
            <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632919/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/icon-1_f7tudz.webp' alt="icon1" />
            <h6>Cutting-Edge Solutions</h6>
            <p>We stay at the forefront of technological advancements, constantly evaluating and adopting the latest tools and methodologies. Our commitment to innovation ensures that you receive state-of-the-art solutions that drive efficiency, enhance productivity, and propel your business forward.</p>
          </div>

          <div className="whychooseus-content-text">
            <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632919/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/icon-2_fgl0cp.webp' alt="icon2" />
            <h6>Customized Approach</h6>
            <p>We understand that every business has distinct IT needs. That's why we take a personalized approach, working closely with you to understand your objectives, challenges, and long-term vision. Our team designs customized solutions that align with your specific goals, ensuring optimal performance and scalability.</p>
          </div>

          <div className="whychooseus-content-text">
            <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632919/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/icon-3_tmjjuc.webp' alt="icon3" />
            <h6>Competitive Pricing</h6>
            <p> We understand the importance of delivering value for your investment. Our pricing models are designed to be competitive without compromising on quality. We provide transparent and flexible pricing options, ensuring that you receive cost-effective solutions that align with your budget. </p>
          </div>

          <div className="whychooseus-content-text">
            <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632919/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/icon-4_bctqmd.webp' alt="icon4" />
            <h6>Client Satisfaction</h6>
            <p>Our track record speaks for itself. We have a proven history of delivering exceptional results and achieving high levels of client satisfaction. We take pride in our ability to exceed expectations, and our numerous client testimonials attest to the quality of our work.</p>
          </div>
        </div>

      </div>
      {/* WhyChooseUs Ends */}

      {/* Services  */}
      <div className='services-section'>
        <div className="section-head">
          <h4><span>Services</span> </h4>
        </div>

        <div className="services-content">
          <AliceCarousel
            mouseTracking
            items={items}
            responsive={responsive}
            controlsStrategy="alternate"
            autoPlay={true}
            // activeIndex={-3}
            infinite={true}
            autoPlayInterval={1000}
            onSlideChange={onSlideChange}
          />
        </div>


        {/* <div className="services-content">
          {services.map((item) => (
            <div className="services-content-text">
              <img src={item.img} alt={item.title} />
              <h3>{item.title}</h3>
              <Link to={item.url} target="_blank"></Link>
            </div>
          ))}
        </div> */}

      </div>

      {/* Services Ends */}

      {/* Technologies  */}
      <div className='technologies'>
        <div className="section-head col-sm-12">
          <h4><span>Technologies We Work</span> </h4>
        </div>

        <div className="tech-img">

          <img rel="preload" title='HTML' src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691736779/AHS%20Assured%20Services%20Pvt%20Ltd/Technologies/html_slvxp4.png" alt="" />

          <img rel="preload" title='CSS' src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691736780/AHS%20Assured%20Services%20Pvt%20Ltd/Technologies/css_dw1vjr.png" alt="" />

          <img rel="preload" title='Javascript' src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691735897/AHS%20Assured%20Services%20Pvt%20Ltd/Technologies/js_lfr8rk.png" alt="" />

          <img rel="preload" title='Wordpress' src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691735898/AHS%20Assured%20Services%20Pvt%20Ltd/Technologies/wordpress_tsprm4.png" alt="" />

          <img rel="preload" title='Sass' src="https://res.cloudinary.com/dcimsha8g/image/upload/v1692785483/AHS%20Assured%20Services%20Pvt%20Ltd/Technologies/sass_cisqol.png" alt="Sass" />

          <img rel="preload" title='MongoDB' src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691735897/AHS%20Assured%20Services%20Pvt%20Ltd/Technologies/MongoDB_Logo.svg_nxyiej.png" alt="" />

          <img rel="preload" title='NodeJS and ExpressJS' src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691735897/AHS%20Assured%20Services%20Pvt%20Ltd/Technologies/node-_-express_nnwtd8.png" alt="" />

          <img rel="preload" title='ReactJS' src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691735897/AHS%20Assured%20Services%20Pvt%20Ltd/Technologies/react_gplsqw.png" alt="" />

          <img rel="preload" title='AWS' src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691735897/AHS%20Assured%20Services%20Pvt%20Ltd/Technologies/2560px-Amazon_Web_Services_Logo.svg_dieayl.png" alt="" />

        </div>

      </div >
      {/* Technologies Ends */}

      {/* Testimonials */}
      {/* <div className='testimonials'>
        <div className="section-head col-sm-12">
          <h4><span>Testimonials</span> </h4>

        </div>
        <div className='testimonials-body'>
          <div className="carousel" id='desktop'>
            <div class="carousel-wrapper">
              <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} showStatus={false} interval={10000} swipeable={false}>

                <div className="carousel-body">

                  <figcaption>
                    <div className="snip">
                      <div className='s'>
                        <div className="snip-text">

                            <p>AHS DiGi-GURU has completely changed the game for Dwarka
                              School of Music. In addition to helping me with re-building
                              website, they offers great online presence, brand promotion,
                              and attractive creatives for Social Media.</p>
                            <p> Their Digital Marketing Services are absolutely outstanding,
                              effortlessly expanding my business.</p>
                        </div>
                      </div>
                      <div className='snip-child'>
                        <h4>Mr.&nbsp;&nbsp;Manoj Kumar</h4>
                        <h6>Founder</h6>
                        <h6>Dwarka School of Music</h6>
                      </div>
                    </div>
                    <div className="snip-img">
                      <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1691563397/Dwarka%20School%20Of%20Music/Logo/m_logo_jispoe.ico' alt="profile-sample3" className="profile" />
                    </div>
                  </figcaption>

                  <figcaption>
                    <div className="snip">
                      <div className='s'>
                        <div className="snip-text">
                          <p>We are a Health Care Service Provider Company and were struggling with
                            our Company Profile creation, Website Designing, Logo Designing, branding,
                            and marketing activities. This was one of the biggest challenges
                            and obstacles to kick off operations. </p>

                          <p> Then we discovered AHS DiGi-Guru who wore quick at understanding
                            our needs and able to deliver services beyond our limit of satisfaction.
                            We saw a significant change in our brand awareness among our target audience.</p>


                          <p>I Really want to thank team AHS for excellent service delivery.</p>


                        </div>
                      </div>
                      <div className='snip-child'>
                        <h4>Mr.&nbsp;&nbsp;Ravi Mishra</h4>
                        <h6>Managing Director</h6>
                        <h6> Well Wishers Home health Care Services</h6>
                      </div>
                    </div>
                    <div className="snip-img">
                      <img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1697178717/AHS%20Assured%20Services/Our%20Projects/Well%20Wishers/Untitled_design_dwd0af.png' alt="profile-sample5" className="profile" />
                    </div>

                  </figcaption>


                </div>

                <div className="carousel-body">

                  <figcaption>
                    <div className="snip">
                      <div className='s'>
                        <div className="snip-text">
                          <p>AHS Assured Services is a Professional Web Design and Development Company that revamped our website exactly how we wanted. The team at AHS Assured Services not only created a visually stunning and user-friendly website but also ensured that it was optimized as per the latest search engine guidelines. Really impressed with their work and timeline and would definitely recommend them to everyone.</p>
                        </div>
                      </div>
                      <div className='snip-child'>
                        <h4>Mr.&nbsp;&nbsp;Soumik Das</h4>
                        <h6>Director of Sales & Marketing</h6>
                        <h6>Thinktank Research Group</h6>
                      </div>
                    </div>
                    <div className="snip-img">
                      <img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1694512542/Think_Tank/Logo/logo_swzvqj_s0mwek.webp' alt="profile-sample5" className="profile" />
                    </div>
                  </figcaption>

                  <figcaption>
                    <div className="snip">
                      <div className='s'>
                        <div className="snip-text">
                          <p>It’s been a great experience with AHS Assured Services for Bulk Marketing. Their creative team took our vision into compelling, engaging content that resonated with our audience which provided the result-oriented delivery. AHS always goes the extra mile to help us in the best possible way.</p>
                        </div>
                      </div>
                      <div className='snip-child'>
                        <h4>Mr.&nbsp;&nbsp;Deepak Singh</h4>
                        <h6>Founder</h6>
                        <h6>Discount Dawa</h6>
                      </div>
                    </div>
                    <div className="snip-img">
                      <img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1697178183/AHS%20Assured%20Services/Our%20Projects/Discount%20Dawa/Discount_Dawa_Logo_tdwblr.png' alt="profile-sample5" className="profile" />
                    </div>
                  </figcaption>

                </div>

                <div className="carousel-body">

                  <figcaption>
                    <div className="snip">
                      <div className='s'>
                        <div className="snip-text">
                          <p>We have been trying for a long time to develop a website for Bliss Music. We made 3 or 4 attempts but failed to come up nicely. A few days back the AHS team contacted me and showed me one of their project on Music. Very soon, we started working on Web Development for my Bliss School of Music.</p>

                          <p>It took some time to finalize the logo as per my requirement, however once we finalized the logo AHS team picked up the pace & delivered the project exactly as per committed time-frame. And the best part is the Website work was more than I was expecting and everyone likes it.</p>

                        </div>
                      </div>
                      <div className='snip-child'>
                        <h4>Mr.&nbsp;&nbsp;Pramod Kumar</h4>
                        <h6>Founder</h6>
                        <h6>Bliss School of Music</h6>
                      </div>
                    </div>
                    <div className="snip-img">
                      <img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510113/Bliss%20School%20Of%20Music/Logo/BSOM_transparent_ffnlvs.png' alt="profile-sample5" className="profile" />
                    </div>
                  </figcaption>

                  <figcaption>
                    <div className="snip">
                      <div className='s'>
                        <div className="snip-text">
                          <p>Dear Akhil Ji,<br /><br />
                            I found your "Website Design and Development Services" very professional. Not only, it was good in quality but also your frequent follow up shows your commitment towards your client's project. You and your team kept us updated and also tried to gauge our vision.<br /><br />
                            Warm Regards<br />
                            Naveen Yadav</p>
                        </div>
                      </div>
                      <div className='snip-child'>
                        <h4>Mr.&nbsp;&nbsp;Naveen Yadav</h4>
                        <h6>CEO</h6>
                        <h6>Competency Enhancers</h6>
                      </div>
                    </div>
                    <div className="snip-img">
                      <img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709020667/AHS%20Assured%20Services/partners/3-removebg-preview_ritfil.webp' alt="profile-sample5" className="profile" />
                    </div>
                  </figcaption>
                </div>

                <div className="carousel-body">

                  <figcaption>
                    <div className="snip" style={{ height: readMore.height }}>
                      <div className='s'>
                        <div className="snip-text">
                          <p>I am thrilled to share my experience with AHS, and I must say, they did an outstanding job. From start to finish, the team at AHS demonstrated unparalleled professionalism, expertise, and dedication to delivering a top-notch website.<br /><br />
                            Right from the initial consultation, it was clear that AHS understood our vision and objectives for the website. They listened attentively to our requirements and provided valuable insights and suggestions to enhance the project further.<br /><br /></p>

                          <p style={{ display: readMore.display }}>Throughout the development process, communication with the AHS team was seamless and transparent. They kept us updated every step of the way, promptly addressing any questions or
                            concerns we had. Their attention to detail and commitment to delivering excellence were evident in every aspect of the project.<br /><br />
                            When it comes to the final product, I couldn't be more impressed.</p>
                          <button style={{ display: button.more }} onClick={showReadMore}>Read More</button>
                          <button style={{ display: button.less }} onClick={showReadLess}>Read Less</button>
                        </div>
                      </div>
                      <div className='snip-child'>
                        <h4>Mr.&nbsp;&nbsp;Bhavya Dhingra</h4>
                        <h6>Wise FinServ Private Wealth</h6>
                      </div>
                    </div>
                    <div className="snip-img">
                      <img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713245331/AHS%20Assured%20Services/Portfolio/Logo/w_vqrh7b.png' alt="profile-sample5" className="profile" />
                    </div>
                  </figcaption>

                </div>

              </Carousel>
            </div>
          </div>

          <div id='mobile' className="carousel">
            <div class="carousel-wrapper">
              <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} showStatus={false} interval={3000} swipeable={false}>
                <figcaption>
                  <div className="snip">
                    <div className='s'>
                      <div className="snip-text">
                        <p>AHS DiGi-GURU has completely changed the game for Dwarka
                          School of Music. In addition to helping me with re-building
                          website, they offers great online presence, brand promotion,
                          and attractive creatives for Social Media.</p>

                        <p> Their Digital Marketing Services are absolutely outstanding,
                          effortlessly expanding my business.</p>

                      </div>
                    </div>
                    <div className='snip-child'>
                      <h4>Mr.&nbsp;&nbsp;Manoj Kumar</h4>
                      <h6>Founder</h6>
                      <h6>Dwarka School of Music</h6>
                    </div>
                  </div>
                  <div className="snip-img">
                    <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1691563397/Dwarka%20School%20Of%20Music/Logo/m_logo_jispoe.ico' alt="profile-sample3" className="profile" />
                  </div>
                </figcaption>

                <figcaption>
                  <div className="snip">
                    <div className='s'>
                      <div className="snip-text">
                        <p>We are a Health Care Service Provider Company and were struggling with
                          our Company Profile creation, Website Designing, Logo Designing, branding,
                          and marketing activities. This was one of the biggest challenges
                          and obstacles to kick off operations. </p>

                        <p> Then we discovered AHS DiGi-Guru who wore quick at understanding
                          our needs and able to deliver services beyond our limit of satisfaction.
                          We saw a significant change in our brand awareness among our target audience.</p>


                        <p>I Really want to thank team AHS for excellent service delivery.</p>


                      </div>
                    </div>
                    <div className='snip-child'>
                      <h4>Mr.&nbsp;&nbsp;Ravi Mishra</h4>
                      <h6>Managing Director</h6>
                      <h6> Well Wishers Home health Care Services</h6>
                    </div>
                  </div>
                  <div className="snip-img">
                    <img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1697178717/AHS%20Assured%20Services/Our%20Projects/Well%20Wishers/Untitled_design_dwd0af.png' alt="profile-sample5" className="profile" />
                  </div>

                </figcaption>

                <figcaption>
                  <div className="snip">
                    <div className='s'>
                      <div className="snip-text">
                        <p>AHS Assured Services is a Professional Web Design and Development Company that revamped our website exactly how we wanted. The team at AHS Assured Services not only created a visually stunning and user-friendly website but also ensured that it was optimized as per the latest search engine guidelines. Really impressed with their work and timeline and would definitely recommend them to everyone.</p>
                      </div>
                    </div>
                    <div className='snip-child'>
                      <h4>Mr.&nbsp;&nbsp;Soumik Das</h4>
                      <h6>Director of Sales & Marketing</h6>
                      <h6>Thinktank Research Group</h6>
                    </div>
                  </div>
                  <div className="snip-img">
                    <img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1694512542/Think_Tank/Logo/logo_swzvqj_s0mwek.webp' alt="profile-sample5" className="profile" />
                  </div>
                </figcaption>

                <figcaption>
                  <div className="snip">
                    <div className='s'>
                      <div className="snip-text">
                        <p>It’s been a great experience with AHS Assured Services for Bulk Marketing. Their creative team took our vision into compelling, engaging content that resonated with our audience which provided the result-oriented delivery. AHS always goes the extra mile to help us in the best possible way.</p>
                      </div>
                    </div>
                    <div className='snip-child'>
                      <h4>Mr.&nbsp;&nbsp;Deepak Singh</h4>
                      <h6>Founder</h6>
                      <h6>Discount Dawa</h6>
                    </div>
                  </div>
                  <div className="snip-img">
                    <img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1697178183/AHS%20Assured%20Services/Our%20Projects/Discount%20Dawa/Discount_Dawa_Logo_tdwblr.png' alt="profile-sample5" className="profile" />
                  </div>
                </figcaption>

                <figcaption>
                  <div className="snip">
                    <div className='s'>
                      <div className="snip-text">
                        <p>We have been trying for a long time to develop a website for Bliss Music. We made 3 or 4 attempts but failed to come up nicely. A few days back the AHS team contacted me and showed me one of their project on Music. Very soon, we started working on Web Development for my Bliss School of Music.</p>

                        <p>It took some time to finalize the logo as per my requirement, however once we finalized the logo AHS team picked up the pace & delivered the project exactly as per committed time-frame. And the best part is the Website work was more than I was expecting and everyone likes it.</p>

                      </div>
                    </div>
                    <div className='snip-child'>
                      <h4>Mr.&nbsp;&nbsp;Pramod Kumar</h4>
                      <h6>Founder</h6>
                      <h6>Bliss School of Music</h6>
                    </div>
                  </div>
                  <div className="snip-img">
                    <img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510113/Bliss%20School%20Of%20Music/Logo/BSOM_transparent_ffnlvs.png' alt="profile-sample5" className="profile" />
                  </div>
                </figcaption>

                <figcaption>
                  <div className="snip">
                    <div className='s'>
                      <div className="snip-text">
                        <p>Dear Akhil Ji,<br /><br />
                          I found your "Website Design and Development Services" very professional. Not only, it was good in quality but also your frequent follow up shows your commitment towards your client's project. You and your team kept us updated and also tried to gauge our vision.<br /><br />
                          Warm Regards<br />
                          Naveen Yadav</p>
                      </div>
                    </div>
                    <div className='snip-child'>
                      <h4>Mr.&nbsp;&nbsp;Naveen Yadav</h4>
                      <h6>CEO</h6>
                      <h6>Competency Enhancers</h6>
                    </div>
                  </div>
                  <div className="snip-img">
                    <img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1709020667/AHS%20Assured%20Services/partners/3-removebg-preview_ritfil.webp' alt="profile-sample5" className="profile" />
                  </div>
                </figcaption>

                <figcaption>
                  <div className="snip" style={{ height: readMore.height }}>
                    <div className='s'>
                      <div className="snip-text">
                        <p>I am thrilled to share my experience with AHS, and I must say, they did an outstanding job. From start to finish, the team at AHS demonstrated unparalleled professionalism, expertise, and dedication to delivering a top-notch website.<br /><br />
                          Right from the initial consultation, it was clear that AHS understood our vision and objectives for the website. They listened attentively to our requirements and provided valuable insights and suggestions to enhance the project further.<br /><br /></p>

                        <p style={{ display: readMore.display }}>Throughout the development process, communication with the AHS team was seamless and transparent. They kept us updated every step of the way, promptly addressing any questions or
                          concerns we had. Their attention to detail and commitment to delivering excellence were evident in every aspect of the project.<br /><br />
                          When it comes to the final product, I couldn't be more impressed.</p>
                        <button style={{ display: button.more }} onClick={showReadMore}>Read More</button>
                        <button style={{ display: button.less }} onClick={showReadLess}>Read Less</button>
                      </div>
                    </div>
                    <div className='snip-child'>
                      <h4>Mr.&nbsp;&nbsp;Bhavya Dhingra</h4>
                      <h6>Wise FinServ Private Wealth</h6>
                    </div>
                  </div>
                  <div className="snip-img">
                    <img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713245331/AHS%20Assured%20Services/Portfolio/Logo/w_vqrh7b.png' alt="profile-sample5" className="profile" />
                  </div>
                </figcaption>

              </Carousel>
            </div>
          </div>
        </div >
      </div > */}
      {/* Testimonials Ends */}
      {/* <OurProjects /> */}

      <HomeBlog />
      {/* Certificates */}
      <div className='certificates'>
        <div className="section-head col-sm-12">
          <h4><span>Recognization</span> </h4>

        </div>
        <div className='cer-body'>
          <div className='cer-body-img'>
            <ul id="Frames">
              <li className="Frame"><img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698746968/AHS%20Assured%20Services/Logo/startupindia_zp38zp.png' className='img-fluid' alt="msme" /></li>
            </ul>

            <ul id="Frames">
              <li className="Frame"><img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698746968/AHS%20Assured%20Services/Logo/msme_uwunqm.png' className='img-fluid' alt="startup" /></li>
            </ul>

          </div>
        </div>
      </div>
      {/* Certificates Ends */}
      <Footer />
    </div >
  )
}

export default Home

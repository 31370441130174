import React from 'react'
import { Link } from 'react-router-dom'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import fb from "../Firebase/firebase";
import { useEffect, useState } from 'react';
const DB = fb.firestore()
const Blogslist = DB.collection('Blogs').orderBy("Date");

const HomeBlog = () => {

    const [blogs, Setblogs] = useState([]);

    useEffect(() => {
        const unsubscribe = Blogslist.limit(100).onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));
            // Update state
            Setblogs(data);
        });

        // Detach listener
        return unsubscribe;
    }, []);


    return (
        <div className='home-blog'>
            <div className="section-head col-sm-12" style={{ marginBottom: "5%" }}>
                <h4><span>Our Blogs</span> </h4>
            </div>

            <div id='desktop' className="carousel">
                <div class="carousel-wrapper">
                    <Carousel centerMode
                        centerSlidePercentage={33.3} infiniteLoop useKeyboardArrows autoPlay={true} showThumbs={false} showStatus={false} interval={1000} swipeable={false}>

                        {blogs.map(blog => (
                            <div className="home-blog-card" key={blog.id}>
                                <img rel="preload" src={blog.Image} alt="" />
                                <h6>{blog.Date}</h6>
                                <div className='home-blog-card-text'>
                                    <Link to={"/show/" + blog.id}>{blog.Title}</Link>
                                </div>
                            </div>
                        ))}

                    </Carousel>
                </div>
            </div>

            <div id='mobile' className="carousel">
                <div class="carousel-wrapper">
                    <Carousel infiniteLoop useKeyboardArrows autoPlay={true} showThumbs={false} showStatus={false} interval={1000} swipeable={false}>

                        {blogs.map(blog => (
                            <div className="home-blog-card" key={blog.id}>
                                <img rel="preload" src={blog.Image} alt="" />
                                <h6>{blog.Date}</h6>
                                <div className='home-blog-card-text'>
                                    <Link to={"/show/" + blog.id}>{blog.Title}</Link>
                                </div>
                            </div>
                        ))}

                    </Carousel>
                </div>
            </div>
        </div>
    )
}

export default HomeBlog

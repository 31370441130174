import React from 'react'
import Header from '../../../Utilities/Header'
import Footer from '../../../Utilities/Footer'
import { useEffect } from 'react'
import UpArrow from '../../../Utilities/UpArrow'
import Bot from '../../../Functionalities/Bot'
import { Link } from 'react-router-dom'
import Title from '../../../Utilities/Title'
import InquiryForm from '../../../Utilities/InquiryForm'
import { Helmet } from "react-helmet";

const Smo = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SMO - Social Media Agency in Gurgaon | AHS Assured Services</title>
        <link rel="canonical" href="https://www.ahsassuredservices.com/smo" />
        <meta name="description" content="We Provide Social Media Optimisation services for your business on all social platforms like, Facebook, Instagram, LinkedIn, Twitter, Youtube, Etc. Explore your brand's full potential in the digital landscape." />
      </Helmet>
      <Bot />
      <UpArrow />
      <Header />

      <div className='banner-hero'>

        <div className="banner-hero-text">
          <h1>social media optimization</h1>
          <div className="content">
            <h2>Ignite Your Online Presence, Ignite Your Success</h2>
          </div>
          {/* <a href='#staffing-services'>Learn More</a> */}
        </div>

        <div className="banner-hero-img">
          <img rel="preload" loading="lazy"  src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632930/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/SMO/bg_zm5d0h.webp' alt="smo-background" className='img-fluid' />
        </div>

      </div>


      <div className="smo-what">

        <div className="smo-what-text">
          <Title title="What is social media optimization?" />
        </div>

        <div className="smo-what-content">

          <div className="smo-what-content-text">
            <p>Social Media Optimization (SMO) is the process of optimizing and leveraging social media platforms to increase brand visibility, reach a wider audience, and engage with users. SMO involves various strategies and techniques aimed at maximizing the effectiveness of social media marketing efforts. The goal is to optimize social media profiles, content, and interactions to drive organic traffic, boost brand awareness, and foster meaningful connections with the target audience.

            </p>

            <p>SMO aims to create a strong presence on social media platforms, engage with the target audience, and promote brand visibility. By optimizing profiles, sharing compelling content, encouraging interaction, and leveraging influencers, businesses can enhance their social media presence and achieve their marketing objectives.

            </p>
          </div>

          <div className="smo-what-content-img">
            <img rel="preload" loading="lazy"  src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632930/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/SMO/smo-what_reljfz.webp' alt="what is smo?" />
          </div>

        </div>

        {/* <p className='last'>Social Media Optimisation is an essential component as it helps increase brand awareness, drive traffic to a website, and improve search engine rankings.</p> */}
      </div>

      <div id='smo_types' className="smo-types">
        <Title title="offerings" />

        <div className="smo-types-content">

          <div className="smo-types-content-text">
            <h2>Strategy Development</h2>
            <p>Define your social media goals and objectives. Identify your target audience, select the appropriate social media platforms for your business, and determine the type of content you will create and share</p>

          </div>


          <div className="smo-types-content-text">
            <h2>Engaging Content & Hashtags</h2>
            <p>Develop high-quality, engaging, and relevant content for your social media platforms. This can include posts, images, videos, infographics, and articles. Tailor your content with relevant and trending hashtags in your posts to increase visibility and reach a wider audience. Research popular industry-specific hashtags and include them strategically in your content.</p>

          </div>

          <div className="smo-types-content-text">
            <h2>Posting and Scheduling</h2>
            <p>Create a consistent posting schedule to ensure regular and timely content updates. Use social media management tools to schedule posts in advance, ensuring your content is published at optimal times for maximum reach and engagement.</p>


          </div>

          <div className="smo-types-content-text">
            <h2>Community Engagement</h2>
            <p>Actively engaging with the community through comments, likes, and shares to build relationships and foster loyalty.</p>

          </div>

          <div className="smo-types-content-text">
            <h2>Social Media Advertising</h2>
            <p>Utilising paid advertising on social media platforms to promote products, services, or content to a specific target audience, increase brand visibility, drive website traffic, and generate leads.</p>

          </div>

          <div className="smo-types-content-text">
            <h2>Cross-Promotion</h2>
            <p>Promoting social media channels across other marketing channels, such as email marketing, to increase visibility and drive traffic.</p>

          </div>

          <div className="smo-types-content-text">
            <h2>Consistent Branding</h2>
            <p>Creating and sharing valuable, informative, and engaging content that encourages likes, shares, and comments.</p>
          </div>

          <div className="smo-types-content-text">
            <h2>Reputation Management</h2>
            <p>Proactively manage your online reputation by addressing any negative comments or reviews. Respond promptly and professionally, aiming to resolve any issues and maintain a positive brand image.</p>
          </div>

          <div className="smo-types-content-text">
            <h2>Analytics</h2>
            <p>Tracking and analyzing social media metrics to optimize strategies and identify opportunities for improvement.</p>

          </div>

        </div>

      </div>


      <div className='project'>
        <div className="project-img">
          <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632930/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/SMO/smo_hire_idkeho.webp' alt="project" />
        </div>

        <div className='project-text'>
          <h2>looking for Social media optimization?</h2>
          <Link data-bs-toggle="modal" data-bs-target="#getintouchform" to={'#'}>get started</Link>
        </div>
        <InquiryForm />
      </div>


      <Footer />
    </div>
  )
}

export default Smo

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import fb from "../Firebase/firebase";

const AdminDashboardNav = () => {
    const navigate = useNavigate();
    const [display, setDisplay] = useState("");

   

    const handleLogout = () => {
        fb.auth().signOut()
            .then(function () {
                navigate("/admin-login");
                console.log("Signed out successfully");
                alert("Logged out successfully");
            })
            .catch(function (error) {
                console.log(error);
                alert(error);
            });
    }

    return (

        <div class="dashboard-nav">
            <Link className='a-logo' to={"/admin"}>
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696153860/AHS%20Assured%20Services/Logo/ahs_logo_cvgolt_wbgmgo.png" alt="" />
            </Link>
            <nav class="dashboard-nav-list">

                <a href="#" class="admin-blog">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M192 32c0 17.7 14.3 32 32 32c123.7 0 224 100.3 224 224c0 17.7 14.3 32 32 32s32-14.3 32-32C512 128.9 383.1 0 224 0c-17.7 0-32 14.3-32 32zm0 96c0 17.7 14.3 32 32 32c70.7 0 128 57.3 128 128c0 17.7 14.3 32 32 32s32-14.3 32-32c0-106-86-192-192-192c-17.7 0-32 14.3-32 32zM96 144c0-26.5-21.5-48-48-48S0 117.5 0 144V368c0 79.5 64.5 144 144 144s144-64.5 144-144s-64.5-144-144-144H128v96h16c26.5 0 48 21.5 48 48s-21.5 48-48 48s-48-21.5-48-48V144z" /></svg>
                    Blog
                    <div className='admin-blog-nav'>
                        <Link to={"/bloglist"} class="dashboard-nav-dropdown-item">
                            <svg id="Layer_1" height="512" viewBox="0 0 48 48" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m36 45h-24a5.006 5.006 0 0 1 -5-5v-32a5.006 5.006 0 0 1 5-5h24a5.006 5.006 0 0 1 5 5v32a5.006 5.006 0 0 1 -5 5zm-24-40a3 3 0 0 0 -3 3v32a3 3 0 0 0 3 3h24a3 3 0 0 0 3-3v-32a3 3 0 0 0 -3-3z" /><path d="m40 15h-32a1 1 0 0 1 -1-1v-6a5.006 5.006 0 0 1 5-5h24a5.006 5.006 0 0 1 5 5v6a1 1 0 0 1 -1 1zm-31-2h30v-5a3 3 0 0 0 -3-3h-24a3 3 0 0 0 -3 3z" /><path d="m16 24a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" /><circle cx="15" cy="9" r="2" /><circle cx="21" cy="9" r="2" /><circle cx="27" cy="9" r="2" /><circle cx="33" cy="9" r="2" /><path d="m34 22h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" /><path d="m16 32a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" /><path d="m34 30h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" /><path d="m16 40a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" /><path d="m34 38h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" /></svg>
                            Blog Lists
                        </Link>
                        <Link to={"/create/"} class="dashboard-nav-dropdown-item">
                            <svg id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m100 323.265h60c11.046 0 20-8.954 20-20s-8.954-20-20-20h-60c-11.046 0-20 8.954-20 20s8.954 20 20 20z" /><path d="m100 243.265h60c11.046 0 20-8.954 20-20s-8.954-20-20-20h-60c-11.046 0-20 8.954-20 20s8.954 20 20 20z" /><path d="m217.441 303.265c0 11.046 8.954 20 20 20 41.821 0 81.139-16.286 110.711-45.858l146.26-146.274c23.448-23.447 23.452-61.402 0-84.853-23.393-23.394-61.458-23.394-84.853 0l-146.26 146.274c-29.571 29.573-45.858 68.89-45.858 110.711zm220.404-228.7c7.796-7.798 20.486-7.798 28.283 0 7.816 7.814 7.819 20.467 0 28.284l-146.261 146.274c-16.719 16.72-37.624 27.735-60.352 32.068 4.333-22.727 15.349-43.632 32.067-60.352z" /><path d="m60 483.265c14.776 0 28.978-5.422 39.992-15.27l27.647-24.73h204.361c55.141 0 100-44.86 100-100v-44.863c0-11.046-8.954-20-20-20s-20 8.954-20 20v44.863c0 33.084-26.916 60-60 60h-212c-4.92 0-9.667 1.813-13.334 5.093l-33.338 29.821c-3.669 3.28-8.402 5.086-13.328 5.086-11.028 0-20-8.972-20-20v-240c0-33.084 26.916-60 60-60h167.749c11.046 0 20-8.954 20-20s-8.954-20-20-20h-167.749c-55.141 0-100 44.86-100 100v240c0 33.084 26.916 60 60 60z" /></g></svg>
                            Create Blog
                        </Link>
                    </div>
                </a>

                {/* <a href="#" class="admin-blog">
                    <svg id="Layer_1" enable-background="new 0 0 50 50" height="512" viewBox="0 0 50 50" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m32.3 8.2c0 2.1 1.7 3.9 3.9 3.9h7.2l-11.1-11.1z" /><path d="m36.2 13.1c-2.7 0-4.9-2.2-4.9-4.9v-7.2h-20.9c-2.1 0-3.9 1.7-3.9 3.9v40.3c0 2.1 1.7 3.9 3.9 3.9h29.2c2.1 0 3.9-1.7 3.9-3.9v-32h-7.3zm-14.8 7.9h7.3v1.5h-7.3zm0 10.4h7.3v1.5h-7.3zm-2.6 12.9h-6.8v-6.8h6.8zm0-10.4h-6.8v-6.8h6.8zm0-10.4h-6.8v-6.8h6.8zm9.9 19.8h-7.3v-1.5h7.3zm8.3-3.3h-15.6v-1.5h15.6zm0-10.4h-15.6v-1.5h15.6zm0-10.4h-15.6v-1.5h15.6z" /></g></svg>
                    Forms
                    <div className='admin-blog-nav'>
                        <Link to={"/career-form-database"} class="dashboard-nav-dropdown-item">
                            <svg id="Layer_1" height="512" viewBox="0 0 48 48" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m36 45h-24a5.006 5.006 0 0 1 -5-5v-32a5.006 5.006 0 0 1 5-5h24a5.006 5.006 0 0 1 5 5v32a5.006 5.006 0 0 1 -5 5zm-24-40a3 3 0 0 0 -3 3v32a3 3 0 0 0 3 3h24a3 3 0 0 0 3-3v-32a3 3 0 0 0 -3-3z" /><path d="m40 15h-32a1 1 0 0 1 -1-1v-6a5.006 5.006 0 0 1 5-5h24a5.006 5.006 0 0 1 5 5v6a1 1 0 0 1 -1 1zm-31-2h30v-5a3 3 0 0 0 -3-3h-24a3 3 0 0 0 -3 3z" /><path d="m16 24a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" /><circle cx="15" cy="9" r="2" /><circle cx="21" cy="9" r="2" /><circle cx="27" cy="9" r="2" /><circle cx="33" cy="9" r="2" /><path d="m34 22h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" /><path d="m16 32a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" /><path d="m34 30h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" /><path d="m16 40a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" /><path d="m34 38h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" /></svg>
                            Career form
                        </Link>
                        <Link to={"/faq-form-database"} class="dashboard-nav-dropdown-item">
                            <svg id="Layer_1" height="512" viewBox="0 0 48 48" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m36 45h-24a5.006 5.006 0 0 1 -5-5v-32a5.006 5.006 0 0 1 5-5h24a5.006 5.006 0 0 1 5 5v32a5.006 5.006 0 0 1 -5 5zm-24-40a3 3 0 0 0 -3 3v32a3 3 0 0 0 3 3h24a3 3 0 0 0 3-3v-32a3 3 0 0 0 -3-3z" /><path d="m40 15h-32a1 1 0 0 1 -1-1v-6a5.006 5.006 0 0 1 5-5h24a5.006 5.006 0 0 1 5 5v6a1 1 0 0 1 -1 1zm-31-2h30v-5a3 3 0 0 0 -3-3h-24a3 3 0 0 0 -3 3z" /><path d="m16 24a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" /><circle cx="15" cy="9" r="2" /><circle cx="21" cy="9" r="2" /><circle cx="27" cy="9" r="2" /><circle cx="33" cy="9" r="2" /><path d="m34 22h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" /><path d="m16 32a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" /><path d="m34 30h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" /><path d="m16 40a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" /><path d="m34 38h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" /></svg>
                            faq form
                        </Link>
                        <Link to={"/get-in-touch-form-database"} class="dashboard-nav-dropdown-item">
                            <svg id="Layer_1" height="512" viewBox="0 0 48 48" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m36 45h-24a5.006 5.006 0 0 1 -5-5v-32a5.006 5.006 0 0 1 5-5h24a5.006 5.006 0 0 1 5 5v32a5.006 5.006 0 0 1 -5 5zm-24-40a3 3 0 0 0 -3 3v32a3 3 0 0 0 3 3h24a3 3 0 0 0 3-3v-32a3 3 0 0 0 -3-3z" /><path d="m40 15h-32a1 1 0 0 1 -1-1v-6a5.006 5.006 0 0 1 5-5h24a5.006 5.006 0 0 1 5 5v6a1 1 0 0 1 -1 1zm-31-2h30v-5a3 3 0 0 0 -3-3h-24a3 3 0 0 0 -3 3z" /><path d="m16 24a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" /><circle cx="15" cy="9" r="2" /><circle cx="21" cy="9" r="2" /><circle cx="27" cy="9" r="2" /><circle cx="33" cy="9" r="2" /><path d="m34 22h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" /><path d="m16 32a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" /><path d="m34 30h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" /><path d="m16 40a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" /><path d="m34 38h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" /></svg>
                            get in touch form
                        </Link>
                        <Link to={"/landing-page-form-database"} class="dashboard-nav-dropdown-item">
                            <svg id="Layer_1" height="512" viewBox="0 0 48 48" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m36 45h-24a5.006 5.006 0 0 1 -5-5v-32a5.006 5.006 0 0 1 5-5h24a5.006 5.006 0 0 1 5 5v32a5.006 5.006 0 0 1 -5 5zm-24-40a3 3 0 0 0 -3 3v32a3 3 0 0 0 3 3h24a3 3 0 0 0 3-3v-32a3 3 0 0 0 -3-3z" /><path d="m40 15h-32a1 1 0 0 1 -1-1v-6a5.006 5.006 0 0 1 5-5h24a5.006 5.006 0 0 1 5 5v6a1 1 0 0 1 -1 1zm-31-2h30v-5a3 3 0 0 0 -3-3h-24a3 3 0 0 0 -3 3z" /><path d="m16 24a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" /><circle cx="15" cy="9" r="2" /><circle cx="21" cy="9" r="2" /><circle cx="27" cy="9" r="2" /><circle cx="33" cy="9" r="2" /><path d="m34 22h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" /><path d="m16 32a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" /><path d="m34 30h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" /><path d="m16 40a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" /><path d="m34 38h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" /></svg>
                            landing page get in touch form
                        </Link>
                        <Link to={"/subscribe-database"} class="dashboard-nav-dropdown-item">
                            <svg id="Layer_1" height="512" viewBox="0 0 48 48" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m36 45h-24a5.006 5.006 0 0 1 -5-5v-32a5.006 5.006 0 0 1 5-5h24a5.006 5.006 0 0 1 5 5v32a5.006 5.006 0 0 1 -5 5zm-24-40a3 3 0 0 0 -3 3v32a3 3 0 0 0 3 3h24a3 3 0 0 0 3-3v-32a3 3 0 0 0 -3-3z" /><path d="m40 15h-32a1 1 0 0 1 -1-1v-6a5.006 5.006 0 0 1 5-5h24a5.006 5.006 0 0 1 5 5v6a1 1 0 0 1 -1 1zm-31-2h30v-5a3 3 0 0 0 -3-3h-24a3 3 0 0 0 -3 3z" /><path d="m16 24a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" /><circle cx="15" cy="9" r="2" /><circle cx="21" cy="9" r="2" /><circle cx="27" cy="9" r="2" /><circle cx="33" cy="9" r="2" /><path d="m34 22h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" /><path d="m16 32a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" /><path d="m34 30h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" /><path d="m16 40a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" /><path d="m34 38h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z" /></svg>
                            subscribe form
                        </Link>
                    </div>
                </a> */}

                <Link to={"/admin-reset-password"} class="dashboard-nav-item">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" /></svg>
                    Reset Password
                </Link>

            </nav>
            <button class="dashboard-nav-item" onClick={handleLogout}>
                Logout
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" /></svg>
            </button>
        </div>

    )
}

export default AdminDashboardNav

import { useState } from "react";
import React from 'react'

const InquiryForm = () => {
    const [userData, setUserData] = useState({
        Name: "",
        Email: "",
        Mobile: "",
        Subject: "",
        Message: ""

    });
    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }


    //connect with firebase

    const SubmitData = async (e) => {
        e.preventDefault();
        const { Name, Email, Mobile, Subject, Message } = userData;
        if (Name && Email && Mobile && Subject && Message) {
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(userData.Email)) {
                alert('Please enter a valid email address');
                return
            }

            if (typeof Mobile !== "undefined") {

                var pattern = new RegExp(/^[0-9\b]+$/);

                if (!pattern.test(userData.Mobile)) {
                    alert("Please enter only number.");
                    return

                } else if (userData.Mobile.length !== 10) {
                    alert("Please enter 10 digit phone number.");
                    return
                }
            }

            const res = fetch(
                'https://ahs-assured-services-default-rtdb.firebaseio.com/get_in_touch_form.json',
                {
                    method: 'POST',
                    headers: {
                        "content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        Name,
                        Email,
                        Subject,
                        Mobile,
                        Message
                    }),
                }
            );

            if (res) {

                alert('Message Sent Successfully');
                setUserData({
                    Name: "",
                    Email: "",
                    Mobile: "",
                    Subject: "",
                    Message: "",
                })
            } else {
                alert('Please fill the data');
            }
        }
        else {
            alert('Please fill the data');
        }
    };
    return (
        <div>
            <div className="modal inquiry fade" id="getintouchform" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Get In Touch</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className="child2" id="get_in_touch" method="POST" >
                                <div className="child2-flex">
                                    <input
                                        type="text"
                                        name="Name"
                                        id="name"
                                        placeholder='Your Name'
                                        value={userData.Name}
                                        onChange={postUserData}
                                        required /><br />
                                    {/* <PhoneInput
                                    placeholder="Enter phone number"
                                    value={phonevalue}
                                    name="Mobile"
                                    onChange={setPhoneValue} /> */}
                                    <input type="text" name="Mobile"
                                        id="phone"
                                        placeholder='Phone Number'
                                        value={userData.Mobile}
                                        onChange={postUserData}
                                        required /><br />

                                    <div className="optgroup">
                                        <input style={{ marginRight: "1em" }}
                                            type="text"
                                            name="Subject"
                                            id="subject"
                                            placeholder='Subject'
                                            value={userData.Subject}
                                            onChange={postUserData}
                                            required /><br />
                                        <input
                                            type="email"
                                            name="Email"
                                            id="email"
                                            placeholder='Email'
                                            value={userData.Email}
                                            onChange={postUserData}
                                            required /><br />
                                    </div>

                                    <select aria-label="Default select example"
                                        value={userData.careers_post}
                                        onChange={postUserData}
                                        className="form-select"
                                        name='careers_post'>
                                        <option value="default" defaultValue='What Service are you looking for?' >What Service are you looking for?</option>
                                        <optgroup label="Digital Marketing Services">
                                            <option value="Social Media Optimization (SMO)">Social Media Optimization (SMO)</option>
                                            <option value="website design & development">website design & development</option>
                                            <option value="Search Engine Optimization (SEO)">Search Engine Optimization (SEO)</option>
                                            <option value="pay per click (PPC) advertising">pay per click (PPC) advertising</option>
                                            <option value="Content Marketing">Content Marketing</option>
                                            <option value="Bulk Marketing">Bulk Marketing</option>
                                            <option value="Video Marketing">Video Marketing</option>
                                            <option value="Affiliate marketing">Affiliate marketing</option>
                                            <option value="Influencer Marketing">Influencer Marketing</option>
                                        </optgroup>
                                        <optgroup label="Staffing Services">
                                            <option value="Temporary staffing services">Temporary staffing services</option>
                                            <option value="Permanent staffing services">Permanent staffing services</option>
                                            <option value="Contract staffing services">Contract staffing services</option>
                                            <option value="Executive Search Services">Executive Search Services</option>
                                        </optgroup>
                                        {/* <optgroup label="Real Estate Services">
                                    <option value="Sale">Sale</option>
                                    <option value="Purchase">Purchase</option>
                                    <option value="Residential/Commercial Property">Residential/Commercial Property</option>
                                </optgroup> */}


                                    </select>



                                    <textarea
                                        id="message"
                                        cols="30"
                                        rows="4"
                                        placeholder='Your Message'
                                        name="Message"
                                        value={userData.Message}
                                        onChange={postUserData}
                                        required></textarea>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-light"
                                    value="Send"
                                    onClick={SubmitData}
                                >
                                    Send
                                </button>

                            </form>
                        </div>
                        <div className="modal-footer">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InquiryForm

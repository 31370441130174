import { Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from "react"
import CareerForm from './Career_form'
import fb from "../Firebase/firebase";
const DB = fb.firestore()
const SE = DB.collection('subscribe');


const Footer = () => {
  const up = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  //subscribe connectivity

  const [subscribeEmail, setsubscribeEmail] = useState({
    subscribe_email: ""
  });

  let subscribeName, subscribeValue;
  const postSubscribeData = (e) => {
    subscribeName = e.target.name;
    subscribeValue = e.target.value;
    setsubscribeEmail({ ...subscribeEmail, [subscribeName]: subscribeValue });
  }

  const Subscribe = async (e) => {
    e.preventDefault();
    const { subscribe_email } = subscribeEmail;
    if (subscribe_email) {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(subscribeEmail.subscribe_email)) {
        alert('Please enter a valid email address');
        return
      }


      try {

        SE.add({
          subscribe_email: subscribe_email
        }).then((docRef) => {
          alert("Subscribed Successfully");
        }).catch((error) => {
          console.error("error:", error);
        });

        setsubscribeEmail({
          subscribe_email: ""
        })

        // console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
        alert("Error Subscribing");
      }
    }
    else {
      alert('Please fill email address');
    }
  };

  // career form connectivity

  const [visible, setVisible] = useState(false);

  // google translator

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };


  useEffect(() => {
    var addScript = document.createElement("script");
    
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);



  // google translator ends


  return (
    <div>
      <CareerForm />
      <section className='footer'>

        <div className='footer-header'>
          <div className='footer-header-text'>
            <span className="subheading">Subscribe here</span><br />
            <span className="subheading">Stay informed about our latest updates & Offers through email</span>
          </div>

          <div className='footer-header-button'>
            <form action="#" className="subscribe-form">
              <div className="form-group d-flex">
                <input type="email"
                  className="rounded-left"
                  placeholder="Enter email address"
                  name='subscribe_email'
                  value={subscribeEmail.subscribe_email}
                  onChange={postSubscribeData} />
                <button type="submit"
                  className="submit"
                  value="Submit"
                  onClick={Subscribe}>
                  <span>Submit</span></button>
              </div>
            </form>
          </div>
        </div>

        <div className="footer-body">
          <div className='footer-body-1'>
            <h2 className="footer-heading">Important Links</h2>
            <ul>
              <li><Link onClick={up} to={"/"}><img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632915/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/footer/home_qb7g2i.webp' alt="home" />&nbsp;Home</Link></li>
              <li><Link onClick={up} to={"/about_us"}><img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632915/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/footer/about_lsbuho.webp' alt="about" />About</Link></li>
              <li className='footer-heading-services-title' onClick={() => setVisible(!visible)}><img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632916/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/footer/Services_g4ezlx.webp' alt="services" />Our Services

                <ul style={{ display: visible ? 'block' : 'none' }} className='footer-heading-services'>
                  {/* <li><Link onClick={up} to={"/construction_services"}>Construction Services</Link></li> */}
                  <li><Link onClick={up} to={"/digital_marketing_services"}>Digital Marketing Services</Link> </li>
                  <li><Link onClick={up} to={"/staffing_services"}>Staffing Services</Link></li>
                  {/* <li><Link onClick={up} to={"/realestate_services"}>Real Estate Services</Link></li> */}
                  {/* <li><Link onClick={up} to={"/business_strategy_consulting_services"}>Business Strategy Consulting Services</Link></li> */}
                </ul>
              </li>
              <li><Link onClick={up} to={"/blog"}><img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632915/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/footer/blog_bzatkn.png' alt="careers" />Blog</Link></li>
              {/* <li><Link onClick={up} to={"/resume"}><img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632915/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/footer/cv_dgg8xl.png' alt="careers" />Resume Builder</Link></li> */}
              <li><Link onClick={up} to={"/careers"}><img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632915/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/footer/careers_ycoqlq.webp' alt="careers" />Careers</Link></li>
              <li><Link onClick={up} to={"/privacy_policy"}><img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632916/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/footer/privacy_policy_bmwyjw.webp' alt="privacy policy" />Privacy Policy</Link></li>
              <li><Link onClick={up} to={"#"} data-bs-toggle="modal" data-bs-target="#exampleModal"><img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696153851/AHS%20Assured%20Services/Icons/footer/join_our_team_k4gi6l_nfv1if.webp' alt="join our team" />Join Our Team</Link></li>
            </ul>
          </div>

          <div className='footer-body-2'>
            <h2 className="footer-heading">Contact</h2>
            <ul>
              <li><img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632915/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/footer/gmail_bojpk0.webp' alt="gmail" /><span><a href="mailto:contact@ahsassuredservices.com">contact@ahsassuredservices.com</a></span></li>
              <li><img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632916/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/footer/location_ypqf3q.webp' alt="location" /><span>Plot 23, Sector 18 Maruti Industrial Development Area, Gurugram, Haryana 122015</span></li>

            </ul>
            <ul className="d-flex">
              <li> <a href="https://www.google.com/search?q=ahs+assured+services&rlz=1C1ONGR_enIN1065IN1067&oq=AHS+Assured+Services&aqs=chrome.0.69i59j69i64j69i59j69i61.13178j0j1&sourceid=chrome&ie=UTF-8" target="_blank" rel="noreferrer">  <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632915/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/footer/google_hx4tdw.webp' alt="google" /></a></li>
              <li><a href="https://www.linkedin.com/showcase/ahs-digi-guru/?viewAsMember=true" target="_blank" rel="noreferrer">
                <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632916/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/footer/linkedin_q0rhfw.webp' alt="linkedin" /></a></li>
              <li><a href="https://www.instagram.com/ahsdigiguruindia/" target="_blank" rel="noreferrer">
                <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632915/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/footer/instagram_emip6q.webp' alt="instagram" /></a></li>
              <li><a href="https://www.facebook.com/ahsdigiguruindia" target="_blank" rel="noreferrer">  <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632915/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/footer/facebook_cdhbhi.webp' alt="facebook" /></a></li>
              <li><a href="https://www.pinterest.com/ahsassuredservices/" target="_blank" rel="noreferrer">  <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1694239110/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/footer/pinterest_lxmwxq.webp' alt="pinterest" /></a></li>
              <li><a href="https://www.youtube.com/@AHSDigiguru" target="_blank" rel="noreferrer">  <img rel="preload" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1694239478/AHS%20Assured%20Services%20Pvt%20Ltd/Icons/footer/youtube_bfhj02.webp' alt="youtube" /></a></li>
              <li><a href="https://wa.me/917290890032/?text=Hi,%0a%0aI have a query related to AHS Services, wish to connect for further discussion.%0a%0aRegards%0a%0aUser from AHS Website%0awww.ahsassuredservices.com " target="_blank" rel="noreferrer">  <img rel="preload" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1697038334/AHS%20Assured%20Services/Icons/footer/whatsapp_2504957_gjjxbb.png' alt="whatsapp" /></a></li>

            </ul>
          </div>

          <div className='footer-body-3'>
            <div className='footer-map'>
              <iframe title='sitemap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.65687408268!2d77.05946137433915!3d28.489878390546554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d192db7851583%3A0x8bb1e5325acc5f47!2sAHS%20Assured%20Services!5e0!3m2!1sen!2sin!4v1694238737711!5m2!1sen!2sin" width="100%" height="250" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>

        </div>

      </section>


      <div className='footer-text'>
        <h6>© All Copyright {new Date().getFullYear()} by AHS Assured Services</h6>
        <div  id="google_translate_element"></div>
      </div>
    </div>
  )
}

export default Footer

import React from 'react'
import Footer from '../../Utilities/Footer'
import Bot from '../../Functionalities/Bot'
import Header from '../../Utilities/Header'
import UpArrow from '../../Utilities/UpArrow'
import { useEffect } from 'react';
import { Helmet } from "react-helmet";
import "./AboutUs.scss"

const AboutUs = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AHS Assured Services Pvt Ltd - About Us</title>
                <link rel="canonical" href="https://www.ahsassuredservices.com/about_us" />
                <meta name="description" content="We are a leading provider of comprehensive digital marketing, staffing, and real estate services. Our expert team is dedicated to maximizing your online presence, recruiting top talent, and facilitating successful real estate ventures. Discover how our tailored solutions can transform your business today" />
            </Helmet>
            <Bot />
            <UpArrow />
            <Header />

            <div className="about_us">

                <div className="section-head col-sm-12">
                    <h4><span>About Us</span> </h4>


                </div>

                <div className='about_us-content'>
                    <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1690818951/AHS%20Assured%20Services%20Pvt%20Ltd/About/aerial-view-people-is-w_qs6er6.jpg" alt="aboutbg" />
                    <div className='abt-text'>
                        {/* <h1>360° Business Solutions</h1> */}
                        {/* <p>With 15+ years of business experience at leadership level, with a moral approach, guaranteeing high service levels and adherence to quality benchmarks. We&apos;ll assist you think about your business in an entire new way, bringing a new viewpoint to long-standing challenges and making a difference you imagine – and accomplish – a future with boundless conceivable outcomes.</p> */}
                        <h1>AHS Assured Services: Your Digital Success Partner</h1>
                        <p>We are AHS Assured Services, a dynamic digital marketing agency that has been at the forefront of driving online growth for businesses across diverse sectors. With a strong foundation built over the past 2 years, we have established ourselves as a trusted partner to clients in Education, Fintech, Market Research, IT, Music Institutes, Retail, and Healthcare.</p>
                        <p>Our mission is to empower businesses to achieve their full potential in the digital landscape. We leverage cutting-edge strategies, data-driven insights, and a deep understanding of consumer behavior to deliver exceptional results.</p>
                    </div>

                </div>

                <div className="abt-bottom">
                    

                    <h3>Recognized Excellence</h3>
                    <p>Our commitment to innovation and delivering value has earned us esteemed recognition as an MSME India and Startup India certified company. These accolades underscore our contributions to the Indian economy and our dedication to fostering entrepreneurship.</p>

                    <p>At AHS Assured Services, we believe in building long-term partnerships with our clients. Let's collaborate to create a digital strategy that propels your business to new heights.</p>

                    <p>Contact us today to uplift your digital transformation journey.</p>
                </div>


                <div className="vision">
                    <div className="vision-content">
                        <h4>Our Vision</h4>
                        <p>Our vision is to create a world where every aspect
                            of business is seamlessly coordinated, connected, and committed to
                            delivering value to all stakeholders. We strive to
                            build a culture that empowers our employees to collaborate effectively,
                            leverage the latest technology, and embrace a spirit of innovation to
                            drive sustainable growth and create positive change in our communities.
                            We are committed to fostering strong relationships with our customers,
                            partners, and suppliers, and to delivering exceptional products and
                            services that meet their needs and exceed their expectations. By
                            embodying these values, we aim to be recognized as a leader in our
                            industry, and a company that inspires trust, loyalty, and respect from
                            all those we serve. </p>
                    </div>

                    <div className="vision-content">
                        <h4>Our Mission</h4>
                        <p>Our mission is to build a coordinated, connected,
                            and committed organization that delivers exceptional products and services
                            to our customers while creating sustainable value for
                            all stakeholders. We strive to leverage the latest technology, foster a
                            culture of collaboration and innovation, and maintain a strong sense of
                            commitment to excellence in everything we do. By embodying these values,
                            we aim to create a positive impact in our communities, drive growth and
                            profitability, and be recognized as a leader in our industry.</p>
                    </div>
                </div>

                <div className='our-team'>
                    <div className="section-head col-sm-12">
                        <h4><span>Our Team</span> </h4>

                    </div>

                    <div className="team leaders">

                        <div className="team-card ">
                            <div className="team-card-img">
                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1694171336/AHS%20Assured%20Services%20Pvt%20Ltd/Our%20Team/akhil-removebg-preview_l9lqws.png" alt="" />
                            </div>
                            <div className="team-card-caption">
                                <h4>Akhil Chandra</h4>
                                <p>Founder & CEO</p>
                                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/akhil-chandra-621706b3"><svg id="Layer_1" enable-background="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_x31_0.Linkedin"><path d="m90 90v-29.3c0-14.4-3.1-25.4-19.9-25.4-8.1 0-13.5 4.4-15.7 8.6h-.2v-7.3h-15.9v53.4h16.6v-26.5c0-7 1.3-13.7 9.9-13.7 8.5 0 8.6 7.9 8.6 14.1v26h16.6z" /><path d="m11.3 36.6h16.6v53.4h-16.6z" /><path d="m19.6 10c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.7 9.6 9.7 9.6-4.4 9.6-9.7-4.3-9.6-9.6-9.6z" /></g></svg></a>
                            </div>
                        </div>

                        <div className="team-card ">
                            <div className="team-card-img">
                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1694171341/AHS%20Assured%20Services%20Pvt%20Ltd/Our%20Team/Saurabh-removebg-preview_dqe8qd.png" alt="" />
                            </div>
                            <div className="team-card-caption">
                                <h4>Saurabh Yadav</h4>
                                <p>Co-Founder</p>
                                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/saurabh-yadav-3a879926a/"><svg id="Layer_1" enable-background="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_x31_0.Linkedin"><path d="m90 90v-29.3c0-14.4-3.1-25.4-19.9-25.4-8.1 0-13.5 4.4-15.7 8.6h-.2v-7.3h-15.9v53.4h16.6v-26.5c0-7 1.3-13.7 9.9-13.7 8.5 0 8.6 7.9 8.6 14.1v26h16.6z" /><path d="m11.3 36.6h16.6v53.4h-16.6z" /><path d="m19.6 10c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.7 9.6 9.7 9.6-4.4 9.6-9.7-4.3-9.6-9.6-9.6z" /></g></svg></a>
                            </div>
                        </div>
                    </div>

                    <div className="team employee">

                        <div className="team-card ">
                            <div className="team-card-img">
                                <img rel="preload" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1711955859/AHS%20Assured%20Services/Our%20Team/lakshya_jmofif.png" alt="" />
                            </div>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/neeraj-kumar-698391154/"><svg id="Layer_1" enable-background="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_x31_0.Linkedin"><path d="m90 90v-29.3c0-14.4-3.1-25.4-19.9-25.4-8.1 0-13.5 4.4-15.7 8.6h-.2v-7.3h-15.9v53.4h16.6v-26.5c0-7 1.3-13.7 9.9-13.7 8.5 0 8.6 7.9 8.6 14.1v26h16.6z" /><path d="m11.3 36.6h16.6v53.4h-16.6z" /><path d="m19.6 10c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.7 9.6 9.7 9.6-4.4 9.6-9.7-4.3-9.6-9.6-9.6z" /></g></svg></a>

                            <div className="team-card-caption">
                                <h4>Lakshya Munjal</h4>
                                <p>Business Development Manager</p>
                            </div>
                        </div>

                        <div className="team-card ">
                            <div className="team-card-img">
                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1694171336/AHS%20Assured%20Services%20Pvt%20Ltd/Our%20Team/appu-removebg-preview_dp58h3.png" alt="" />
                            </div>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/aparna-chauhan2000/"><svg id="Layer_1" enable-background="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_x31_0.Linkedin"><path d="m90 90v-29.3c0-14.4-3.1-25.4-19.9-25.4-8.1 0-13.5 4.4-15.7 8.6h-.2v-7.3h-15.9v53.4h16.6v-26.5c0-7 1.3-13.7 9.9-13.7 8.5 0 8.6 7.9 8.6 14.1v26h16.6z" /><path d="m11.3 36.6h16.6v53.4h-16.6z" /><path d="m19.6 10c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.7 9.6 9.7 9.6-4.4 9.6-9.7-4.3-9.6-9.6-9.6z" /></g></svg></a>

                            <div className="team-card-caption">
                                <h4>Aparna Chauhan</h4>
                                <p>Sr. Website Developer</p>
                            </div>
                        </div>

                        {/* <div className="team-card ">
                            <div className="team-card-img">
                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1694171336/AHS%20Assured%20Services%20Pvt%20Ltd/Our%20Team/Ashutosh-removebg-preview_kd5wbj.png" alt="" />
                            </div>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/ashutosh-sharma-909025182/"><svg id="Layer_1" enable-background="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_x31_0.Linkedin"><path d="m90 90v-29.3c0-14.4-3.1-25.4-19.9-25.4-8.1 0-13.5 4.4-15.7 8.6h-.2v-7.3h-15.9v53.4h16.6v-26.5c0-7 1.3-13.7 9.9-13.7 8.5 0 8.6 7.9 8.6 14.1v26h16.6z" /><path d="m11.3 36.6h16.6v53.4h-16.6z" /><path d="m19.6 10c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.7 9.6 9.7 9.6-4.4 9.6-9.7-4.3-9.6-9.6-9.6z" /></g></svg></a>

                            <div className="team-card-caption">
                                <h4>Ashutosh Sharma</h4>
                                <p>Sr. Digital Marketing Executive</p>
                            </div>
                        </div> */}

                        <div className="team-card ">
                            <div className="team-card-img">
                                <img rel="preload" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1711955876/AHS%20Assured%20Services/Our%20Team/ruchika_e80imz.png" alt="" />
                            </div>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/himanshu-bhadauriya-9696ab28a/"><svg id="Layer_1" enable-background="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_x31_0.Linkedin"><path d="m90 90v-29.3c0-14.4-3.1-25.4-19.9-25.4-8.1 0-13.5 4.4-15.7 8.6h-.2v-7.3h-15.9v53.4h16.6v-26.5c0-7 1.3-13.7 9.9-13.7 8.5 0 8.6 7.9 8.6 14.1v26h16.6z" /><path d="m11.3 36.6h16.6v53.4h-16.6z" /><path d="m19.6 10c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.7 9.6 9.7 9.6-4.4 9.6-9.7-4.3-9.6-9.6-9.6z" /></g></svg></a>

                            <div className="team-card-caption">
                                <h4>Ruchika Nagpal</h4>
                                <p>Graphic Designer</p>
                            </div>
                        </div>

                        <div className="team-card ">
                            <div className="team-card-img">
                                <img rel="preload" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1711955859/AHS%20Assured%20Services/Our%20Team/shiksha_xjeqxq.png" alt="" />

                            </div>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/lopamudra-panda-7a378b182/"><svg id="Layer_1" enable-background="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_x31_0.Linkedin"><path d="m90 90v-29.3c0-14.4-3.1-25.4-19.9-25.4-8.1 0-13.5 4.4-15.7 8.6h-.2v-7.3h-15.9v53.4h16.6v-26.5c0-7 1.3-13.7 9.9-13.7 8.5 0 8.6 7.9 8.6 14.1v26h16.6z" /><path d="m11.3 36.6h16.6v53.4h-16.6z" /><path d="m19.6 10c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.7 9.6 9.7 9.6-4.4 9.6-9.7-4.3-9.6-9.6-9.6z" /></g></svg></a>

                            <div className="team-card-caption">
                                <h4>Shiksha Kumari</h4>
                                <p>Graphic Designer</p>
                            </div>
                        </div>

                        {/* <div className="team-card ">
                            <div className="team-card-img">
                                <img rel="preload" src="https://res.cloudinary.com/dcimsha8g/image/upload/v1694171340/AHS%20Assured%20Services%20Pvt%20Ltd/Our%20Team/Prabhnoor-removebg-preview_l12nwp.png" alt="" />

                            </div>
                            <a target="_blank" rel="noreferrer" href="http://www.linkedin.com/in/prabhnoor-guliani-a47279200"><svg id="Layer_1" enable-background="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_x31_0.Linkedin"><path d="m90 90v-29.3c0-14.4-3.1-25.4-19.9-25.4-8.1 0-13.5 4.4-15.7 8.6h-.2v-7.3h-15.9v53.4h16.6v-26.5c0-7 1.3-13.7 9.9-13.7 8.5 0 8.6 7.9 8.6 14.1v26h16.6z" /><path d="m11.3 36.6h16.6v53.4h-16.6z" /><path d="m19.6 10c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.7 9.6 9.7 9.6-4.4 9.6-9.7-4.3-9.6-9.6-9.6z" /></g></svg></a>

                            <div className="team-card-caption">
                                <h4>Prabhnoor Guliani</h4>
                                <p>Human Resource Executive</p>
                            </div>
                        </div> */}
                    </div>
                </div>




            </div>

            <Footer />
        </>
    )
}

export default AboutUs

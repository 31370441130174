import React from 'react';

import './LearningOptions.css';

const LearningOptions = (props) => {
    const options = [
        {
            text: 'Do you want to contact us?',
            handler: props.actionProvider.handleContact,
            id: 1
        },
        {
            text: 'Do you want to know about our Services?',
            handler: props.actionProvider.handleServices,
            id: 2
        },
        {
            text: 'Are you a Job Seeker or Provider?',
            handler: props.actionProvider.handleJob,
            id: 3
        },
        {
            text: 'Do you have some other query?',
            handler: props.actionProvider.handleOthers,
            id: 4
        }
    ];

    const optionsMarkup = options.map((option) => (
        <button
            className='learning-option-button'
            key={option.id}
            onClick={option.handler}
        >
            {option.text}
        </button>
    ));

    return <div className='learning-options-container'>{optionsMarkup}</div>;
};

export default LearningOptions;

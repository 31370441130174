import React from 'react';
import { createChatBotMessage } from 'react-chatbot-kit';
import LearningOptions from '../Functionalities/LearningOptions/LearningOptions';
import LinkList from '../Functionalities/LinkList/LinkList';
import { Link } from 'react-router-dom';
import ServicesLO from './LearningOptions/ServicesLO';
import JobLo from './LearningOptions/JobLO';
import DigitalLO from './LearningOptions/DigitalLO';
import Avatar from './Avatar'

const config = {
    initialMessages: [
        createChatBotMessage(
            `Hi, I'm Digi Guru. How are you?`,
            // {
            //     widget: 'learningOptions'
            // }
        )
    ],
    widgets: [
        {
            widgetName: 'learningOptions',
            widgetFunc: (props) => <LearningOptions {...props} />
        },
        {
            widgetName: 'Contact',
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: 'Email',
                        url: 'mailto:contact@ahsassuredservices.com',
                        id: 'email'
                    }
                ]
            },
        },
        {
            widgetName: 'Services',
            widgetFunc: (props) => <ServicesLO {...props} />
        },
        {
            widgetName: 'DigitalMarketingServices',
            widgetFunc: (props) => <DigitalLO {...props} />
        },
        {
            widgetName: 'SMO',
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: 'Know More',
                        url: '/smo'
                    },
                    {
                        text: 'Subscribe our services',
                        url: 'mailto:@contact@ahsassuredservices.com'
                    },
                ]
            },
        },
        {
            widgetName: 'WEB',
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: 'Know More',
                        url: '/web_design_and_development'
                    },
                    {
                        text: 'Subscribe our services',
                        url: 'mailto:@contact@ahsassuredservices.com'
                    },
                ]
            },
        },
        {
            widgetName: 'SEO',
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: 'Know More',
                        url: '/seo'
                    },
                    {
                        text: 'Subscribe our services',
                        url: 'mailto:@contact@ahsassuredservices.com'
                    },
                ]
            },
        },
        {
            widgetName: 'PPC',
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: 'Know More',
                        url: '/'
                    },
                    {
                        text: 'Subscribe our services',
                        url: 'mailto:@contact@ahsassuredservices.com'
                    },
                ]
            },
        },
        {
            widgetName: 'VIDEO',
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: 'Know More',
                        url: '/'
                    },
                    {
                        text: 'Subscribe our services',
                        url: 'mailto:@contact@ahsassuredservices.com'
                    },
                ]
            },
        },
        {
            widgetName: 'AFFILIATE',
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: 'Know More',
                        url: '/'
                    },
                    {
                        text: 'Subscribe our services',
                        url: 'mailto:@contact@ahsassuredservices.com'
                    },
                ]
            },
        },
        {
            widgetName: 'BULK',
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: 'Know More',
                        url: '/'
                    },
                    {
                        text: 'Subscribe our services',
                        url: 'mailto:@contact@ahsassuredservices.com'
                    },
                ]
            },
        },
        {
            widgetName: 'INFLUENCER',
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: 'Know More',
                        url: '/'
                    },
                    {
                        text: 'Subscribe our services',
                        url: 'mailto:@contact@ahsassuredservices.com'
                    },
                ]
            },
        },
        {
            widgetName: 'CONTENT',
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: 'Know More',
                        url: '/'
                    },
                    {
                        text: 'Subscribe our services',
                        url: 'mailto:@contact@ahsassuredservices.com'
                    },
                ]
            },
        },
        {
            widgetName: 'StaffingServices',
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: 'Temporary Staffing Services',
                        url: '/staffing_services',
                        id: 1,
                    },
                    {
                        text: 'Permanent Staffing Services',
                        url: '/staffing_services',
                        id: 2,
                    },
                    {
                        text: 'Contract Staffing Services',
                        url: '/staffing_services',
                        id: 3,
                    },
                    {
                        text: 'Executive Staffing Services',
                        url: '/staffing_services',
                        id: 4,
                    }
                ]
            },
        },
        {
            widgetName: 'Job',
            widgetFunc: (props) => <JobLo {...props} />
        },
        {
            widgetName: 'JobYes',
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: 'Sr. Digital Marketing Executive',
                        url: '/careers',
                        id: 1,
                    },
                    {
                        text: 'Social Media Manager',
                        url: '/careers',
                        id: 2
                    },
                    {
                        text: 'SEO Expert',
                        url: '/careers',
                        id: 3
                    },
                    {
                        text: 'Sr. Sales/ Business Development Executive ',
                        url: '/careers',
                        id: 4
                    }
                ]
            },
        },
        {
            widgetName: 'Others',
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: 'Email Us',
                        url: 'mailto:contact@ahsassuredservices.com',
                    }
                ]
            },
        },
        {
            widgetName: 'Address',
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: 'Address',
                        url: 'https://www.google.com/maps?ll=28.489874,77.062036&z=13&t=m&hl=en&gl=IN&mapclient=embed&cid=12567277828314516030',
                    }
                ]
            },
        }
    ],
    botName: 'Digi Guru',
    customStyles: {
        botMessageBox: {
            backgroundColor: '#16243d'
        },
        chatButton: {
            backgroundColor: '#376B7E'
        }
    }
};

export default config;

import React from 'react'
import Footer from '../../Utilities/Footer'
import Header from '../../Utilities/Header'
import Bot from '../../Functionalities/Bot'
import UpArrow from '../../Utilities/UpArrow'
import { useEffect } from 'react'
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {


    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AHS Assured Services Pvt Ltd - Privacy Policy</title>
                <link rel="canonical" href="https://www.ahsassuredservices.com/faq" />
                <meta name="description" content="Protecting your data is our priority. Discover how we safeguard your information. Read our comprehensive Privacy Policy now" />
            </Helmet>
            <UpArrow />
            <Header />
            <Bot />

            <div className='privacy'>

                <h1>Privacy Policy</h1>
                <h2><b><i>Last updated: June 23, 2023</i></b></h2>
                <p>This Privacy Policy describes the practices and policies of AHS Assured Services ("we," "our," or "us") regarding the collection, use, and disclosure of personal information when you visit our website https://www.ahsassuredservices.com/ (the "Website"). We are committed to protecting your privacy and ensuring the security of your personal information. By accessing or using our Website, you agree to the terms and conditions of this Privacy Policy.</p>

                <div>
                    <h3>Information We Collect</h3>

                    <h4>1.1 Personal Information:</h4>
                    <p>                    We may collect personal information from you when you voluntarily provide it to us through the Website. This may include your name, email address, phone number, and any other information you provide to us when contacting us or filling out forms on the Website.</p>

                    <h4>1.2 Non-Personal Information:</h4>
                    <p>We may also collect non-personal information automatically as you navigate through the Website. This information may include your IP address, browser type, operating system, referring URLs, pages visited, and other similar information.</p>
                </div>


                <div>
                    <h3>Use of Information</h3>

                    <h4>2.1 Personal Information:</h4>
                    <p>We may use the personal information you provide to respond to your inquiries, process your requests, provide the services you request, and communicate with you regarding our services</p>

                    <h4>2.2 Non-Personal Information:</h4>
                    <p>Non-personal information collected automatically may be used for statistical analysis, website improvement, and to enhance your experience on the Website. This information helps us understand how visitors use our Website and allows us to tailor content to better meet their needs.</p>
                </div>

                <div>
                    <h3>Cookies and Tracking Technologies</h3>

                    <p>We may use cookies, web beacons, and other tracking technologies on our Website to collect information about your browsing activities. Cookies are small files that are stored on your computer or device. These technologies help us improve the functionality of our Website and provide a more personalized experience.</p>

                    <p>You have the option to disable cookies through your browser settings, but please note that some features of the Website may not function properly if you choose to do so.</p>
                </div>

                <div>
                    <h3>Third-Party Disclosure</h3>

                    <p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as described in this Privacy Policy. We may share your information with trusted third-party service providers who assist us in operating our Website, conducting our business, or providing services to you, as long as they agree to keep your information confidential.</p>

                    <p>We may also disclose your personal information when required by law, to enforce our Website policies, or to protect our or others' rights, property, or safety.</p>
                </div>

                <div>
                    <h3>Data Security</h3>

                    <p>We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your information.</p>

                </div>

                <div>
                    <h3>Links to Third-Party Websites</h3>

                    <p>Our Website may contain links to third-party websites. We do not control or assume any responsibility for the content or practices of these third-party websites. We encourage you to review the privacy policies of those websites before providing any personal information.</p>

                </div>

                <div>
                    <h3>Children's Privacy</h3>

                    <p> Our Website is not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us, and we will delete such information from our records.</p>

                </div>

                <div>
                    <h3>Changes to this Privacy Policy</h3>

                    <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last updated" date at the top of this policy will reflect the date of the latest revision. We encourage you to review this Privacy Policy periodically to stay informed about how we collect, use, and protect your information.</p>

                </div>


                <div>
                    <h3>Contact Us</h3>

                    <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at contact@ahsassuredservices.com</p>


                    <p><b>By using our Website, you consent to the terms of this Privacy Policy.</b></p>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy

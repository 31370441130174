import React, { useEffect } from 'react'
import Header from '../../../Utilities/Header'
import Footer from '../../../Utilities/Footer'
import Title from '../../../Utilities/Title'

const WebSolutions = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Header />
      <div className="web-solutions">
        <div className='banner-hero'>
          <div className="banner-hero-text">
            <h1>E-Voting Web Solution</h1>
            <div className="content">
              <h2>Crafting Tomorrow's Success in Every Click</h2>
            </div>
            {/* <a href='#staffing-services'>Learn More</a> */}
          </div>

          <div className="banner-hero-img">
            <img rel="preload" loading="lazy" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1700736426/AHS%20Assured%20Services/Services/digital-marketing/Web%20Solutions/web-solutions_banner_btpkbi.png' alt="web-solutions-background" className='img-fluid' />
          </div>
        </div>

        <div className="smo-what">
          <div className="smo-what-text">
            <Title title="What is E-Voting Web Solution?" />
          </div>

          <div className="smo-what-content">
            <div className="smo-what-content-img">
              <img rel="preload" loading="lazy" src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1700746810/AHS%20Assured%20Services/Services/digital-marketing/Web%20Solutions/Admin%20Panel/banner_msktyb.jpg' alt="what is E-Voting Web Solution?" />
            </div>
            <div className="smo-what-content-text">
              <p>AHS Assured Services, where innovation meets exceptional E-Voting Web Solution. We specialize in crafting customized, impactful solutions that speak volumes about your brand. Our dedicated team of developers, designers, and strategists collaborates seamlessly to transform your vision into a compelling reality.</p>
              <p>Beyond the conventional web presence, we specialize in crafting digital assets that not only captivate but also actively engage your audience, leaving an indelible mark in the age of the internet</p>
            </div>
          </div>
        </div>

        <div className="solutions-feature">
          <div className="smo-what-text">
            <Title title="Feature of Module" />
          </div>

          <div className="solutions-feature-body">

            <div className="solutions-feature-card">
              <img rel="preload" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1700736403/AHS%20Assured%20Services/Services/digital-marketing/Web%20Solutions/feature/login_window_for_members_cxzlpm.jpg" alt="" />
              <div className="caption">
                <h3>Login Window for Members</h3>
              </div>
            </div>

            <div className="solutions-feature-card">
              <img rel="preload" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1700736403/AHS%20Assured%20Services/Services/digital-marketing/Web%20Solutions/feature/List_Of_Candidates_With_Their_Resumes_n9mcdp.jpg" alt="" />
              <div className="caption">
                <h3>Candidate Listing</h3>
              </div>
            </div>

            <div className="solutions-feature-card">
              <img rel="preload" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1700736406/AHS%20Assured%20Services/Services/digital-marketing/Web%20Solutions/feature/Review_Selection_mkclzx.jpg" alt="" />
              <div className="caption">
                <h3>Strong Review And Selection Process</h3>
              </div>
            </div>

            <div className="solutions-feature-card">
              <img rel="preload" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1700736402/AHS%20Assured%20Services/Services/digital-marketing/Web%20Solutions/feature/Confirm_Selection_t7nr6b.jpg" alt="" />
              <div className="caption">
                <h3>Analytical Results and Confirmation</h3>
              </div>
            </div>

          </div>
        </div>

        <div className="solutions-feature">
          <div className="smo-what-text">
            <Title title="Security Feature" />
          </div>

          <div className="solutions-feature-body security-feature">
            <div className="solutions-feature-card">
              <img rel="preload" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1700738912/AHS%20Assured%20Services/Services/digital-marketing/Web%20Solutions/Security%20Feature/IP_Address_Tracking_zhc1re.jpg" alt="" />
              <div className="caption">
                <h3>IP Address Tracking</h3>
              </div>
            </div>

            <div className="solutions-feature-card">
              <img rel="preload" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1700738912/AHS%20Assured%20Services/Services/digital-marketing/Web%20Solutions/Security%20Feature/Device_Model_Tracking_a0x2dd.jpg" alt="" />
              <div className="caption">
                <h3>Device (Model) Tracking</h3>
              </div>
            </div>

            <div className="solutions-feature-card">
              <img rel="preload" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1700738915/AHS%20Assured%20Services/Services/digital-marketing/Web%20Solutions/Security%20Feature/Time_Stamp_ei5nyu.jpg" alt="" />
              <div className="caption">
                <h3>Time Stamp</h3>
              </div>
            </div>

            <div className="solutions-feature-card">
              <img rel="preload" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1700738912/AHS%20Assured%20Services/Services/digital-marketing/Web%20Solutions/Security%20Feature/Location_ors6zr.jpg" alt="" />
              <div className="caption">
                <h3>Location / GEO Tracking</h3>
              </div>
            </div>

            <div className="solutions-feature-card">
              <img rel="preload" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1700738914/AHS%20Assured%20Services/Services/digital-marketing/Web%20Solutions/Security%20Feature/Session_Limit_For_5_Minutes_lyrv9z.jpg" alt="" />
              <div className="caption">
                <h3>Session Time Control</h3>
              </div>
            </div>

            <div className="solutions-feature-card">
              <img rel="preload" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1700738916/AHS%20Assured%20Services/Services/digital-marketing/Web%20Solutions/Security%20Feature/User_Blocked_After_4_Unsuccessful_OTP_Attempt_etgica.jpg" alt="" />
              <div className="caption">
                <h3>User Authentication</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="solutions-feature">
          <div className="smo-what-text">
            <Title title="Admin Panel" />
          </div>

          <div className="solutions-feature-body a-p">
            <div className="solutions-feature-card">
              <img rel="preload" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1700741467/AHS%20Assured%20Services/Services/digital-marketing/Web%20Solutions/Admin%20Panel/Login_For_Panel_vbrtss.jpg" alt="" />
              <div className="caption">
                <h3>Login Panel</h3>
              </div>
            </div>

            <div className="solutions-feature-card">
              <img rel="preload" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1700744780/AHS%20Assured%20Services/Services/digital-marketing/Web%20Solutions/Admin%20Panel/Real_Time_Vote_Counter_2_t4fvti.jpg" alt="" />
              <div className="caption">
                <h3>Real Time Data Analysis</h3>
              </div>
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  )
}

export default WebSolutions

import React from 'react'
import Header from '../../../Utilities/Header'
import Footer from '../../../Utilities/Footer'
import UpArrow from '../../../Utilities/UpArrow'
import Bot from '../../../Functionalities/Bot'
import Title from '../../../Utilities/Title'
import { Link } from 'react-router-dom'
import InquiryForm from '../../../Utilities/InquiryForm'

const InfluencerMarketing = () => {
  return (
    <div>
      <Bot />
      <UpArrow />
      <Header />

      <div className="influencer_marketing">


        <div className='banner-hero'>

          <div className="banner-hero-text">
            <h1>influencer marketing</h1>
            <div className="content">
              <h2>Where Creators Connect, Brands Soar!</h2>
            </div>
            {/* <a href='#staffing-services'>Learn More</a> */}
          </div>

          <div className="banner-hero-img">
            <img rel="preload" loading="lazy"  src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632928/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Influencer%20Marketing/hero_zpqykk.webp' alt="smo-background" className='img-fluid' />
          </div>

        </div>

        <div className="smo-what">
          <div className="smo-what-text">
            <Title title="What is influencer marketing?" />
          </div>

          <div className="smo-what-content">

            <div className="smo-what-content-text">
              <p>Influencer marketing is a form of marketing strategy that involves partnering with influential individuals, known as influencers, to promote a brand, product, or service to their dedicated and engaged audience. These influencers have established credibility, expertise, or popularity in a particular niche or industry, and they leverage their influence and reach on social media platforms to create sponsored content on behalf of brands.</p>

              <p>
              The goal of influencer marketing is to tap into the influencers' trusted relationship with their followers and benefit from their ability to persuade and impact consumer behavior. By collaborating with influencers, brands can access a targeted and often niche-specific audience, enhance brand awareness, drive engagement, and increase product or service sales.
              </p>

              <p>Influencer marketing is prevalent on various social media platforms such as Instagram, YouTube, TikTok, Twitter, and more. The content created by influencers for a brand can take the form of sponsored posts, videos, reviews, stories, giveaways, or endorsements.</p>
            </div>

            <div className="smo-what-content-img">
              <img rel="preload" loading="lazy"  src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632928/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Influencer%20Marketing/influencer_what_zkipyx.webp' alt="what is smo?" />
            </div>

          </div>
        </div>

        <div className='content-services web-why'>
          <div className="smo-what-text">
            <Title title="types of influencers" />
          </div>
          <div className='container-fluid mx-auto mt-5 mb-5 col-12' style={{ textAlign: "center" }}>

            <div className="row web-why-card" style={{ justifyContent: "center" }}>
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    {/* <img rel="preload" loading="lazy"  className="img-fluid" src={hero} alt='customized' /> */}
                    <div className="shadow"></div>
                    <div className="card-title">Reach and Exposure</div>
                    <div className="card-subtitle">
                      <p> Influencers have established and dedicated followings on social media platforms, which allows brands to tap into a large and engaged audience. This can significantly expand the brand's reach and exposure to potential customers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    {/* <img rel="preload" loading="lazy"  className="img-fluid" src={products} alt='user-Friendly Experience' /> */}
                    <div className="card-title">Targeted Audience</div>
                    <div className="card-subtitle">
                      <p>Different influencers cater to specific niches and demographics. Collaborating with the right influencers enables brands to connect with their target audience more effectively, ensuring that the promotional content reaches the right people.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    {/* <img rel="preload" loading="lazy"  className="img-fluid" src={explainer} alt='Mobile-Optimized' /> */}
                    <div className="card-title">Authenticity and Credibility</div>
                    <div className="card-subtitle">
                      <p> Influencers often have a genuine and authentic relationship with their followers. When they promote a brand or product, it feels like a recommendation from a friend, leading to higher levels of trust and credibility for the brand.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    {/* <img rel="preload" loading="lazy"  className="img-fluid" src={testimonails} alt='Mobile-Optimized' /> */}
                    <div className="card-title">Engagement and Interaction</div>
                    <div className="card-subtitle">
                      <p>Influencers typically have higher engagement rates compared to traditional advertising methods. Their content sparks conversations and interactions, creating a more meaningful connection between the brand and its potential customers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    {/* <img rel="preload" loading="lazy"  className="img-fluid" src={live} alt='Mobile-Optimized' /> */}
                    <div className="card-title">Creative Content</div>
                    <div className="card-subtitle">
                      <p> Influencers are skilled content creators. They can produce engaging and creative promotional content that seamlessly integrates the brand's message, making it more appealing to their audience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    {/* <img rel="preload" loading="lazy"  className="img-fluid" src={tutorial} alt='Mobile-Optimized' /> */}
                    <div className="card-title">Brand Awareness</div>
                    <div className="card-subtitle">
                      <p>By collaborating with influencers, brands can quickly increase their visibility and brand awareness within the influencer's community and beyond.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    {/* <img rel="preload" loading="lazy"  className="img-fluid" src={events} alt='Mobile-Optimized' /> */}
                    <div className="card-title">Influencer Insights</div>
                    <div className="card-subtitle">
                      <p>Influencers often have a deep understanding of their audience and their preferences. Brands can gain valuable insights into their target market through these collaborations, helping them refine their marketing strategies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    {/* <img rel="preload" loading="lazy"  className="img-fluid" src={events} alt='Mobile-Optimized' /> */}
                    <div className="card-title">Cost-Effective</div>
                    <div className="card-subtitle">
                      <p>Influencer marketing can be more cost-effective than traditional advertising, especially for businesses with limited marketing budgets. Working with micro-influencers or nano-influencers can offer significant value without exorbitant costs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    {/* <img rel="preload" loading="lazy"  className="img-fluid" src={events} alt='Mobile-Optimized' /> */}
                    <div className="card-title">Adaptability and Agility</div>
                    <div className="card-subtitle">
                      <p> Influencer marketing allows brands to be more adaptable and agile with their campaigns. They can respond quickly to market trends and adjust their messaging in real-time, staying relevant and up-to-date.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    {/* <img rel="preload" loading="lazy"  className="img-fluid" src={events} alt='Mobile-Optimized' /> */}
                    <div className="card-title">Measurable Results</div>
                    <div className="card-subtitle">
                      <p>Digital influencer marketing campaigns are measurable, providing valuable data and metrics to evaluate the effectiveness of the campaign and calculate the return on investment (ROI).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div id='seo_key' className="smo-types seo">
          <Title title="Benefits of Influencer Marketing" />
          <div className="seo-key-content">

            <div className="seo-key-content-text">
              <h2>Increased Reach</h2>
              <hr />
              <p>Influencers have established audiences, allowing brands to access a wider pool of potential customers.</p>
            </div>

            <div className="seo-key-content-text">
              <h2>Authenticity</h2>
              <hr />
              <p>Influencers' content tends to be more genuine, which can resonate better with their followers compared to traditional advertising.</p>
            </div>

            <div className="seo-key-content-text">
              <h2>Niche Targeting</h2>
              <hr />
              <p>Brands can collaborate with influencers whose followers match their target demographic.</p>
            </div>

            <div className="seo-key-content-text">
              <h2>Improved Engagement</h2>
              <hr />
              <p>Influencers often have a more engaged audience, leading to higher interaction rates.</p>
            </div>

          </div>

        </div>

        <div className='project'>
          <div className="project-img">
            <img rel="preload" loading="lazy"  className="img-fluid" src='https://res.cloudinary.com/dcimsha8g/image/upload/v1690632929/AHS%20Assured%20Services%20Pvt%20Ltd/Services/digital-marketing/Influencer%20Marketing/project_hu9bwt.webp' alt="project" />
          </div>

          <div className='project-text'>
            <h2>looking for influencer Marketing?</h2>
            <Link data-bs-toggle="modal" data-bs-target="#getintouchform" to={'#'}>get started</Link>
        </div>
        <InquiryForm />

        </div>

      </div>
      <Footer />
    </div>
  )
}

export default InfluencerMarketing

import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './Components/Sass/main.scss'
import './Components/Sass/media.scss'
// import Business from "./Components/Pages/Our_Services/BusinessStrategyConsultingServices"
// import Construction from "./Components/Pages/Our_Services/ConstructionServices"
// import RealEstate from "./Components/Pages/Our_Services/RealEstateServices"
import ThankYouForm from './Components/Utilities/ThankYouForm'
import ThanksForm from './Components/Utilities/ThankYouForm'
import LandingPage from './Components/Pages/LandingPage'

// Main Pages ======================================================
import Home from './Components/Pages/Home/Home'
import About from './Components/Pages/About Us/AboutUs'
import Careers from "./Components/Pages/Career/Careers"
import Faq from "./Components/Pages/Faq/Faq"
import Privacy from './Components/Pages/PrivacyPolicy/PrivacyPolicy'
import Blog from './Components/Pages/Blog/Blog'
import BlogDetails from './Components/Pages/Blog/BlogDetails'
import Contact from './Components/Pages/Contact/Contact'

// Main Pages Ends =================================================

// Service Pages ===================================================
import Digital from "./Components/Pages/Our_Services/DigitalMarketingServices"
import Staffing from "./Components/Pages/Our_Services/StaffingServices"
// Service Pages Ends ==============================================

// Digital Marketing Service Pages =================================
import Ppc from './Components/Pages/Our_Services/Digital_Marketing_Services.jsx/Ppc'
import Seo from './Components/Pages/Our_Services/Digital_Marketing_Services.jsx/Seo'
import Smo from './Components/Pages/Our_Services/Digital_Marketing_Services.jsx/Smo'
import Content from './Components/Pages/Our_Services/Digital_Marketing_Services.jsx/ContentMarketing'
import Web from './Components/Pages/Our_Services/Digital_Marketing_Services.jsx/WebDevelopment'
import Influence from './Components/Pages/Our_Services/Digital_Marketing_Services.jsx/InfluencerMarketing'
import Affiliate from './Components/Pages/Our_Services/Digital_Marketing_Services.jsx/AffiliateMarketing'
import Video from './Components/Pages/Our_Services/Digital_Marketing_Services.jsx/VideoMarketing'
import Bulk from './Components/Pages/Our_Services/Digital_Marketing_Services.jsx/Bulkmarketing'
import WebSolutions from './Components/Pages/Our_Services/Digital_Marketing_Services.jsx/WebSolutions'
// Digital Marketing Service Pages Ends ============================

// Admin Pages =====================================================
import Blogslist from './Components/AdminPanel/Blog/blogslist'
import CreateBlog from './Components/AdminPanel/Blog/create'
import Admin from './Components/AdminPanel/Admin'
import AdminLogin from './Components/AdminPanel/AdminLogin'
import CareerDB from './Components/AdminPanel/Forms/CareerDB'
import FaqDB from './Components/AdminPanel/Forms/FaqDB'
import GetInTouchDB from './Components/AdminPanel/Forms/GetInTouchDB'
import LandingPageDB from './Components/AdminPanel/Forms/LandingPageDB'
import SubscribeDB from './Components/AdminPanel/Forms/SubscribeDB'
import ResetPassword from './Components/AdminPanel/ResetPassword'

// Admin Pages Ends ================================================


// Portfolio =====================================================
import Portfolio from './Components/Pages/Portfolio/Portfolio2'
// import Website from './Components/Pages/Portfolio/Website'
// import CreativeAdvertisement from './Components/Pages/Portfolio/CreativeAdvertisement'
// Portfolio Ends =====================================================

function App() {
  return (
    <div >
      <BrowserRouter>
        <Routes>
          {/* pages */}
          <Route path="/" element={<Home />} />
          <Route path="/about_us" element={<About />} />
          {/* <Route path="/contact" element={<Contact />} /> */}
          <Route path="/blog" element={<Blog />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/thankyou" element={<ThankYouForm />} />
          <Route path="/thanks" element={<ThanksForm />} />
          <Route path="/get-a-quote" element={<LandingPage />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/privacy_policy" element={<Privacy />} />

          {/* services */}
          {/* <Route path="/business_strategy_consulting_services" element={<Business />} /> */}
          {/* <Route path="/construction_services" element={<Construction />} /> */}
          {/* <Route path="/realestate_services" element={<RealEstate />} /> */}
          <Route path="/staffing_services" element={<Staffing />} />
          <Route path="/digital_marketing_services" element={<Digital />} />

          {/* digital marketing services */}
          <Route path="/seo" element={<Seo />} />
          <Route path="/smo" element={<Smo />} />
          <Route path="/ppc" element={<Ppc />} />
          <Route path="/content_marketing" element={<Content />} />
          <Route path="/web_design_and_development" element={<Web />} />
          <Route path="/bulk_marketing" element={<Bulk />} />
          <Route path="/influencer_marketing" element={<Influence />} />
          <Route path="/affiliate_marketing" element={<Affiliate />} />
          <Route path="/video_marketing" element={<Video />} />
          <Route path="/web-solutions" element={<WebSolutions />} />


          {/* Resume */}
          {/* <Route path="/resume" element={<Resume />} />
          <Route path="/resume_education" element={<ResumeEducation />} />
          <Route path="/resume_skills" element={<ResumeSkills />} />
          <Route path="/resume_experience" element={<ResumeExperience />} />
          <Route path="/resume_summary" element={<ResumeSummary />} />
          <Route path="/resume_finalize" element={<ResumeFinalize />} />
          <Route path="/resume_header" element={<ResumeHeader />} /> */}

          {/* blog */}
          <Route path="/bloglist" element={<Blogslist />} />
          <Route path="/create/" element={<CreateBlog />} />
          <Route path="/show/:id" element={<BlogDetails />} />
          <Route path='/blog' element={<Blog />} />


          {/* Admin */}
          <Route exact path="/admin-login" element={<AdminLogin />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/career-form-database" element={<CareerDB />} />
          <Route exact path="/faq-form-database" element={<FaqDB />} />
          <Route exact path="/get-in-touch-form-database" element={<GetInTouchDB />} />
          <Route exact path="/landing-page-form-database" element={<LandingPageDB />} />
          <Route exact path="/subscribe-database" element={<SubscribeDB />} />
          <Route exact path="/admin-reset-password" element={<ResetPassword />} />


          {/* Portfolio */}
          {/* <Route exact path="/portfolio/websites" element={<Website />} />
          <Route exact path="/portfolio/creative-advertisement" element={<CreativeAdvertisement />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
